import {
  CarbonDispatchTypes,
  CLEAR_CARBON_PARK_DETAIL,
  GET_ALL_CARBON_REPORT,
  GET_ALL_CARBON_REPORT_FAIL,
  GET_ALL_CARBON_REPORT_RELOAD,
  GET_ALL_CARBON_REPORT_SUCCESS,
  GET_CARBON_PARK_DETAIL,
  GET_CARBON_PARK_DETAIL_FAIL,
  GET_CARBON_PARK_DETAIL_SUCCESS,
  GET_CARBON_PARK_LIST,
  GET_CARBON_PARK_LIST_FAIL,
  GET_CARBON_PARK_LIST_SUCCESS,
  GET_CARBON_REPORT_DETAIL,
  GET_CARBON_REPORT_DETAIL_FAIL,
  GET_CARBON_REPORT_DETAIL_SUCCESS,
  RESET_CARBON,
  UPLOAD_FILE,
  UPLOAD_FILE_FAIL,
  UPLOAD_FILE_GET_PRESIGNED_URL_SUCCESS,
  UPLOAD_FILE_SUCCESS,
} from './actionTypes';
import {
  carbonParkDetailInitialState,
  carbonParkInitialState,
  carbonReportDetailInitialState,
  carbonReportListInitialState,
  defaultCarbonState,
  IDefaultCarbonState,
} from './state';

const carbonReducer = (
  state: IDefaultCarbonState = defaultCarbonState,
  action: CarbonDispatchTypes,
): IDefaultCarbonState => {
  switch (action.type) {
    // GET ALL CARBON REPORT
    case GET_ALL_CARBON_REPORT:
      return {
        ...state,
        carbonReportList: carbonReportListInitialState,
        isLoading: true,
        isError: false,
        statusAPI: null,
      };
    case GET_ALL_CARBON_REPORT_RELOAD:
      return {
        ...state,
        carbonReportList: carbonReportListInitialState,
        isLoading: false,
        isError: false,
        statusAPI: null,
      };
    case GET_ALL_CARBON_REPORT_SUCCESS:
      return {
        ...state,
        carbonReportList: action.payload,
        isLoading: false,
        isError: false,
        statusAPI: null,
      };
    case GET_ALL_CARBON_REPORT_FAIL:
      return {
        ...state,
        isLoading: false,
        isError: true,
        statusAPI: action.payload,
      };
    // GET CARBON PARK LIST
    case GET_CARBON_PARK_LIST:
      return {
        ...state,
        carbonParkList: carbonParkInitialState,
        isLoading: true,
        isError: false,
        statusAPI: null,
      };
    case GET_CARBON_PARK_LIST_SUCCESS:
      return {
        ...state,
        carbonParkList: action.payload,
        isLoading: false,
        isError: false,
        statusAPI: null,
      };
    case GET_CARBON_PARK_LIST_FAIL:
      return {
        ...state,
        isLoading: false,
        isError: true,
        statusAPI: action.payload,
      };
    // GET CARBON PARK DETAIL
    case GET_CARBON_PARK_DETAIL:
      return {
        ...state,
        carbonParkDetail: [carbonParkDetailInitialState],
        isLoadingMap: true,
        isError: false,
        statusAPI: null,
      };
    case GET_CARBON_PARK_DETAIL_SUCCESS:
      return {
        ...state,
        carbonParkDetail: action.payload,
        isLoadingMap: false,
        isError: false,
        statusAPI: null,
      };
    case GET_CARBON_PARK_DETAIL_FAIL:
      return {
        ...state,
        isLoadingMap: false,
        isError: true,
        statusAPI: action.payload,
      };
    case CLEAR_CARBON_PARK_DETAIL:
      return {
        ...state,
        carbonParkDetail: [],
        isLoadingMap: false,
        isError: false,
        statusAPI: null,
      };
    // GET CARBON OVERVIEW
    case GET_CARBON_REPORT_DETAIL:
      return {
        ...state,
        carbonReportDetail: carbonReportDetailInitialState,
        isLoading: true,
        isError: false,
        statusAPI: null,
      };
    case GET_CARBON_REPORT_DETAIL_SUCCESS:
      return {
        ...state,
        carbonReportDetail: action.payload,
        isLoading: false,
        isError: false,
        statusAPI: null,
      };
    case GET_CARBON_REPORT_DETAIL_FAIL:
      return {
        ...state,
        isLoading: false,
        isError: true,
        statusAPI: action.payload,
      };
    // UPLOAD FILE STATUS
    case UPLOAD_FILE:
      return {
        ...state,
        uploadInfoStatus: null,
        isLoading: true,
        isError: false,
        statusAPI: null,
      };
    case UPLOAD_FILE_GET_PRESIGNED_URL_SUCCESS:
      return {
        ...state,
        isLoading: true,
        isError: false,
        statusAPI: null,
      };
    case UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        uploadInfoStatus: true,
        isLoading: false,
        isError: false,
        statusAPI: null,
      };
    case UPLOAD_FILE_FAIL:
      return {
        ...state,
        uploadInfoStatus: false,
        isLoading: false,
        isError: true,
        statusAPI: action.payload,
      };
    case RESET_CARBON:
      return {
        ...defaultCarbonState,
      };
    default:
      return state;
  }
};

export default carbonReducer;
