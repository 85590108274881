import type { PowerBIReport } from './state';

export const GET_COMPANY_POWERBI_REPORT = 'GET_COMPANY_POWERBI_REPORT';
export const GET_COMPANY_POWERBI_REPORT_SUCCESS =
  'GET_COMPANY_POWERBI_REPORT_SUCCESS';
export const GET_COMPANY_POWERBI_REPORT_FAIL =
  'GET_COMPANY_POWERBI_REPORT_FAIL';
export const RESET_COMPANY_POWERBI_REPORT = 'RESET_COMPANY_POWERBI_REPORT';

export interface getCompanyPowerBIReport {
  type: typeof GET_COMPANY_POWERBI_REPORT;
  payload: any;
}

export interface getCompanyPowerBIReportSuccess {
  type: typeof GET_COMPANY_POWERBI_REPORT_SUCCESS;
  payload: PowerBIReport;
}

export interface getCompanyPowerBIReportFail {
  type: typeof GET_COMPANY_POWERBI_REPORT_FAIL;
  payload: any;
}

export interface resetCompanyPowerBIReport {
  type: typeof RESET_COMPANY_POWERBI_REPORT;
  payload: any;
}

export type ProtocolDispatchTypes =
  | getCompanyPowerBIReport
  | getCompanyPowerBIReportSuccess
  | getCompanyPowerBIReportFail
  | resetCompanyPowerBIReport;
