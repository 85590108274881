import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  styled,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import type { ListOption } from '@/models/helper';

export const CarbonMapDialog = ({
  isOpen,
  handleClose,
  parkDetails,
}: {
  isOpen: boolean;
  handleClose: () => void;
  parkDetails: ListOption[];
}) => {
  const { t } = useTranslation();
  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <CarbonMapDialogTitle>
        {t('molecules.locationDetails')}
      </CarbonMapDialogTitle>
      <CarbonMapDialogContent>
        <List sx={{ pt: 0 }}>
          {parkDetails.map((field: ListOption, index: number) => (
            <div key={`wrapper-${index}`}>
              <CarbonMapListItem disableGutters key={`item-${index}`}>
                <CarbonMapLabel key={`label-${index}`}>
                  {field.label}
                </CarbonMapLabel>
                <CarbonMapValue key={`value-${index}`}>
                  {field.value}
                </CarbonMapValue>
              </CarbonMapListItem>
              <Divider key={`divider-${index}`} />
            </div>
          ))}
        </List>
      </CarbonMapDialogContent>
    </Dialog>
  );
};

const CarbonMapDialogTitle = styled(DialogTitle)(({ theme }) => ({
  textAlign: 'left',
  fontFamily: 'Montserrat-Bold',
  fontWeight: '600',
  fontSize: '20px',
  lineHeight: '28px',
  background: theme.palette.ui.neutral.white,
}));

const CarbonMapDialogContent = styled(DialogContent)(({ theme }) => ({
  minWidth: '365px',
  background: theme.palette.ui.neutral.white,
}));

const CarbonMapListItem = styled(ListItem)`
  &.MuiListItem-root {
    justify-content: space-between;
  }
`;

const CarbonMapLabel = styled(Typography)`
  &.MuiTypography-root {
    text-align: left;
    font-family: 'Montserrat-Bold';
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
  }
`;

const CarbonMapValue = styled(Typography)`
  &.MuiTypography-root {
    text-align: right;
    font-family: 'Open-Sans-Regular';
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
`;
