import { TableCell, TableRow, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { columnDataType } from '@/utils/carbon';

import type { ColumnType } from '@/components/Organisms/CarbonTable/CarbonTable';
import type { CarbonReport } from '@/redux/reducers/carbon/state';

interface CarbonTableRowProps {
  columns: readonly ColumnType[];
  rowData: CarbonReport;
  idxRow: number;
}

export const CarbonTableRow = ({
  rowData,
  columns,
  idxRow,
}: CarbonTableRowProps) => {
  const navigate = useNavigate();
  return (
    <TableRow hover role="checkbox" tabIndex={-1} key={idxRow}>
      {columns.map((column: ColumnType, idxColumn: number) => {
        return (
          <TableCell
            key={idxColumn}
            align={column.align}
            sx={{
              padding: '16px 24px',
              color:
                rowData.reportStatus === 'Ready'
                  ? column.id === 'reportName'
                    ? 'ui.palo.blue'
                    : 'ui.neutral.black'
                  : 'ui.neutral.greyLight',
            }}
            onClick={() =>
              column.id === 'reportName' &&
              navigate(
                `/carbon-coach/overview/${columnDataType(
                  'reportUuid',
                  rowData,
                )}`,
              )
            }
          >
            <Typography
              variant="body2"
              fontWeight={
                rowData.reportStatus === 'Ready'
                  ? column.id === 'reportName'
                    ? 700
                    : 400
                  : 400
              }
            >
              {columnDataType(column.id, rowData)}
            </Typography>
          </TableCell>
        );
      })}
    </TableRow>
  );
};
