import ReactEcharts from 'echarts-for-react';
import { useTranslation } from 'react-i18next';

import { RadarChartToolTip } from '@/components/Atoms';

import { theme } from '@/theme/theme';

import { formatScores } from '@/utils/chart';

import type { RadarParameter } from '@/models/chart';
import type { EChartsOption } from 'echarts-for-react';

export const RadarChart = ({
  parameters,
  showTitle = false,
}: {
  parameters: RadarParameter;
  showTitle: boolean;
}) => {
  const { scores, title, subtext, legend } = parameters;
  const {
    ui: {
      stroke,
      neutral: { black, white },
      primaryGreen,
    },
    background,
  } = theme.palette;

  const formattedScores = formatScores(scores);
  const { t } = useTranslation();

  const radarParameters: EChartsOption = {
    backgroundColor: background.default,
    color: primaryGreen.main,
    title: {
      text: title,
      subtext: subtext,
      show: showTitle,
      left: 'center',
      textStyle: {
        fontSize: '16px',
      },
      padding: 27,
    },
    legend: {
      data: [t('molecules.resultScore')],
      bottom: 'auto',
    },
    radar: {
      center: ['50%', '55%'],
      radius: '70%',
      scale: true,
      splitNumber: 4,
      z: 2,
      splitLine: {
        lineStyle: {
          color: stroke,
          width: 1.5,
        },
      },
      axisLine: {
        lineStyle: {
          color: stroke,
          width: 1.5,
        },
      },
      splitArea: {
        show: false,
      },
      indicator: [
        {
          name: formattedScores[0].name,
          max: formattedScores[0].max || 100,
          color: black,
          axisLabel: {
            show: true,
            color: black,
            margin: 0,
            fontSize: 12,
            textShadowColor: white,
            textShadowBlur: 1,
            align: 'center',
            formatter: function (value: number) {
              return Math.round(value * 100) / 100;
            },
          },
        },
        ...formattedScores.slice(1).map(score => ({
          name: score.name,
          max: score?.max || 100,
          color: black,
        })),
      ],
    },
    tooltip: {
      trigger: 'item',
      position: ['35%', '32%'],
      padding: 0,
      borderColor: stroke,
    },
    series: [
      {
        name: t('molecules.resultScore'),
        type: 'radar',
        areaStyle: {
          opacity: 0.5,
        },
        data: [
          {
            value: formattedScores.map(({ score }) => score?.toFixed(2) || 0),
            name: legend,
            tooltip: {
              formatter: function () {
                return RadarChartToolTip({
                  data: {
                    scores: formattedScores,
                    name: title,
                  },
                });
              },
            },
          },
        ],
      },
    ],
  };

  return (
    <ReactEcharts
      option={radarParameters}
      lazyUpdate={true}
      style={{ width: '100%', height: '100%' }}
    />
  );
};
