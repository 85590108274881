import { Coordinates } from 'Chart';
import cloneDeep from 'lodash/cloneDeep';

import { CarbonPark } from '@/redux/reducers/carbon/state';

// format to ensure the radar charts will have a proper shape by moving branches with 0 scores
export function formatScores(
  scores: { name: string; score: number; max: number }[],
) {
  const formattedScores = cloneDeep(scores);

  formattedScores.sort((a, b) => {
    a.name.replace('#', ' ');
    b.name.replace('#', ' ');
    if (a.score === 0) return 1;
    if (b.score === 0) return -1;
    return 0;
  });

  return formattedScores;
}

export function getCenterCoordinates(coordinates: Coordinates[]): Coordinates {
  let longitudeSum = 0;
  let latitudeSum = 0;
  const coordinatesNum = coordinates.length;

  for (const coordinate of coordinates) {
    longitudeSum += coordinate[0];
    latitudeSum += coordinate[1];
  }

  return [longitudeSum / coordinatesNum, latitudeSum / coordinatesNum];
}

export function getAllCoords(parkLineList: CarbonPark[]): Coordinates[] {
  const allCoords: Coordinates[] = [];

  for (const parkLine of parkLineList) {
    allCoords.push([parkLine.latitude, parkLine.longitude]);
  }

  return allCoords;
}
