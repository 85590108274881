import { UserInfo } from 'SustainablePlatform';

import { statusAPI } from '@/redux/stores/state';

import { AvailableOrganization } from './state';

export const AUTH_REQUEST = 'AUTH_REQUEST';
export const AUTH_REQUEST_SUCCESS = 'AUTH_REQUEST_SUCCESS';
export const AUTH_REQUEST_FAIL = 'AUTH_REQUEST_FAIL';

export const GET_USER_INFO = 'GET_USER_INFO';
export const GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS';
export const GET_USER_INFO_FAIL = 'GET_USER_INFO_FAIL';
export const GET_USER_AVAILABLE_ORGANIZATION_UUIDS =
  'GET_USER_AVAILABLE_ORGANIZATION_UUIDS';
export const GET_USER_AVAILABLE_ORGANIZATION_UUIDS_SUCCESS =
  'GET_USER_AVAILABLE_ORGANIZATION_UUIDS_SUCCESS';
export const GET_USER_AVAILABLE_ORGANIZATION_UUIDS_FAIL =
  'GET_USER_AVAILABLE_ORGANIZATION_UUIDS_FAIL';
export const UPDATE_USER_ORGANIZATION = 'UPDATE_USER_ORGANIZATION';
export const UPDATE_USER_ORGANIZATION_SUCCESS =
  'UPDATE_USER_ORGANIZATION_SUCCESS';
export const UPDATE_USER_ORGANIZATION_FAIL = 'UPDATE_USER_ORGANIZATION_FAIL';

export interface AuthRequest {
  type: typeof AUTH_REQUEST;
  payload: any;
}

export interface AuthRequestSuccess {
  type: typeof AUTH_REQUEST_SUCCESS;
  payload: any;
}

export interface AuthRequestFail {
  type: typeof AUTH_REQUEST_FAIL;
  payload: any;
}

export interface GetUserInfo {
  type: typeof GET_USER_INFO;
  payload: any;
}

export interface GetUserInfoSuccess {
  type: typeof GET_USER_INFO_SUCCESS;
  payload: UserInfo;
}

export interface GetUserInfoFail {
  type: typeof GET_USER_INFO_FAIL;
  payload: statusAPI;
}

export interface GetUserAvailableOrganizationUuids {
  type: typeof GET_USER_AVAILABLE_ORGANIZATION_UUIDS;
  payload: any;
}

export interface GetUserAvailableOrganizationUuidsSuccess {
  type: typeof GET_USER_AVAILABLE_ORGANIZATION_UUIDS_SUCCESS;
  payload: AvailableOrganization[];
}

export interface GetUserAvailableOrganizationUuidsFail {
  type: typeof GET_USER_AVAILABLE_ORGANIZATION_UUIDS_FAIL;
  payload: statusAPI;
}

export interface UpdateUserOrganization {
  type: typeof UPDATE_USER_ORGANIZATION;
  payload: any;
}

export interface UpdateUserOrganizationSuccess {
  type: typeof UPDATE_USER_ORGANIZATION_SUCCESS;
  payload: any;
}

export interface UpdateUserOrganizationFail {
  type: typeof UPDATE_USER_ORGANIZATION_FAIL;
  payload: any;
}

export type AuthDispatchTypes =
  | AuthRequest
  | AuthRequestSuccess
  | AuthRequestFail
  | GetUserInfo
  | GetUserInfoSuccess
  | GetUserInfoFail
  | GetUserAvailableOrganizationUuids
  | GetUserAvailableOrganizationUuidsSuccess
  | GetUserAvailableOrganizationUuidsFail
  | UpdateUserOrganization
  | UpdateUserOrganizationSuccess
  | UpdateUserOrganizationFail;
