import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ActionItemCard } from '@/components/Molecules';

import {
  getAllActions,
  updateActionStatus,
} from '@/redux/reducers/action/actions';
import { useAppDispatch, useAppSelector } from '@/redux/stores/hooks';

import {
  ActionListStatusRow,
  CustomLoader,
  LoaderWrapper,
  PageContainer,
} from './ActionList.styles';

export const ActionListPage = () => {
  const dispatch = useAppDispatch();
  const { isLoading, actionList } = useAppSelector(store => store.action);
  const { t } = useTranslation();

  const completedActions = actionList?.filter(action => action?.completed);
  const inProgressActions = actionList?.filter(action => !action?.completed);

  useEffect(() => {
    if (actionList?.length < 1) {
      dispatch(getAllActions());
    }
  }, [dispatch]);

  const handleCompleteAction = async (actionId: string, isComplete: boolean) =>
    dispatch(
      updateActionStatus({
        actionId,
        isComplete,
      }),
    );

  return (
    <>
      {isLoading && (
        <LoaderWrapper>
          <CustomLoader />
        </LoaderWrapper>
      )}
      <PageContainer>
        <div
          className="headline1-desktop"
          style={{ color: 'var(--text_primary)', fontWeight: 700 }}
        >
          {t('pages.action')}
        </div>

        {!!inProgressActions?.length && (
          <div>
            <ActionListStatusRow>
              {t('pages.toDo')} ({inProgressActions?.length || 0})
            </ActionListStatusRow>
            <div className="body1-desktop">
              {inProgressActions?.map(action => (
                <ActionItemCard
                  key={`incompleted-action-${action?.uuid}`}
                  actionId={action?.uuid}
                  isCompleted={action?.completed}
                  description={action?.description}
                  comment={action?.comment}
                  handleUpdateAction={handleCompleteAction}
                  expanded={false}
                />
              ))}
            </div>
          </div>
        )}

        {!!completedActions?.length && (
          <div>
            <ActionListStatusRow>
              {t('pages.completed')} ({completedActions?.length || 0})
            </ActionListStatusRow>
            <div
              className="body1-desktop"
              style={{ color: 'var(--text_secondary)' }}
            >
              {completedActions?.map(action => (
                <ActionItemCard
                  key={`completed-action-${action?.uuid}`}
                  actionId={action?.uuid}
                  isCompleted={action?.completed}
                  description={action?.description}
                  comment={action?.comment}
                  handleUpdateAction={handleCompleteAction}
                  expanded={false}
                />
              ))}
            </div>
          </div>
        )}
      </PageContainer>
    </>
  );
};
