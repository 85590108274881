import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { CarbonMapDialog, NavBreadcrumbs } from '@/components/Molecules';
import {
  CarbonChartsContainer,
  CarbonReportInfoContainer,
} from '@/components/Templates';

import { ListOption } from '@/models/helper';

import { makeParkDetailContent } from '@/utils/carbon';

import {
  getCarbonParkDetail,
  getCarbonReportDetail,
} from '@/redux/reducers/carbon/actions';
import { CarbonPark } from '@/redux/reducers/carbon/state';
import { useAppDispatch, useAppSelector } from '@/redux/stores/hooks';

import { Container, Title } from './CarbonReportDetailPage.styles';

import type { BreadcrumbItem } from '@/components/Molecules/NavBreadcrumbs/NavBreadcrumbs';

export const CarbonReportDetailPage = () => {
  const { reportID } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isLoading, isLoadingMap, carbonReportDetail, carbonParkDetail } =
    useAppSelector(store => store.carbon);
  const [parkLineList, setParkLineList] = useState<CarbonPark[]>([]);
  const [parkDetailContent, setParkDetailContent] = useState<ListOption[]>([]);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const BreadcrumbItemList: BreadcrumbItem[] = useMemo(
    () => [
      {
        key: '1',
        title: t('pages.carbon'),
        color: 'rgb(106, 113, 111)',
        link: true,
        onClickAction: () => navigate('/carbon-coach'),
      },
      {
        key: '2',
        title: t('pages.carbonFootprintReport'),
        color: 'rgb(32, 40, 38)',
        link: false,
        onClickAction: () => null,
      },
    ],
    [],
  );

  const handleClickOpen = useCallback((parkLine: CarbonPark) => {
    setParkDetailContent(makeParkDetailContent(parkLine));
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    if (reportID) {
      dispatch(getCarbonReportDetail({ reportUuid: reportID }));
    }
  }, [dispatch, reportID]);

  useEffect(() => {
    if (carbonReportDetail?.report.parkUuid) {
      dispatch(
        getCarbonParkDetail({ parkUuid: carbonReportDetail?.report?.parkUuid }),
      );
    }
  }, [dispatch, carbonReportDetail]);

  useEffect(() => {
    if (carbonParkDetail) {
      setParkLineList(carbonParkDetail);
    }
  }, [carbonParkDetail]);

  return (
    <>
      <CarbonMapDialog
        isOpen={open}
        handleClose={handleClose}
        parkDetails={parkDetailContent}
      />
      <Container>
        <Title>
          <NavBreadcrumbs breadcrumbItems={BreadcrumbItemList} />
          <div className="headline3-desktop">
            {t('pages.digitalSolutionCarbon')}
          </div>
        </Title>
        <CarbonReportInfoContainer reportDetail={carbonReportDetail} />
        <CarbonChartsContainer
          reportDetail={carbonReportDetail}
          isLoading={isLoadingMap || isLoading}
          parkLineList={parkLineList}
          openParkLineDetail={handleClickOpen}
        />
      </Container>
    </>
  );
};
