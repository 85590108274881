import axios from 'axios';

import { generateDefaultApiHeaders } from '@/utils/api';

import { webServices } from '@/redux/stores/api';

import {
  CLEAR_CARBON_PARK_DETAIL,
  GET_ALL_CARBON_REPORT,
  GET_ALL_CARBON_REPORT_FAIL,
  GET_ALL_CARBON_REPORT_RELOAD,
  GET_ALL_CARBON_REPORT_SUCCESS,
  GET_CARBON_PARK_DETAIL,
  GET_CARBON_PARK_DETAIL_FAIL,
  GET_CARBON_PARK_DETAIL_SUCCESS,
  GET_CARBON_PARK_LIST,
  GET_CARBON_PARK_LIST_FAIL,
  GET_CARBON_PARK_LIST_SUCCESS,
  GET_CARBON_REPORT_DETAIL,
  GET_CARBON_REPORT_DETAIL_FAIL,
  GET_CARBON_REPORT_DETAIL_SUCCESS,
  UPLOAD_FILE,
  UPLOAD_FILE_FAIL,
  UPLOAD_FILE_SUCCESS,
} from './actionTypes';

import type { RootState } from '@/redux/stores/store';
import type { AnyAction, ThunkAction } from '@reduxjs/toolkit';

// GET
export const getAllCarbonReportList = ({
  refresh,
}: {
  refresh: boolean;
}): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async dispatch => {
    try {
      if (refresh) {
        dispatch({ type: GET_ALL_CARBON_REPORT_RELOAD });
      } else {
        dispatch({ type: GET_ALL_CARBON_REPORT });
      }
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT_ROOT}/${webServices.getAllCarbonReport}`,
        JSON.stringify({}),
        generateDefaultApiHeaders(),
      );
      dispatch({
        type: GET_ALL_CARBON_REPORT_SUCCESS,
        payload: response.data.result,
      });
    } catch (err) {
      dispatch({
        type: GET_ALL_CARBON_REPORT_FAIL,
        payload: JSON.stringify(err),
      });
    }
  };
};

export const getCarbonReportDetail = ({
  reportUuid,
}: {
  reportUuid: string;
}): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async dispatch => {
    try {
      dispatch({
        type: GET_CARBON_REPORT_DETAIL,
      });
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT_ROOT}/${webServices.getCarbonReportDetail}`,
        JSON.stringify({ report_uuid: reportUuid }),
        generateDefaultApiHeaders(),
      );
      dispatch({
        type: GET_CARBON_REPORT_DETAIL_SUCCESS,
        payload: response.data.result,
      });
    } catch (err) {
      dispatch({
        type: GET_CARBON_REPORT_DETAIL_FAIL,
        payload: JSON.stringify(err),
      });
    }
  };
};

export const getCarbonParkList = (): ThunkAction<
  void,
  RootState,
  unknown,
  AnyAction
> => {
  return async dispatch => {
    try {
      dispatch({ type: GET_CARBON_PARK_LIST });
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT_ROOT}/${webServices.getCarbonParkList}`,
        JSON.stringify({}),
        generateDefaultApiHeaders(),
      );
      dispatch({
        type: GET_CARBON_PARK_LIST_SUCCESS,
        payload: response.data.result,
      });
    } catch (err) {
      dispatch({
        type: GET_CARBON_PARK_LIST_FAIL,
        payload: JSON.stringify(err),
      });
    }
  };
};

export const getCarbonParkDetail = ({
  parkUuid,
}: {
  parkUuid: string;
}): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async dispatch => {
    try {
      dispatch({ type: GET_CARBON_PARK_DETAIL });
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT_ROOT}/${webServices.getCarbonParkDetail}`,
        JSON.stringify({ park_uuid: parkUuid }),
        generateDefaultApiHeaders(),
      );
      dispatch({
        type: GET_CARBON_PARK_DETAIL_SUCCESS,
        payload: response.data.result,
      });
    } catch (err) {
      dispatch({
        type: GET_CARBON_PARK_DETAIL_FAIL,
        payload: JSON.stringify(err),
      });
    }
  };
};

// POST
export const uploadFile = ({
  fileName,
  reportName,
  reportUuid,
  parkUuid,
  file,
}: {
  fileName: string;
  reportName: string;
  reportUuid: string;
  parkUuid: string;
  file: any;
}): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async dispatch => {
    try {
      dispatch({ type: UPLOAD_FILE });

      const cfUpload = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT_ROOT}/${webServices.uploadHARFile}`,
        JSON.stringify({
          park_uuid: parkUuid,
          report_name: reportName,
          device_uuid: process.env.REACT_APP_TEST_HAR_DEVICE_UUID,
          report_uuid: reportUuid,
          file_name: fileName,
        }),
        generateDefaultApiHeaders(),
      );

      await uploadToS3({
        presignedUrl: cfUpload.data.result.presignedlink,
        file,
      });
      dispatch({
        type: UPLOAD_FILE_SUCCESS,
        payload: cfUpload.data,
      });
    } catch (err) {
      dispatch({
        type: UPLOAD_FILE_FAIL,
        payload: JSON.stringify(err),
      });
    }
  };
};

export interface PresignedLink {
  url: string;
  fields: Map<string, string>;
}

export async function uploadToS3({
  presignedUrl,
  file,
}: {
  presignedUrl: PresignedLink;
  file: File;
}) {
  const formData = new FormData();
  Object.entries(presignedUrl.fields).forEach(([key, value]) => {
    formData.append(key, value);
  });
  formData.append('file', file);

  const response = await axios.post(presignedUrl.url, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });

  return response;
}

export function clearCarbonParkDetail() {
  return { type: CLEAR_CARBON_PARK_DETAIL };
}
