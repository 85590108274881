import { Box, styled } from '@mui/material';
import ReactEcharts from 'echarts-for-react';

import type { EChartsOption } from 'echarts-for-react';

interface PieChartProps {
  chartData: { value: number; name: string }[];
}

export const PieChart = ({ chartData }: PieChartProps) => {
  const optionPieChart: EChartsOption = {
    tooltip: {
      trigger: 'item',
      formatter: '{b} &nbsp; &nbsp; {c}, {d}%',
    },
    legend: {
      top: '5%',
      left: 'center',
    },
    series: [
      {
        type: 'pie',
        radius: '50%',
        data: chartData,
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
      },
    ],
  };
  return (
    <PieChartWrapper>
      <ReactEcharts
        option={optionPieChart}
        lazyUpdate={true}
        style={{ width: '100%', height: '100%' }}
      />
    </PieChartWrapper>
  );
};

const PieChartWrapper = styled(Box)`
  display: flex;
  box-sizing: border-box;
  width: 100vw;
  max-width: 1000px;
  height: 100%;
  min-height: 500px;
`;
