import { useEffect, useState } from 'react';
import { Box, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  CustomButton as NextButton,
  ProgressBar,
  CustomButton as UpdateButton,
} from '@/components/Atoms';
import { MultipleChoiceButton } from '@/components/Molecules';
import { QuestionHintDialog } from '@/components/Organisms';

import { renderTagColor } from '@/utils/general';

import { updateLPQuizQuestion } from '@/redux/reducers/quiz/actions';
import { useAppDispatch, useAppSelector } from '@/redux/stores/hooks';

import type { AnswerOption } from '@/redux/reducers/gr491/state';
import type { Quiz, QuizQuestion } from '@/redux/reducers/quiz/state';

interface QuizQuestionContainerProps {
  quizID: string;
  quizQuestionDetails: QuizQuestion;
  setShowFeedback: any;
  setCorrectAnswer: any;
  setFeedbackItem: any;
  isCompleted: boolean;
  setIsCompleted: any;
}

export const QuizQuestionContainer: React.FC<QuizQuestionContainerProps> = ({
  quizID,
  quizQuestionDetails,
  setShowFeedback,
  setCorrectAnswer,
  setFeedbackItem,
  isCompleted,
  setIsCompleted,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { quizList, quizQuestion, quizQuestionUpdateStatus, isUpdating } =
    useAppSelector(store => store.quiz);
  const [openHint, setOpenHint] = useState<boolean>(false);
  const [totalQuestionCount, setTotalQuestionCount] = useState<number>(0);
  const [disableConfirm, setDisableConfirm] = useState<boolean>(true);
  const [progress, setProgress] = useState<number>(0);
  const [selectedOption, setSelectedOption] = useState<string>('');
  const { t } = useTranslation();
  const {
    nextQuestionUuid,
    options,
    question,
    questionUuid,
    questionOrder,
    quizUuid,
    tags,
  } = quizQuestionDetails;

  const handleSubmitAnswer = async () => {
    await dispatch(
      updateLPQuizQuestion({
        quizID: quizID,
        questionID: questionUuid,
        answerID: selectedOption,
      }),
    );
    setIsCompleted(true);
  };

  const handleSelectOption = async ({ answerUuid }: { answerUuid: string }) => {
    setDisableConfirm(false);
    setSelectedOption(answerUuid);
  };

  const handleClickNext = async () => {
    setDisableConfirm(true);
    setSelectedOption('');
    setIsCompleted(false);
    setFeedbackItem(null);
    setCorrectAnswer(false);
    setShowFeedback(false);
    if (nextQuestionUuid !== null) {
      navigate(`/learning-playground/${quizUuid}/${nextQuestionUuid}`);
    } else if (nextQuestionUuid === null) {
      navigate(`/learning-playground/result/${quizUuid}`);
    }
  };

  useEffect(() => {
    const currentQuiz: Quiz[] = quizList.filter(
      (quiz: Quiz) => quiz.uuid === quizQuestion.quizUuid,
    );
    if (currentQuiz[0]) {
      setTotalQuestionCount(currentQuiz[0].questionsTotal);
    }
  }, [quizList, quizQuestion.quizUuid]);

  useEffect(() => {
    setProgress((questionOrder * 100) / totalQuestionCount);
  }, [questionOrder, totalQuestionCount]);

  useEffect(() => {
    const feedbackOptionObject: AnswerOption | undefined =
      quizQuestion.options.find((item: AnswerOption) => !!item.isCorrect);

    if (isCompleted) {
      setShowFeedback(true);
      setFeedbackItem(feedbackOptionObject);
    }
  }, [quizQuestion.options, isCompleted, setShowFeedback, setFeedbackItem]);

  useEffect(() => {
    const selectedOptionObject: AnswerOption | undefined =
      quizQuestion.options.find(
        (item: AnswerOption) => item.answerUuid === selectedOption,
      );

    if (selectedOptionObject?.isCorrect) {
      setCorrectAnswer(true);
    } else if (!selectedOptionObject?.isCorrect) {
      setCorrectAnswer(false);
    }
  }, [quizQuestion.options, selectedOption, setCorrectAnswer]);

  return (
    <CardContainer>
      <CardProgressBar>
        <CardProgressBarText>
          <Typography variant="body2">{`${t(
            'templates.question',
          )} ${questionOrder}/${totalQuestionCount}`}</Typography>
        </CardProgressBarText>
        <ProgressBar value={progress} width="100%" />
      </CardProgressBar>
      <CardQuestion>
        <CardTags>
          {tags.map((tagItem: string, idx: number) => (
            <CardTagsItem
              style={{ background: renderTagColor(tagItem) }}
              key={idx}
            >
              <Typography variant="body2">{tagItem}</Typography>
            </CardTagsItem>
          ))}
        </CardTags>
        <CardTitle>
          <Typography variant="h2">{question}</Typography>
        </CardTitle>
      </CardQuestion>
      <QuestionHintDialog
        openDialog={openHint}
        handleClose={() => setOpenHint(false)}
        quizQuestion={quizQuestion}
      />
      <CardOptions>
        {options.map((optionItem: AnswerOption, idx: number) => (
          <MultipleChoiceButton
            key={idx}
            text={optionItem.answer}
            focused={selectedOption === optionItem.answerUuid ? true : false}
            disabled={isCompleted || isUpdating}
            onClickAction={() =>
              handleSelectOption({ answerUuid: optionItem.answerUuid })
            }
          />
        ))}
      </CardOptions>
      <Box>
        {isCompleted &&
        quizQuestionUpdateStatus.questionUuid === quizQuestion.questionUuid ? (
          <NextButton
            level="Primary"
            width="100%"
            text={
              nextQuestionUuid === null
                ? t('templates.seeResults')
                : t('templates.nextQuestion')
            }
            iconType="Right"
            onClickAction={() => handleClickNext()}
          />
        ) : (
          <UpdateButton
            disabled={disableConfirm}
            level="Primary"
            width="100%"
            text={t('templates.confirmAnswer')}
            iconType="Right"
            onClickAction={() => handleSubmitAnswer()}
            isLoading={isUpdating}
          />
        )}
      </Box>
    </CardContainer>
  );
};

const CardContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  boxSizing: 'border-box',
  width: '100%',
  height: '100%',
  gap: '24px',
}));

const CardProgressBar = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  boxSizing: 'border-box',
  gap: '8px',
  width: '100%',
}));

const CardProgressBarText = styled(Box)(({ theme }) => ({
  display: 'flex',
  fontWeight: '400',
  color: theme.palette.text.primary,
  whiteSpace: 'nowrap',
}));

const CardQuestion = styled(Box)(() => ({
  display: 'flex',
  boxSizing: 'border-box',
  flexDirection: 'column',
  width: '100%',
  gap: '8px',
}));

const CardTags = styled(Box)(() => ({
  display: 'flex',
  boxSizing: 'border-box',
  width: '100%',
  gap: '12px',
}));

const CardTagsItem = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '2px 8px',
  borderRadius: '3px',
}));

const CardTitle = styled(Box)(() => ({
  display: 'flex',
  boxSizing: 'border-box',
  width: '100%',
}));

const CardOptions = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
  boxSizing: 'border-box',
  color: theme.palette.text.primary,
  height: 'fit-content',
  gap: '16px',
}));
