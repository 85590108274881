import { Box, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';

import { theme } from '@/theme/theme';

export const LoadingWheel = ({ isChart = false }: { isChart?: boolean }) => {
  return (
    <StyledLoadingContainer
      sx={{
        background: isChart ? theme.palette.ui.primaryGreen.light : null,
      }}
    >
      <StyledCircularProgress />
    </StyledLoadingContainer>
  );
};

const StyledLoadingContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
}));

const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
  color: theme.palette.ui.primaryGreen.main,
}));
