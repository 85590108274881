export interface RadarScore {
  name: string;
  score: number;
  max: number;
}

export interface PowerBIReport {
  report_URL: string;
}

export interface IDefaultProtocolState {
  lastUpdatedDate: string;
  powerBIReport: PowerBIReport;
  isLoading: boolean;
  isError: boolean;
  errorMsg: any | null;
}

export const defaultProtocolState: IDefaultProtocolState = {
  lastUpdatedDate: '',
  powerBIReport: {
    report_URL: '',
  },
  isLoading: false,
  isError: false,
  errorMsg: null,
};
