import { useEffect, useState } from 'react';
import { Box, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  CustomButton as BackButton,
  LoadingWheel,
  CustomButton as UpdateButton,
} from '@/components/Atoms';
import { MultipleChoiceButton } from '@/components/Molecules';

import { renderSDGNumbersOnly } from '@/utils/general';

import { updateGR491QuestionDetails } from '@/redux/reducers/gr491/actions';
import { useAppDispatch, useAppSelector } from '@/redux/stores/hooks';

import type { UserAnswer } from '@/redux/reducers/gr491/state';

interface QuestionContainerProps {
  familyID?: string;
}

export const QuestionContainer = ({ familyID }: QuestionContainerProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [selectedOption, setSelectedOption] = useState<string>('');
  const { questionDetail, isLoading } = useAppSelector(store => store.gr491);
  const { t } = useTranslation();

  const { tag, question, options = [], userAnswer, uuid } = questionDetail;
  const userAnswerUUID = userAnswer?.uuid || '';
  const cardCaption =
    tag === ''
      ? t('templates.globalSdg')
      : `${renderSDGNumbersOnly(tag || '')}`;

  useEffect(() => {
    setSelectedOption(userAnswerUUID);
  }, [userAnswerUUID]);

  const handleSubmitAnswer = async () => {
    dispatch(
      updateGR491QuestionDetails({
        questionID: uuid,
        answerID: selectedOption,
      })
    );
  };

  return (
    <CardContainer>
      <CardSDG>{cardCaption}</CardSDG>
      {isLoading ? (
        <LoadingWheel isChart={true} />
      ) : (
        <CardTitle>{question}</CardTitle>
      )}
      <CardOptions>
        {options.map((optionItem: UserAnswer, idx: number) => (
          <MultipleChoiceButton
            key={idx}
            text={optionItem.answer}
            focused={optionItem.uuid === selectedOption}
            onClickAction={() => setSelectedOption(optionItem.uuid)}
          />
        ))}
      </CardOptions>
      <CardNextButton>
        {selectedOption === userAnswerUUID ? (
          <BackButton
            level="Primary"
            width="200px"
            text={t('templates.goBack')}
            iconType="Left"
            onClickAction={() => navigate(`/sustainable-it-audit/${familyID}`)}
          />
        ) : (
          <UpdateButton
            level="Primary"
            width="200px"
            text={t('templates.save')}
            iconType="Right"
            isLoading={isLoading}
            onClickAction={() => handleSubmitAnswer()}
          />
        )}
      </CardNextButton>
    </CardContainer>
  );
};

const CardContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  boxSizing: 'border-box',
  width: '100%',
  height: '100%',
  gap: '40px',
}));

const CardSDG = styled(Box)(({ theme }) => ({
  ...theme.typography.h3,
}));

const CardTitle = styled(Box)(({ theme }) => ({
  display: 'flex',
  boxSizing: 'border-box',
  width: '100%',
  ...theme.typography.h2,
}));

const CardOptions = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
  boxSizing: 'border-box',
  color: theme.palette.text.primary,
  height: 'fit-content',
  gap: '16px',
}));

const CardNextButton = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  width: '100%',
}));
