import { combineReducers } from 'redux';

import { deliveryPlaybookApi } from '../api';
import actionReducer from './action/reducer';
import authReducer from './auth/reducer';
import carbonReducer from './carbon/reducer';
import chatbotReducer from './chatbot/reducer';
import gr491Reducer from './gr491/reducer';
import protocolReducer from './protocol/reducer';
import quizReducer from './quiz/reducer';

const appReducer = combineReducers({
  // RTK-Query reducers
  [deliveryPlaybookApi.reducerPath]: deliveryPlaybookApi.reducer,
  // Redux reducers
  action: actionReducer,
  auth: authReducer,
  carbon: carbonReducer,
  chatbot: chatbotReducer,
  gr491: gr491Reducer,
  protocol: protocolReducer,
  quiz: quizReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'USER_SIGNOUT') {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
