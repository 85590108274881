import { getIdToken } from './auth';

export const generateDefaultApiHeaders = () => ({
  method: 'POST',
  headers: {
    Authorization: `Bearer ${getIdToken()}`,
    'Content-Type': 'application/json',
    'x-api-key': process.env.REACT_APP_API_KEY,
  },
});
