import { Box, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { renderTagColor } from '@/utils/general';

import type { QuizResult } from '@/redux/reducers/quiz/state';

export const QuizResultScore = ({ scores }: { scores: QuizResult[] }) => {
  const { t } = useTranslation();
  return (
    <QuizResultContainer>
      <Typography variant="subtitle1">{t('organisms.byCategory')}</Typography>
      {scores.map((categoryItem: QuizResult, idx: number) => (
        <QuizResultCategory key={idx}>
          <QuizResultTag
            variant="body2"
            style={{
              background: renderTagColor(categoryItem.category),
            }}
          >
            {categoryItem.category}
          </QuizResultTag>
          <Typography variant="body2">{`${categoryItem.answersCorrect} ${t(
            'organisms.outOf',
          )} ${categoryItem.questionsTotal}`}</Typography>
        </QuizResultCategory>
      ))}
    </QuizResultContainer>
  );
};

const QuizResultContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  boxSizing: 'border-box',
  gap: 12,
  width: '100%',
  height: '100%',
}));

const QuizResultCategory = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-evenly',
  gap: 12,
  boxSizing: 'border-box',
}));

const QuizResultTag = styled(Typography)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 100,
  padding: '2px 8px',
  borderRadius: 3,
}));
