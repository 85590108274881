export const carbonReportMenuItems: {
  id: number;
  name: string;
  nameKey: string;
}[] = [
  {
    id: 1,
    name: 'Park Overview',
    nameKey: 'constants.parkOverview',
  },
  {
    id: 2,
    name: 'Content Overview',
    nameKey: 'constants.contentOverview',
  },
  {
    id: 3,
    name: 'Content Breakdown',
    nameKey: 'constants.contentBreakdown',
  },
  {
    id: 4,
    name: 'Energy Consumption',
    nameKey: 'constants.energyConsumption',
  },
  {
    id: 5,
    name: 'Carbon Emission',
    nameKey: 'constants.carbonEmission',
  },
];
