import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { LoginContainer } from '@/components/Templates';

import { Logo as HomeLogo } from '@/assets/images';

import { authenticate } from '@/redux/reducers/auth/actions';
import {
  AUTH_REQUEST,
  AUTH_REQUEST_FAIL,
  AUTH_REQUEST_SUCCESS,
} from '@/redux/reducers/auth/actionTypes';
import { useAppDispatch } from '@/redux/stores/hooks';

import { LoginPageContainer, LoginPageWrapper } from './LoginPage.styles';

export const LoginPage = ({ isLoggedIn }: { isLoggedIn: boolean }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/');
    }
  }, [navigate]);

  const onSubmit = async (event: any) => {
    try {
      event.preventDefault();
      dispatch({ type: AUTH_REQUEST });
      await authenticate({ email, password });
      dispatch({ type: AUTH_REQUEST_SUCCESS });
      navigate('/');
    } catch (err) {
      dispatch({
        type: AUTH_REQUEST_FAIL,
        payload: JSON.stringify(err),
      });
    }
  };

  return (
    <LoginPageWrapper>
      <LoginPageContainer>
        <img
          src={HomeLogo}
          alt="login-page-logo"
          style={{
            height: '100%',
          }}
        />
      </LoginPageContainer>
      <LoginContainer
        handleEmailChange={setEmail}
        handlePasswordChange={setPassword}
        onSubmit={onSubmit}
        email={email}
        password={password}
      />
    </LoginPageWrapper>
  );
};
