import { Done } from '@mui/icons-material';
import {
  Box,
  ButtonBase,
  Divider,
  Tooltip,
  tooltipClasses,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { renderSDGNumbersOnly } from '@/utils/general';

import type { CategoryQuestion } from '@/redux/reducers/gr491/state';
import type { TooltipProps } from '@mui/material/Tooltip';

interface QuestionCardProps {
  familyID: string;
  item: CategoryQuestion;
}

export const QuestionCard = ({ familyID, item }: QuestionCardProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <ButtonBase component="div">
      <QuestionsCardContainer
        onClick={() =>
          navigate(`/sustainable-it-audit/${familyID}/${item.uuid}`)
        }
      >
        <QuestionsCardHeader>
          {item.tag === ''
            ? t('organisms.globalSdg')
            : `${renderSDGNumbersOnly(item.tag)}`}
          {item.completed && (
            <Done
              fontSize="small"
              sx={{
                justifySelf: 'right',
                color: 'ui.primaryGreen.main',
              }}
            />
          )}
        </QuestionsCardHeader>

        <TooltipQuestion
          title={
            <>
              <Typography variant="body2" sx={{ paddingBottom: '5px' }}>
                {item.tag === ''
                  ? t('organisms.globalSdg')
                  : `${renderSDGNumbersOnly(item.tag)}`}
              </Typography>
              <Divider />
              <Box sx={{ paddingTop: '5px' }}> {item.question} </Box>
            </>
          }
        >
          <QuestionPart>{item.question}</QuestionPart>
        </TooltipQuestion>
      </QuestionsCardContainer>
    </ButtonBase>
  );
};

const QuestionsCardContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  boxSizing: 'border-box',
  alignItems: 'flex-start',
  padding: '24px',
  gap: '16px',
  width: '100%',
  height: '100%',
  textAlign: 'left',

  background: theme.palette.ui.neutral.white,
  boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.16)',
  borderRadius: '8px',
}));

const QuestionsCardHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  ...theme.typography.body2,
}));

const QuestionPart = styled(Box)(({ theme }) => ({
  ...theme.typography.subtitle1,

  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '3',
  WebkitBoxOrient: 'vertical',
}));

const TooltipQuestion = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.ui.neutral.white,
    color: theme.palette.ui.neutral.black,
    ...theme.typography.subtitle1,
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.16)',
    borderRadius: '8px',
    padding: '15px',
  },
}));
