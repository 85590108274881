import { styled } from '@mui/material';

export const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: 24,
  gap: 24,
  width: '100%',
});

export const Title = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
  fontWeight: 700,
});
