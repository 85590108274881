import {
  ActionDispatchTypes,
  GET_ALL_ACTIONS,
  GET_ALL_ACTIONS_FAIL,
  GET_ALL_ACTIONS_SUCCESS,
  RESET_ACTIONS,
  UPDATE_ACTION_STATUS,
  UPDATE_ACTION_STATUS_FAIL,
  UPDATE_ACTION_STATUS_SUCCESS,
  UPDATE_ACTION_TOTALS,
} from './actionTypes';

import type { Action } from './state';

export interface IDefaultActionState {
  actionList: Action[];
  totalActions: number;
  totalCompletedActions: number;
  lastUpdatedDate: string;
  monthOverMonthActions: number;
  isLoading: boolean;
  isError: boolean;
  errorMsg: any | null;
}

export const defaultActionState: IDefaultActionState = {
  actionList: [],
  totalActions: 0,
  totalCompletedActions: 0,
  lastUpdatedDate: '',
  monthOverMonthActions: 0,
  isLoading: false,
  isError: false,
  errorMsg: null,
};

const actionReducer = (
  state: IDefaultActionState = defaultActionState,
  action: ActionDispatchTypes,
): IDefaultActionState => {
  switch (action.type) {
    case GET_ALL_ACTIONS:
      return {
        ...state,
        isLoading: true,
        isError: false,
        errorMsg: null,
      };
    case GET_ALL_ACTIONS_SUCCESS:
      return {
        ...state,
        actionList: action.payload,
        isLoading: false,
        isError: false,
        errorMsg: null,
      };
    case GET_ALL_ACTIONS_FAIL:
      return {
        ...state,
        actionList: [],
        isLoading: false,
        isError: true,
        errorMsg: action.payload,
      };
    case UPDATE_ACTION_TOTALS:
      return {
        ...state,
        totalActions: action.payload.totalActions,
        totalCompletedActions: action.payload.totalCompletedActions,
        lastUpdatedDate: action.payload.lastUpdatedDate,
      };
    case UPDATE_ACTION_STATUS:
      return {
        ...state,
        isLoading: true,
        isError: false,
        errorMsg: null,
      };
    case UPDATE_ACTION_STATUS_SUCCESS:
      return {
        ...state,
        isError: false,
        errorMsg: null,
      };
    case UPDATE_ACTION_STATUS_FAIL:
      return {
        ...state,
        isLoading: false,
        isError: true,
        errorMsg: action.payload,
      };
    case RESET_ACTIONS:
      return {
        ...defaultActionState,
      };

    default:
      return state;
  }
};

export default actionReducer;
