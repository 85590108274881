import ReactEcharts from 'echarts-for-react';

import {
  SankeyChartDomainToolTip,
  SankeyChartSdgToolTip,
} from '@/components/Atoms';

import { theme } from '@/theme/theme';

import { isSDGvalue } from '@/utils/general';

import type { SanKeyParameter, SanKeyResult } from '@/models/chart';
import type { EChartsOption } from 'echarts-for-react';

const makeSankeyLabel = (
  name: string,
  position: string,
  results: SanKeyResult[],
) => ({
  name,
  label: {
    fontSize: 14,
    fontFamily: theme.typography.subtitle2.fontFamily,
    position,
  },
  tooltip: {
    borderColor: 'var(--stroke)',
    formatter: function (params: any) {
      const isSDG = isSDGvalue(params.name);
      let questionsTotal = 0;
      let questionsCompleted = 0;
      let totalDomainScore = 0;
      let totalSdgScore = 0;
      let title = '';

      for (const result of results) {
        if (result.domains.toLowerCase() === params.name.toLowerCase()) {
          questionsTotal += result.questionsTotal;
          questionsCompleted += result.questionsCompleted;
          totalDomainScore += result.score;
        }
        if (result.sdg.toLowerCase() === params.name.toLowerCase()) {
          totalSdgScore += result.score;
          title = result.sdgTitle;
        }
      }

      if (isSDG) {
        const domainContributions = results.filter(
          (result: SanKeyResult) =>
            result.sdg.toLowerCase() === params.name.toLowerCase(),
        );

        return SankeyChartSdgToolTip({
          data: {
            name: params.name,
            title,
            score: params.value,
            domainContributions,
            totalScore: totalSdgScore,
          },
        });
      }

      const sdgContributions = results.filter(
        (result: SanKeyResult) =>
          result.domains.toLowerCase() === params.name.toLowerCase(),
      );
      return SankeyChartDomainToolTip({
        data: {
          name: params.name,
          totalScore: totalDomainScore,
          questionsTotal,
          questionsCompleted,
          sdgContributions,
        },
      });
    },
  },
});

export const SanKeyChart = ({
  parameters,
  showTitle = false,
}: {
  parameters: SanKeyParameter;
  showTitle?: boolean;
}) => {
  const sankeyParameters: EChartsOption = {
    title: {
      text: parameters.title,
      subtext: parameters.subtext,
      show: showTitle,
    },
    legend: {
      data: parameters.legend,
    },
    tooltip: {
      trigger: 'item',
      position: ['35%', '32%'],
      padding: 0,
    },
    series: {
      type: 'sankey',
      layout: 'none',
      draggable: false,
      emphasis: {
        focus: 'adjacency',
      },
      top: showTitle ? 25 : 0,
      width: '90%',
      height: '85%',
      data: [
        ...parameters.categories.map(category =>
          makeSankeyLabel(category, 'right', parameters.results),
        ),
        ...parameters.sdgGoals.map(goal =>
          makeSankeyLabel(goal, 'left', parameters.results),
        ),
      ],
      links: parameters.links,
    },
  };

  return (
    <ReactEcharts
      option={sankeyParameters}
      lazyUpdate={true}
      style={{ width: '100%', height: '100%' }}
    />
  );
};
