import { styled } from '@mui/material';

import { Header } from '@/components/Organisms';
import { ChatbotContainer, SideMenuContainer } from '@/components/Templates';

export const Layout = ({
  children,
  isLoggedIn,
  handleClickUser,
}: {
  children: any;
  isLoggedIn: boolean;
  handleClickUser: () => void;
}) => {
  return (
    <>
      {isLoggedIn && <Header onClickUsersAction={handleClickUser} />}
      {isLoggedIn && <SideMenuContainer />}
      {isLoggedIn && <ChatbotContainer />}
      {isLoggedIn ? <ContentWrapper>{children}</ContentWrapper> : children}
    </>
  );
};

export const ContentWrapper = styled('main')(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  boxSizing: 'border-box',
  top: 56,
  left: 245,
  right: 0,
  bottom: 0,
  minHeight: 'calc(100vh - 56px)',
  width: 'calc(100% - 245px)',
  background: theme.palette.ui.neutral.grey,
}));
