import type { statusAPI } from '@/redux/stores/state';

export type VerifiedUploadFile = {
  file_name: string;
  object_key: string;
  park_uuid: string;
  presignedlink: PresignedLink;
  report_uuid: string;
  run_uuid: string;
};

export interface PresignedLink {
  url: string;
  fields?: {
    [key: string]: string;
  };
}

export const uploadFileInitialState: VerifiedUploadFile = {
  file_name: '',
  object_key: '',
  park_uuid: '',
  presignedlink: {
    url: '',
    fields: {},
  },
  report_uuid: '',
  run_uuid: '',
};

export interface CarbonReport {
  reportName: string;
  reportUuid: string;
  parkUuid: string;
  parkName: string;
  creationDate: string;
  updatedDate: string;
  reportStatus: string;
}

export const carbonReportListInitialState: CarbonReport[] = [
  {
    reportName: 'Loading...',
    reportUuid: 'Loading...',
    parkUuid: 'Loading...',
    parkName: 'Loading...',
    creationDate: 'Loading...',
    updatedDate: 'Loading...',
    reportStatus: 'Loading...',
  },
];

export interface CarbonParkListing {
  parkUuid: string;
  parkName: string;
}

export interface CarbonPark {
  parkLineUuid: string;
  parkUuid: string;
  parkName: string;
  latitude: number;
  longitude: number;
  ratioInstallTransfer: number;
  ratioUsageTransfer: number;
  installSizeByte: number;
  nbInstallPerDevice: number;
  countDevices: number;
  countRunPerDevices: number;
  cacheEfficiency: number;
  screenTimePerRun: number;
  gridFootprint: {
    gridUuid: string;
    providerName: string;
    region: string;
    country: string;
    city: string;
    impactGJ: number;
  };
  energyTransfer: {
    energyTransferUuid: string;
    landlineConsumption: number;
    wirelessConsumption: number;
    dataCenterConsumption: number;
  };
}

export const carbonParkListingInitialState: CarbonParkListing = {
  parkUuid: '',
  parkName: 'No registered parks available',
};

export const carbonParkInitialState: CarbonParkListing[] = [
  carbonParkListingInitialState,
];

export type CarbonReportDetail = {
  report: CarbonReport;
  size_by_mimetype: {
    title: string;
    data: { [key: string]: number };
  };
  size_by_page_title_and_mimetype: {
    title: string;
    data: { [key: string]: { [key: string]: number } };
  };
  energy_usage: {
    title: string;
    data: { [key: string]: number };
  };
  carbon_usage: {
    title: string;
    data: { [key: string]: number };
  };
};

export type BarType = {
  name: string;
  type: string;
  stack: string;
  label: {
    show: boolean;
  };
  emphasis: {
    focus: string;
  };
  data: number[];
};

export const carbonReportDetailInitialState: CarbonReportDetail = {
  report: {
    reportName: '',
    reportUuid: '',
    parkUuid: '',
    parkName: '',
    creationDate: '',
    updatedDate: '',
    reportStatus: '',
  },
  size_by_mimetype: { title: '', data: {} },
  size_by_page_title_and_mimetype: { title: '', data: {} },
  energy_usage: { title: '', data: {} },
  carbon_usage: { title: '', data: {} },
};

export const carbonParkDetailInitialState: CarbonPark = {
  parkLineUuid: '',
  parkUuid: '',
  parkName: '',
  latitude: 0,
  longitude: 0,
  ratioInstallTransfer: 0,
  ratioUsageTransfer: 0,
  installSizeByte: 0,
  nbInstallPerDevice: 0,
  countDevices: 0,
  countRunPerDevices: 0,
  cacheEfficiency: 0,
  screenTimePerRun: 0,
  gridFootprint: {
    gridUuid: '',
    providerName: '',
    region: '',
    country: '',
    city: '',
    impactGJ: 0,
  },
  energyTransfer: {
    energyTransferUuid: '',
    landlineConsumption: 0,
    wirelessConsumption: 0,
    dataCenterConsumption: 0,
  },
};

export interface IDefaultCarbonState {
  carbonReportList: CarbonReport[];
  carbonReportDetail: CarbonReportDetail;
  carbonParkList: CarbonParkListing[];
  carbonParkDetail: CarbonPark[];
  isLoadingMap: boolean;
  uploadInfoStatus: boolean | null;
  isLoading: boolean;
  isError: boolean;
  statusAPI: statusAPI | null;
}

export const defaultCarbonState: IDefaultCarbonState = {
  carbonReportList: carbonReportListInitialState,
  carbonReportDetail: carbonReportDetailInitialState,
  carbonParkList: carbonParkInitialState,
  carbonParkDetail: [carbonParkDetailInitialState],
  isLoadingMap: false,
  uploadInfoStatus: null,
  isLoading: false,
  isError: false,
  statusAPI: null,
};
