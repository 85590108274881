export const staticPagesMap: Record<string, string> = {
  'carbon-how-to': 'public/carbon-how-to/carbon-how-to/',
  cix: 'public/cix/cix/',
  tfnd: 'public/tfnd/tfnd/',
  'physical-carbon-calculator':
    'public/physical-carbon-calculator/physical-carbon-calculator/',
  'planetary-limits': 'public/planetary-limits/planetary-limits/',
  sustainability: 'public/sustainability/sustainability/',
  'regulation-analysis': 'public/regulation-analyzer/regulation-analyzer/',
  'sdg-explained': 'public/sdg-explained/sdg-explained/',
  'time-line': 'public/timeline/timeline/',
  marketplace: 'public/marketplace/marketplace/',
  'life-cycle-analysis': 'public/life-cycle-analysis/',
};
