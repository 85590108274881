import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import type { QuizQuestion } from '@/redux/reducers/quiz/state';

interface QuestionHintDialogProps {
  openDialog: boolean;
  handleClose: any;
  quizQuestion: QuizQuestion;
}

export const QuestionHintDialog: React.FC<QuestionHintDialogProps> = ({
  openDialog,
  handleClose,
  quizQuestion,
}) => {
  const { t } = useTranslation();
  return (
    <Dialog open={openDialog} onClose={handleClose}>
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {`${t('organisms.question')} #${quizQuestion.questionOrder} ${t(
          'organisms.hint',
        )}`}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <div
            className="headline3-desktop"
            style={{ padding: '10px', color: 'var(--text_primary)' }}
          >
            {quizQuestion.hint
              ? quizQuestion.hint
              : t('organisms.noHintAvailable')}
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <div
          className="button2-desktop"
          style={{ padding: '10px' }}
          onClick={handleClose}
        >
          {t('organisms.back')}
        </div>
      </DialogActions>
    </Dialog>
  );
};
