import { useState } from 'react';
import { InfoOutlined, OpenInFull } from '@mui/icons-material';
import { Box, IconButton, Popover, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { FullScreenChartDialog } from '@/components/Organisms';

import { theme } from '@/theme/theme';

import type { MouseEvent } from 'react';

export const ChartCard = ({
  children,
  description,
  title,
}: {
  children: any;
  description: any;
  title: string;
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [showFullScreenChart, setShowFullScreenChart] =
    useState<boolean>(false);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleOnSeeDetail = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOnExpand = () => {
    setShowFullScreenChart(!showFullScreenChart);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <CardContainer>
      <HeaderContainer>
        <TitleContainer>
          <Typography variant="h3">{title}</Typography>
        </TitleContainer>
        <IconsContainer>
          <CustomPopover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
          >
            {description}
          </CustomPopover>
          <ChartIconButton onClick={handleOnSeeDetail}>
            <InfoOutlined
              style={{ color: theme.palette.ui.primaryGreen.main }}
            />
          </ChartIconButton>
          <FullScreenChartDialog
            title={title}
            isOpen={showFullScreenChart}
            handleOnSeeDetail={handleOnSeeDetail}
            handleClose={handleOnExpand}
            children={children}
          />
          <ChartIconButton onClick={handleOnExpand}>
            <OpenInFull style={{ color: theme.palette.ui.primaryGreen.main }} />
          </ChartIconButton>
        </IconsContainer>
      </HeaderContainer>
      {children}
    </CardContainer>
  );
};

const CardContainer = styled(Box)(() => ({
  height: '96%',
  width: '100%',
}));

const HeaderContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
}));

const TitleContainer = styled(Box)(() => ({}));

const CustomPopover = styled(Popover)(() => ({}));

const IconsContainer = styled(Box)(() => ({
  position: 'relative',
  top: '-4px',
}));

const ChartIconButton = styled(IconButton)(() => ({
  padding: '6px',
}));
