export const webServices = {
  // User
  getUserInfo: 'get_user_info',
  getUserOrganizationUuids: 'get_user_organization_uuids',
  updateUserOrganization: 'update_user_organization',

  // Action
  getAllActions: 'al_get_action_list',
  updateActionStatus: 'al_update_action',

  // SDG
  getGR491TopSDG: 'gr491_get_top_sdg',
  getGR491FamilyList: 'gr491_get_families',
  getGR491FamilyDetail: 'gr491_get_questions_list',
  getGR491QuestionDetail: 'gr491_get_question',
  updateGR491QuestionDetail: 'gr491_update_question',
  getGR491ResultOverview: 'gr491_get_result_overview',
  getResultSankey: 'gr491_get_result_sankey',

  // Carbon footprint
  uploadHARFile: 'cf_upload_har',
  getAllCarbonReport: 'cf_get_report_list',
  getCarbonReportDetail: 'cf_get_report_detail',
  getCarbonParkList: 'cf_get_park_list',
  getCarbonParkDetail: 'cf_get_park_detail',
  getCompanyPowerBIReport: 'cf_get_power_bi_report',

  // Learning Playground
  getLPQuizList: 'lp_get_quiz_list',
  getLPQuizQuestion: 'lp_get_quiz_question',
  updateLPQuizQuestion: 'lp_update_quiz_question',
  getLPQuizResultOverview: 'lp_get_quiz_result_overview',
  generateQuizRecommendation: 'lp_create_recommended_quiz',
  getLPQuizCategoryList: 'lp_get_quiz_categories',

  // Chatbot
  getChatbotResponse: 'cb_get_response',
};
