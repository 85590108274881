import React, { useEffect, useState } from 'react';
import { Box, styled, Tab, Tabs, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { LoadingWheel } from '@/components/Atoms';
import { HorizontalBarChart, PieChart, TreeMap } from '@/components/Molecules';
import CarbonParkLineMap from '@/components/Organisms/CarbonParkLineMap/CarbonParkLineMap';

import { carbonReportMenuItems } from '@/const/CarbonReportMenuItems';

import {
  parsePieChartLabelNames,
  transformData,
  transformDataBar,
  transformDataBarXAxis,
} from '@/utils/carbon';

import type {
  BarType,
  CarbonPark,
  CarbonReportDetail,
} from '@/redux/reducers/carbon/state';

interface CarbonChartsContainerProps {
  reportDetail: CarbonReportDetail;
  isLoading: boolean;
  parkLineList: CarbonPark[];
  openParkLineDetail: (parkLine: CarbonPark) => void;
}

export const CarbonChartsContainer = ({
  reportDetail,
  isLoading,
  parkLineList,
  openParkLineDetail,
}: CarbonChartsContainerProps) => {
  const [selectedChart, setSelectedChart] = useState<number>(1);
  const [dataTreemap, setDataTreemap] = useState<
    { name: string; value: number }[]
  >([]);
  const [dataBarChart, setDataBarChart] = useState<BarType[]>([]);
  const [xAxisBarChart, setxAxisBarChart] = useState<string[]>([]);
  const [dataPieChartEnergy, setDataPieChartEnergy] = useState<
    { name: string; value: number }[]
  >([]);
  const [dataPieChartCarbon, setDataPieChartCarbon] = useState<
    { name: string; value: number }[]
  >([]);

  useEffect(() => {
    const data = transformData({
      obj: reportDetail.size_by_mimetype.data,
    });
    setDataTreemap(data);
  }, [reportDetail.size_by_mimetype]);

  useEffect(() => {
    setDataBarChart([]);
    const transformedData = transformDataBar({
      obj: reportDetail.size_by_page_title_and_mimetype.data,
    });
    setDataBarChart(transformedData);
  }, [reportDetail.size_by_page_title_and_mimetype]);

  useEffect(() => {
    setxAxisBarChart([]);
    const transformedAxis: string[] = transformDataBarXAxis({
      obj: reportDetail.size_by_page_title_and_mimetype.data,
    });
    setxAxisBarChart(transformedAxis);
  }, [reportDetail.size_by_page_title_and_mimetype]);

  useEffect(() => {
    setDataPieChartEnergy([]);
    const data = transformData({
      obj: reportDetail.energy_usage.data,
    });
    parsePieChartLabelNames(data);
    setDataPieChartEnergy(data);
  }, [reportDetail.energy_usage]);

  useEffect(() => {
    setDataPieChartCarbon([]);
    const data = transformData({
      obj: reportDetail.carbon_usage.data,
    });
    parsePieChartLabelNames(data);
    setDataPieChartCarbon(data);
  }, [reportDetail.carbon_usage]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedChart(newValue);
  };

  const { t } = useTranslation();

  return (
    <ChartsContainer>
      <ChartsTitle>
        <Typography variant="h3">{t('templates.reportDetails')}</Typography>
      </ChartsTitle>
      <ChartsMenu>
        <ChartTabs
          value={selectedChart}
          onChange={handleChange}
          variant="fullWidth"
        >
          {carbonReportMenuItems.map(
            (menuItem: { id: number; name: string; nameKey: string }) => (
              <ChartsMenuItem
                key={menuItem.id}
                label={
                  <Typography variant="body1">{t(menuItem.nameKey)}</Typography>
                }
                value={menuItem.id}
              />
            ),
          )}
        </ChartTabs>
      </ChartsMenu>
      <ChartsHolder>
        {selectedChart === 1 && isLoading ? (
          <LoadingWheel isChart={true} />
        ) : selectedChart === 1 && !isLoading ? (
          <CarbonParkLineMap
            parkLineList={parkLineList}
            onClick={openParkLineDetail}
          />
        ) : selectedChart === 2 ? (
          <TreeMap chartData={dataTreemap} />
        ) : selectedChart === 3 ? (
          <HorizontalBarChart
            xAxisLabel={xAxisBarChart}
            chartData={dataBarChart}
          />
        ) : selectedChart === 4 ? (
          <PieChart chartData={dataPieChartEnergy} />
        ) : selectedChart === 5 ? (
          <PieChart chartData={dataPieChartCarbon} />
        ) : null}
      </ChartsHolder>
    </ChartsContainer>
  );
};

const ChartsContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns:
    '[first] 1fr [second] 1fr [third] 1fr [third-last] 1fr [second-last] 1fr [last]',
  gridTemplateRows: '[top] 30px [menu] 30px [chart] 500px [bottom]',
  width: '100%',
  height: 'fit-content',
  padding: '24px',
  gap: '32px',
  background: theme.palette.ui.neutral.white,
  boxSizing: 'border-box',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.12)',
  borderRadius: '8px',
}));

const ChartsTitle = styled(Box)(() => ({
  display: 'flex',
  boxSizing: 'border-box',
  gridColumn: 'first / last',
  gridRow: 'top / menu',
  width: '100%',
  height: '100%',
}));

const ChartsMenu = styled(Box)(() => ({
  display: 'static',
  boxSizing: 'border-box',
  gridColumn: 'first / last',
  gridRow: 'menu / chart',
  width: '100%',
  height: '100%',
}));

const ChartsHolder = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  gridColumn: 'first / last',
  gridRow: 'chart / bottom',
  width: '100%',
  height: '100%',
}));

const ChartsMenuItem = styled(Tab)(({ theme }) => ({
  display: 'flex',
  boxSizing: 'border-box',
  gridRow: 'menu / chart',
  justifyContent: 'center',
  width: '100%',
  borderBottom: `2px solid ${theme.palette.ui.stroke}`,
  textTransform: 'capitalize',
  color: theme.palette.ui.neutral.black,
  height: '40px',
  minHeight: '40px',
}));

const ChartTabs = styled(Tabs)(() => ({
  '& .MuiTabs-indicator': {
    bottom: 8,
  },
}));
