import type { SanKeyParameter } from '@/models/chart';
import type { EChartsOption } from 'echarts-for-react';
import type {
  CategoryQuestionDetail,
  Family,
  FamilyDetail,
  TopSDG,
} from './state';

export const GET_GR491_TOP_SDG = 'GET_GR491_TOP_SDG';
export const GET_GR491_TOP_SDG_SUCCESS = 'GET_GR491_TOP_SDG_SUCCESS';
export const GET_GR491_TOP_SDG_FAIL = 'GET_GR491_TOP_SDG_FAIL';
export const GET_GR491_FAMILY_LIST = 'GET_GR491_FAMILY_LIST';
export const GET_GR491_FAMILY_LIST_SUCCESS = 'GET_GR491_FAMILY_LIST_SUCCESS';
export const GET_GR491_FAMILY_LIST_FAIL = 'GET_GR491_FAMILY_LIST_FAIL';
export const GET_GR491_FAMILY_DETAIL = 'GET_GR491_FAMILY_DETAIL';
export const GET_GR491_FAMILY_DETAIL_SUCCESS =
  'GET_GR491_FAMILY_DETAIL_SUCCESS';
export const GET_GR491_FAMILY_DETAIL_FAIL = 'GET_GR491_FAMILY_DETAIL_FAIL';
export const GET_GR491_QUESTION_DETAIL = 'GET_GR491_QUESTION_DETAIL';
export const GET_GR491_QUESTION_DETAIL_SUCCESS =
  'GET_GR491_QUESTION_DETAIL_SUCCESS';
export const GET_GR491_QUESTION_DETAIL_FAIL = 'GET_GR491_QUESTION_DETAIL_FAIL';
export const GET_GR491_RESULT_OVERVIEW = 'GET_GR491_RESULT_OVERVIEW';
export const GET_GR491_RESULT_OVERVIEW_SUCCESS =
  'GET_GR491_RESULT_OVERVIEW_SUCCESS';
export const GET_GR491_RESULT_OVERVIEW_FAIL = 'GET_GR491_RESULT_OVERVIEW_FAIL';
export const UPDATE_GR491_QUESTION_DETAIL = 'UPDATE_GR491_QUESTION_DETAIL';
export const UPDATE_GR491_QUESTION_DETAIL_SUCCESS =
  'UPDATE_GR491_QUESTION_DETAIL_SUCCESS';
export const UPDATE_GR491_QUESTION_DETAIL_FAIL =
  'UPDATE_GR491_QUESTION_DETAIL_FAIL';
export const GET_GR491_RESULT_SANKEY = 'GET_GR491_RESULT_SANKEY';
export const GET_GR491_RESULT_SANKEY_SUCCESS =
  'GET_GR491_RESULT_SANKEY_SUCCESS';
export const GET_GR491_RESULT_SANKEY_FAIL = 'GET_GR491_RESULT_SANKEY_FAIL';
export const RESET_GR491 = 'RESET_GR491';

export interface GetGR491TopSDG {
  type: typeof GET_GR491_TOP_SDG;
  payload: any;
}

export interface GetGR491TopSDGSuccess {
  type: typeof GET_GR491_TOP_SDG_SUCCESS;
  payload: TopSDG;
}

export interface GetGR491TopSDGFail {
  type: typeof GET_GR491_TOP_SDG_FAIL;
  payload: any;
}

export interface GetGR491FamilyList {
  type: typeof GET_GR491_FAMILY_LIST;
  payload: any;
}

export interface GetGR491FamilyListSuccess {
  type: typeof GET_GR491_FAMILY_LIST_SUCCESS;
  payload: Family[];
}

export interface GetGR491FamilyListFail {
  type: typeof GET_GR491_FAMILY_LIST_FAIL;
  payload: any;
}

export interface GetGR491FamilyDetail {
  type: typeof GET_GR491_FAMILY_DETAIL;
  payload: any;
}

export interface GetGR491FamilyDetailSuccess {
  type: typeof GET_GR491_FAMILY_DETAIL_SUCCESS;
  payload: FamilyDetail;
}

export interface GetGR491FamilyDetailFail {
  type: typeof GET_GR491_FAMILY_DETAIL_FAIL;
  payload: any;
}

export interface GetGR491QuestionDetail {
  type: typeof GET_GR491_QUESTION_DETAIL;
  payload: any;
}

export interface GetGR491QuestionDetailSuccess {
  type: typeof GET_GR491_QUESTION_DETAIL_SUCCESS;
  payload: CategoryQuestionDetail;
}

export interface GetGR491QuestionDetailFail {
  type: typeof GET_GR491_QUESTION_DETAIL_FAIL;
  payload: any;
}

export interface GetGR491ResultOverview {
  type: typeof GET_GR491_RESULT_OVERVIEW;
  payload: any;
}

export interface GetGR491ResultOverviewSuccess {
  type: typeof GET_GR491_RESULT_OVERVIEW_SUCCESS;
  payload: EChartsOption[];
}

export interface GetGR491ResultOverviewFail {
  type: typeof GET_GR491_RESULT_OVERVIEW_FAIL;
  payload: any;
}

export interface UpdateGR491QuestionDetail {
  type: typeof UPDATE_GR491_QUESTION_DETAIL;
  payload: any;
}

export interface UpdateGR491QuestionDetailSuccess {
  type: typeof UPDATE_GR491_QUESTION_DETAIL_SUCCESS;
  payload: any;
}

export interface UpdateGR491QuestionDetailFail {
  type: typeof UPDATE_GR491_QUESTION_DETAIL_FAIL;
  payload: any;
}

export interface GetGR491ResultSankey {
  type: typeof GET_GR491_RESULT_SANKEY;
  payload: any;
}

export interface GetGR491ResultSankeySuccess {
  type: typeof GET_GR491_RESULT_SANKEY_SUCCESS;
  payload: SanKeyParameter;
}

export interface GetGR491ResultSankeyFail {
  type: typeof GET_GR491_RESULT_SANKEY_FAIL;
  payload: any;
}

export interface ResetGR491 {
  type: typeof RESET_GR491;
  payload: any;
}

export type GR491DispatchTypes =
  | GetGR491TopSDG
  | GetGR491TopSDGSuccess
  | GetGR491TopSDGFail
  | GetGR491FamilyList
  | GetGR491FamilyListSuccess
  | GetGR491FamilyListFail
  | GetGR491FamilyDetail
  | GetGR491FamilyDetailSuccess
  | GetGR491FamilyDetailFail
  | GetGR491QuestionDetail
  | GetGR491QuestionDetailSuccess
  | GetGR491QuestionDetailFail
  | GetGR491ResultOverview
  | GetGR491ResultOverviewSuccess
  | GetGR491ResultOverviewFail
  | UpdateGR491QuestionDetail
  | UpdateGR491QuestionDetailSuccess
  | UpdateGR491QuestionDetailFail
  | GetGR491ResultSankey
  | GetGR491ResultSankeySuccess
  | GetGR491ResultSankeyFail
  | ResetGR491;
