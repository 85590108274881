import { Box, styled } from '@mui/material';

export const Container = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: 24,
  gap: 24,
  width: '100%',
}));

export const Content = styled(Box)(() => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  width: '100%',
  height: 'fit-content',
  gap: 24,
}));
