import { LinearProgress, styled } from '@mui/material';

const StyledProgressBar = styled(LinearProgress)(({ theme }) => ({
  height: '4px',
  background: theme.palette.ui.stroke,
  bordeRadius: '200px',
  '& .MuiLinearProgress-bar': {
    background: theme.palette.ui.palo.green,
    borderRadius: '100px',
  },
}));

interface ProgressBarProps {
  width: string;
  value: number;
}

export const ProgressBar = ({ width, value }: ProgressBarProps) => {
  return (
    <StyledProgressBar
      variant="determinate"
      sx={{ width: width }}
      value={value}
    />
  );
};
