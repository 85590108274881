import type { statusAPI } from '@/redux/stores/state';
import type {
  CarbonReport,
  CarbonReportDetail,
  VerifiedUploadFile,
} from './state';

export const GET_ALL_CARBON_REPORT = 'GET_ALL_CARBON_REPORT';
export const GET_ALL_CARBON_REPORT_RELOAD = 'GET_ALL_CARBON_REPORT_RELOAD';
export const GET_ALL_CARBON_REPORT_SUCCESS = 'GET_ALL_CARBON_REPORT_SUCCESS';
export const GET_ALL_CARBON_REPORT_FAIL = 'GET_ALL_CARBON_REPORT_FAIL';

export const GET_CARBON_REPORT_DETAIL = 'GET_CARBON_REPORT_DETAIL';
export const GET_CARBON_REPORT_DETAIL_SUCCESS =
  'GET_CARBON_REPORT_DETAIL_SUCCESS';
export const GET_CARBON_REPORT_DETAIL_FAIL = 'GET_CARBON_REPORT_DETAIL_FAIL';

export const GET_CARBON_PARK_LIST = 'GET_CARBON_PARK_LIST';
export const GET_CARBON_PARK_LIST_SUCCESS = 'GET_CARBON_PARK_LIST_SUCCESS';
export const GET_CARBON_PARK_LIST_FAIL = 'GET_CARBON_PARK_LIST_FAIL';

export const GET_CARBON_PARK_DETAIL = 'GET_CARBON_PARK_DETAIL';
export const GET_CARBON_PARK_DETAIL_SUCCESS = 'GET_CARBON_PARK_DETAIL_SUCCESS';
export const GET_CARBON_PARK_DETAIL_FAIL = 'GET_CARBON_PARK_DETAIL_FAIL';

export const VERIFY_UPLOAD_FILE = 'VERIFY_UPLOAD_FILE';
export const VERIFY_UPLOAD_FILE_SUCCESS = 'VERIFY_UPLOAD_FILE_SUCCESS';
export const VERIFY_UPLOAD_FILE_FAIL = 'VERIFY_UPLOAD_FILE_FAIL';

export const UPLOAD_FILE = 'UPLOAD_FILE';
export const UPLOAD_FILE_GET_PRESIGNED_URL_SUCCESS =
  'UPLOAD_FILE_GET_PRESIGNED_URL_SUCCESS';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_FAIL = 'UPLOAD_FILE_FAIL';

export const CLEAR_CARBON_PARK_DETAIL = 'CLEAR_CARBON_PARK_DETAIL';
export const RESET_CARBON = 'RESET_CARBON';

export interface GetAllCarbonReport {
  type: typeof GET_ALL_CARBON_REPORT;
  payload: any;
}

export interface GetAllCarbonReportReload {
  type: typeof GET_ALL_CARBON_REPORT_RELOAD;
  payload: any;
}

export interface GetAllCarbonReportSuccess {
  type: typeof GET_ALL_CARBON_REPORT_SUCCESS;
  payload: CarbonReport[];
}

export interface GetAllCarbonReportFail {
  type: typeof GET_ALL_CARBON_REPORT_FAIL;
  payload: statusAPI;
}

export interface GetCarbonReportDetail {
  type: typeof GET_CARBON_REPORT_DETAIL;
  payload: any;
}

export interface GetCarbonReportDetailSuccess {
  type: typeof GET_CARBON_REPORT_DETAIL_SUCCESS;
  payload: CarbonReportDetail;
}

export interface GetCarbonReportDetailFail {
  type: typeof GET_CARBON_REPORT_DETAIL_FAIL;
  payload: statusAPI;
}

export interface GetCarbonParkList {
  type: typeof GET_CARBON_PARK_LIST;
  payload: any;
}

export interface GetCarbonParkListSuccess {
  type: typeof GET_CARBON_PARK_LIST_SUCCESS;
  payload: any;
}

export interface GetCarbonParkListFail {
  type: typeof GET_CARBON_PARK_LIST_FAIL;
  payload: statusAPI;
}

export interface GetCarbonParkDetail {
  type: typeof GET_CARBON_PARK_DETAIL;
  payload: any;
}

export interface ClearCarbonParkDetail {
  type: typeof CLEAR_CARBON_PARK_DETAIL;
  payload: any;
}

export interface GetCarbonParkDetailSuccess {
  type: typeof GET_CARBON_PARK_DETAIL_SUCCESS;
  payload: any;
}

export interface GetCarbonParkDetailFail {
  type: typeof GET_CARBON_PARK_DETAIL_FAIL;
  payload: statusAPI;
}

export interface VerifyHARFile {
  type: typeof VERIFY_UPLOAD_FILE;
  payload: any;
}

export interface VerifyHARFileSuccess {
  type: typeof VERIFY_UPLOAD_FILE_SUCCESS;
  payload: VerifiedUploadFile;
}

export interface VerifyHARFileFail {
  type: typeof VERIFY_UPLOAD_FILE_FAIL;
  payload: statusAPI;
}

export interface UploadHARFile {
  type: typeof UPLOAD_FILE;
  payload: any;
}

export interface UploadFileGetPresignedUrlSuccess {
  type: typeof UPLOAD_FILE_GET_PRESIGNED_URL_SUCCESS;
  payload: any;
}

export interface UploadHARFileSuccess {
  type: typeof UPLOAD_FILE_SUCCESS;
  payload: any;
}

export interface UploadHARFileFail {
  type: typeof UPLOAD_FILE_FAIL;
  payload: statusAPI;
}

export interface ResetCarbon {
  type: typeof RESET_CARBON;
  payload: any;
}

export type CarbonDispatchTypes =
  | GetAllCarbonReport
  | GetAllCarbonReportReload
  | GetAllCarbonReportSuccess
  | GetAllCarbonReportFail
  | GetCarbonReportDetail
  | GetCarbonReportDetailSuccess
  | GetCarbonReportDetailFail
  | GetCarbonParkList
  | GetCarbonParkListSuccess
  | GetCarbonParkListFail
  | GetCarbonParkDetail
  | GetCarbonParkDetailSuccess
  | GetCarbonParkDetailFail
  | UploadHARFile
  | UploadFileGetPresignedUrlSuccess
  | UploadHARFileSuccess
  | UploadHARFileFail
  | ClearCarbonParkDetail
  | ResetCarbon;
