import {
  ChatbotDispatchTypes,
  GET_CHATBOT_RESPONSE,
  GET_CHATBOT_RESPONSE_FAIL,
  GET_CHATBOT_RESPONSE_SUCCESS,
} from './actionTypes';
import { defaultChatbotState, IDefaultChatbotState } from './state';

const chatbotReducer = (
  state: IDefaultChatbotState = defaultChatbotState,
  action: ChatbotDispatchTypes
): IDefaultChatbotState => {
  switch (action.type) {
    case GET_CHATBOT_RESPONSE:
      return {
        ...state,
        response: '',
        isLoading: true,
        isError: false,
        errorMsg: null,
      };
    case GET_CHATBOT_RESPONSE_SUCCESS:
      return {
        ...state,
        response: action.payload,
        isLoading: false,
        isError: false,
        errorMsg: null,
      };
    case GET_CHATBOT_RESPONSE_FAIL:
      return {
        ...state,
        response: '',
        isLoading: false,
        isError: true,
        errorMsg: action.payload,
      };
    default:
      return state;
  }
};

export default chatbotReducer;
