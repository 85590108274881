import { styled } from '@mui/material';

export const Container = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  padding: 24,
  gap: 24,
  overflowY: 'scroll',
  width: '100%',
});
