import { deliveryPlaybookApi } from '@/redux/api';

import type {
  AggregatedKPIRecord,
  KPIRecord,
  KPIReport,
} from 'SustainablePlatform';

export default deliveryPlaybookApi.injectEndpoints({
  endpoints: build => ({
    getKPIRecordList: build.query<
      AggregatedKPIRecord[],
      { report_uuid: KPIReport['DELIVERY_PLAYBOOK_KPI_REPORT_UUID'] }
    >({
      query: payload => ({
        url: '/dp_get_kpi_record_list',
        data: payload,
      }),
      providesTags: ['KPIRecords'],
    }),
    updateKPIRecord: build.mutation<
      KPIRecord,
      {
        record_uuid: KPIRecord['DELIVERY_PLAYBOOK_KPI_RECORD_UUID'];
        score: KPIRecord['KPI_SCORE'];
        comment: KPIRecord['KPI_COMMENT'];
      }
    >({
      query: payload => ({
        url: '/dp_update_kpi_record',
        data: payload,
      }),
      invalidatesTags: ['KPIRecords'],
    }),
  }),
});
