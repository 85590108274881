import { AssignmentTurnedInOutlined } from '@mui/icons-material';
import { Box, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import type { Quiz } from '@/redux/reducers/quiz/state';

export const MiniQuizCard = ({ quizItem }: { quizItem: Quiz }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <MiniQuizCardContainer
      onClick={() =>
        navigate(
          `/learning-playground/${quizItem.uuid}/${quizItem.firstQuestionUuid}`,
        )
      }
    >
      <MiniQuizItemImageWrapper>
        <img src={quizItem.imageUrl} alt={'mini-quiz-item'} />
      </MiniQuizItemImageWrapper>
      <MiniQuizItemText>
        <Typography variant="subtitle1">{quizItem.name}</Typography>
      </MiniQuizItemText>
      <MiniQuizItemQuestions>
        <Typography variant="caption">
          <AssignmentTurnedInOutlined
            fontSize="small"
            style={{ marginRight: '5px' }}
          />
          {quizItem.questionsTotal + ` ${t('organisms.questions')}`}
        </Typography>
      </MiniQuizItemQuestions>
    </MiniQuizCardContainer>
  );
};

const MiniQuizCardContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateRows: '[top] 2fr [center] 1fr [bottom]',
  gridTemplateColumns: '[first] 120px [middle] auto [last]',
  padding: '12px 16px 12px 12px',
  gap: 16,
  width: '100%',
  height: '100%',
  boxSizing: 'border-box',
  background: theme.palette.ui.neutral.white,
  boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.16)',
  borderRadius: 6,
  maxWidth: 335,
  maxHeight: 124,
  cursor: 'pointer',
}));

const MiniQuizItemText = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gridRow: 'top / center',
  gridColumn: 'middle / last',
  width: '100%',
  height: '100%',
  color: theme.palette.text.primary,
}));

const MiniQuizItemQuestions = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-start',
  gridRow: 'center / bottom',
  gridColumn: 'middle / last',
  width: '100%',
  height: '100%',
  color: theme.palette.text.secondary,
}));

const MiniQuizItemImageWrapper = styled(Box)(() => ({
  display: 'flex',
  gridRow: 'top / bottom',
  gridColumn: 'first / middle',
  width: '100%',
  height: '100%',
}));
