import { autoBatchEnhancer, configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';

import { deliveryPlaybookApi } from '../api';
import rootReducer from '../reducers/rootReducer';

import type { Action, ThunkAction } from '@reduxjs/toolkit';

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(thunk, deliveryPlaybookApi.middleware),
  enhancers: defaultEnhancers => defaultEnhancers.concat(autoBatchEnhancer()),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action
>;
