import { useCallback, useEffect, useState } from 'react';
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  styled,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import type { QuizDomain } from '@/redux/reducers/quiz/state';

interface QuizGenerationFormProps {
  isOpen: boolean;
  handleClose: () => void;
  onSubmit: (categories: string[]) => void;
  quizDomains: QuizDomain[];
}

interface CategorySelection {
  name: string;
  selected: boolean;
}

export const QuizGenerationForm: React.FC<QuizGenerationFormProps> = ({
  isOpen,
  handleClose,
  onSubmit,
  quizDomains,
}) => {
  const categoryLimit = 2;
  const [categories, setCategories] = useState<CategorySelection[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);

  const setFreshCategories = useCallback(() => {
    const selectableCategories = quizDomains.map((cat: QuizDomain) => ({
      ...cat,
      selected: false,
    }));
    setCategories(selectableCategories);
  }, [quizDomains]);

  useEffect(() => {
    const selectedTrue = categories.reduce<string[]>((list, category) => {
      if (category.selected === true) {
        list.push(category.name);
      }
      return list;
    }, []);
    setSelectedCategories(selectedTrue);
  }, [categories, setSelectedCategories]);

  useEffect(() => {
    setFreshCategories();
  }, [setCategories, quizDomains]);

  const handleSelectCategory = (event: any) => {
    const categorySelected = event?.target.value;
    const newCategories = categories.map(category => {
      if (category.name === categorySelected) {
        return {
          ...category,
          selected: event.target.checked,
        };
      }
      return category;
    });
    setCategories(newCategories);
  };

  const onHandleClose = () => {
    setSelectedCategories([]);
    handleClose();
  };

  const onHandleSubmit = () => {
    setSelectedCategories([]);
    setFreshCategories();
    onSubmit(selectedCategories);
  };
  const { t } = useTranslation();

  return (
    <Dialog open={isOpen} onClose={onHandleClose}>
      <QuizGenerationDialogTitle>
        {t('organisms.newRecommendedQuiz')}
      </QuizGenerationDialogTitle>
      <Divider />
      <QuizGenerationCard>
        <FormControl>
          <QuizGenerationDialogContentText>
            {t('organisms.selectQuizCategories')}
          </QuizGenerationDialogContentText>
          <QuizGenerationDialogCheckboxWrapper>
            {categories.map(category => {
              const atLimit = selectedCategories.length >= categoryLimit;
              return (
                <QuizGenerationDialogFormControlLabel
                  key={`option-${category.name}`}
                  control={
                    <Checkbox
                      checked={category.selected}
                      checkedIcon={<CheckBox />}
                      icon={<CheckBoxOutlineBlank />}
                      onChange={handleSelectCategory}
                      value={category.name}
                      disabled={!category.selected && atLimit}
                    />
                  }
                  label={category.name}
                />
              );
            })}
          </QuizGenerationDialogCheckboxWrapper>
        </FormControl>
      </QuizGenerationCard>
      <Divider />
      <QuizGenerationDialogActions>
        <CancelButton variant="text" onClick={handleClose}>
          {t('organisms.cancel')}
        </CancelButton>
        <CreateButton
          variant="contained"
          color="primary"
          onClick={onHandleSubmit}
          disabled={selectedCategories.length < 1}
        >
          {t('organisms.create')}
        </CreateButton>
      </QuizGenerationDialogActions>
    </Dialog>
  );
};

const QuizGenerationDialogTitle = styled(DialogTitle)(({ theme }) => ({
  textAlign: 'left',
  ...theme.typography.h3,
  fontFamily: 'Montserrat-Bold',
  fontWeight: '600',
  background: theme.palette.ui.neutral.white,
}));

const QuizGenerationCard = styled(DialogContent)(({ theme }) => ({
  padding: '16px 24px',
  background: theme.palette.ui.neutral.white,
}));

const QuizGenerationDialogCheckboxWrapper = styled(FormGroup)(() => ({
  margin: 0,
  padding: 0,
}));

const QuizGenerationDialogContentText = styled(DialogContentText)(
  ({ theme }) => ({
    paddingBottom: '5px',
    ...theme.typography.body1,
  }),
);

const QuizGenerationDialogFormControlLabel = styled(FormControlLabel)(
  ({ theme }) => ({
    ...theme.typography.body1,
    margin: 0,
    padding: '5px 0',
    span: {
      padding: '0 14px',
      color: theme.palette.ui.primaryGreen.main,
    },
  }),
);

const QuizGenerationDialogActions = styled(DialogActions)(({ theme }) => ({
  justifyContent: 'space-between',
  background: theme.palette.ui.neutral.white,
}));

const CancelButton = styled(Button)(({ theme }) => ({
  marginLeft: '8px',
  ...theme.typography.button,
  fontFamily: 'Montserrat-SemiBold',
  fontWeight: 600,
}));

const CreateButton = styled(Button)(({ theme }) => ({
  marginRight: '8px',
  ...theme.typography.button,
  fontFamily: 'Montserrat-SemiBold',
  fontWeight: 600,
}));
