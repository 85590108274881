import {
  Box,
  Container,
  FormControl,
  Grid,
  styled,
  Typography,
} from '@mui/material';

export const LearningPlaygroundPageContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: 24,
  gap: 24,
  overflowY: 'scroll',
  width: '100%',
  height: '100%',
}));

export const LearningPlaygroundTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  color: theme.palette.text.primary,
  boxSizing: 'border-box',
}));

export const LearningPlaygroundContent = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 40,
  boxSizing: 'border-box',
}));

export const NewQuizButtonWrapper = styled(Container)`
  &.MuiContainer-root {
    width: 250px;
    display: flex;
    padding: 0;
    margin: 8px;
  }
`;

export const QuizSelectorRow = styled(Container)`
  &.MuiContainer-root {
    display: flex;
    box-sizing: border-box;
    gap: 8px;
    padding: 16px 0px;
    margin: 8px 0;
  }
`;

export const QuizSelectorWrapper = styled(FormControl)(() => ({
  flex: 1,
}));

export const QuizListAllQuizContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: 'fit-content',
  width: '100%',
}));

export const QuizListAllQuizItemsContainer = styled(Box)(() => ({
  width: '100%',
  padding: '0px 8px',
}));

export const QuizListLoaderWrapper = styled(Box)(() => ({
  height: 400,
}));

export const MiniQuizCardGridItem = styled(Grid)(() => ({
  paddingBottom: 16,
  width: 350,
}));
