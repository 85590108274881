import { camelCaseToCapitalize, formatDate } from './general';

import type { CarbonPark, CarbonReport } from '@/redux/reducers/carbon/state';

type PieChartData = {
  name: string;
  value: number;
};

const pieChartLabels: Record<string, string> = {
  usageDevice: 'Usage: Device',
  usageNetwork: 'Usage: Network',
  usageDatacenter: 'Usage: Data Centre',
  installationDevice: 'Installation: Device',
  installationNetwork: 'Installation: Network',
  installationDatacenter: 'Installation: Data Centre',
};

export const transformData = ({
  obj,
}: {
  obj: {
    [key: string]: number;
  };
}) => {
  const transformedData = Object.keys(obj).map((key: string) => {
    return { name: key, value: Number(obj[key].toFixed(1)) };
  });

  return transformedData;
};

export const transformDataBar = ({
  obj,
}: {
  obj: {
    [key: string]: {
      [key: string]: number;
    };
  };
}) => {
  const transformedData = Object.keys(obj).map((pkey: string) => {
    return {
      name: pkey,
      type: 'bar',
      stack: 'total',
      label: {
        show: false,
      },
      emphasis: {
        focus: 'series',
      },
      data: Object.keys(obj[pkey]).map((skey: string) => {
        return Number(obj[pkey][skey].toFixed(1));
      }),
    };
  });

  return transformedData;
};

export const transformDataBarXAxis = ({
  obj,
}: {
  obj: {
    [key: string]: {
      [key: string]: number;
    };
  };
}) => {
  let transformedAxis: string[] = [];
  Object.keys(obj).map((pkey: string) => {
    return Object.keys(obj[pkey]).map((skey: string) => {
      return (
        !(transformedAxis.indexOf(skey) > -1) &&
        (transformedAxis = [...transformedAxis, skey])
      );
    });
  });

  return transformedAxis;
};

export const makeParkDetailContent = (parkDetails: CarbonPark) => {
  const parkDetailContent: { label: string; value: number | string }[] = [
    { label: camelCaseToCapitalize('parkName'), value: parkDetails.parkName },
    {
      label: camelCaseToCapitalize('landlineConsumption'),
      value: parkDetails.energyTransfer.landlineConsumption,
    },
    {
      label: camelCaseToCapitalize('wirelessConsumption'),
      value: parkDetails.energyTransfer.wirelessConsumption,
    },
    {
      label: camelCaseToCapitalize('dataCenterConsumption'),
      value: parkDetails.energyTransfer.dataCenterConsumption,
    },
    {
      label: camelCaseToCapitalize('ratioInstallTransfer'),
      value: parkDetails.ratioInstallTransfer,
    },
    {
      label: camelCaseToCapitalize('ratioUsageTransfer'),
      value: parkDetails.ratioUsageTransfer,
    },
    {
      label: camelCaseToCapitalize('installSizeByte'),
      value: parkDetails.installSizeByte,
    },
    {
      label: camelCaseToCapitalize('nbInstallPerDevice'),
      value: parkDetails.nbInstallPerDevice,
    },
    {
      label: camelCaseToCapitalize('countDevices'),
      value: parkDetails.countDevices,
    },
    {
      label: camelCaseToCapitalize('countRunPerDevices'),
      value: parkDetails.countRunPerDevices,
    },
    {
      label: camelCaseToCapitalize('cacheEfficiency'),
      value: parkDetails.cacheEfficiency,
    },
    {
      label: camelCaseToCapitalize('screenTimePerRun'),
      value: parkDetails.screenTimePerRun,
    },
  ];
  return parkDetailContent;
};

export const columnDataType = (type: string, row: CarbonReport) => {
  switch (type) {
    case 'reportName':
      return row['reportName'];
    case 'parkName':
      return row['parkName'];
    case 'creationDate':
      return row['creationDate'] === 'Loading...'
        ? 'Loading...'
        : formatDate(row['creationDate']);
    case 'reportUuid':
      return row['reportUuid'];
    case 'reportStatus':
      return row['reportStatus'];
    default:
      return null;
  }
};

export const parsePieChartLabelNames = (pieChartDataList: PieChartData[]) => {
  for (const pieChartData of pieChartDataList) {
    if (pieChartLabels[pieChartData.name]) {
      pieChartData.name = pieChartLabels[pieChartData.name];
    }
  }
};
