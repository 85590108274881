import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { CarbonTableContainer } from '@/components/Templates';

import { getAllCarbonReportList } from '@/redux/reducers/carbon/actions';
import { useAppDispatch, useAppSelector } from '@/redux/stores/hooks';

import { Container, Title } from './CarbonPage.styles';

export const CarbonPage = () => {
  const dispatch = useAppDispatch();
  const carbonReportList = useAppSelector(
    store => store.carbon.carbonReportList,
  );
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getAllCarbonReportList({ refresh: false }));
  }, [dispatch]);

  const handleRefreshReports = () => {
    dispatch(getAllCarbonReportList({ refresh: true }));
  };

  return (
    <Container>
      <Title className="headline1-desktop">{t('pages.carbonAssessment')}</Title>
      <CarbonTableContainer
        reports={carbonReportList}
        handleRefresh={handleRefreshReports}
      />
    </Container>
  );
};
