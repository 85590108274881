import axios from 'axios';

import { generateDefaultApiHeaders } from '@/utils/api';
import { camelCaseToCapitalize } from '@/utils/general';

import { webServices } from '@/redux/stores/api';

import {
  GET_GR491_FAMILY_DETAIL,
  GET_GR491_FAMILY_DETAIL_FAIL,
  GET_GR491_FAMILY_DETAIL_SUCCESS,
  GET_GR491_FAMILY_LIST,
  GET_GR491_FAMILY_LIST_FAIL,
  GET_GR491_FAMILY_LIST_SUCCESS,
  GET_GR491_QUESTION_DETAIL,
  GET_GR491_QUESTION_DETAIL_FAIL,
  GET_GR491_QUESTION_DETAIL_SUCCESS,
  GET_GR491_RESULT_OVERVIEW,
  GET_GR491_RESULT_OVERVIEW_FAIL,
  GET_GR491_RESULT_OVERVIEW_SUCCESS,
  GET_GR491_RESULT_SANKEY,
  GET_GR491_RESULT_SANKEY_FAIL,
  GET_GR491_RESULT_SANKEY_SUCCESS,
  GET_GR491_TOP_SDG,
  GET_GR491_TOP_SDG_FAIL,
  GET_GR491_TOP_SDG_SUCCESS,
  UPDATE_GR491_QUESTION_DETAIL,
  UPDATE_GR491_QUESTION_DETAIL_FAIL,
  UPDATE_GR491_QUESTION_DETAIL_SUCCESS,
} from './actionTypes';

import type { SanKeyResult } from '@/models/chart';
import type { RootState } from '@/redux/stores/store';
import type { AnyAction, ThunkAction } from '@reduxjs/toolkit';

// GET
export const getGR491TopSDG = ({
  limit,
}: {
  limit: number;
}): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async dispatch => {
    try {
      dispatch({ type: GET_GR491_TOP_SDG });
      const resp = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT_ROOT}/${webServices.getGR491TopSDG}`,
        JSON.stringify({ limit }),
        generateDefaultApiHeaders()
      );

      if (resp?.data?.statusAPI !== 'SUCCESS') {
        throw new Error(
          `Get GR491 Top SDG Error - errorMessage: ${resp?.data?.errorMessage}`
        );
      }

      dispatch({
        type: GET_GR491_TOP_SDG_SUCCESS,
        payload: resp.data.result,
      });
    } catch (err) {
      dispatch({
        type: GET_GR491_TOP_SDG_FAIL,
        payload: JSON.stringify(err),
      });
    }
  };
};

export const getGR491FamilyList = (): ThunkAction<
  void,
  RootState,
  unknown,
  AnyAction
> => {
  return async dispatch => {
    try {
      dispatch({ type: GET_GR491_FAMILY_LIST });
      const resp = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT_ROOT}/${webServices.getGR491FamilyList}`,
        JSON.stringify({}),
        generateDefaultApiHeaders()
      );

      if (resp?.data?.statusAPI !== 'SUCCESS') {
        throw new Error(
          `Get GR491 Family List Error - errorMessage: ${resp?.data?.errorMessage}`
        );
      }

      dispatch({
        type: GET_GR491_FAMILY_LIST_SUCCESS,
        payload: resp.data.result,
      });
    } catch (err) {
      dispatch({
        type: GET_GR491_FAMILY_LIST_FAIL,
        payload: JSON.stringify(err),
      });
    }
  };
};

export const getGR491FamilyDetail = ({
  familyID,
}: {
  familyID: string;
}): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async dispatch => {
    try {
      dispatch({ type: GET_GR491_FAMILY_DETAIL });
      const resp = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT_ROOT}/${webServices.getGR491FamilyDetail}`,
        JSON.stringify({ family_uuid: familyID }),
        generateDefaultApiHeaders()
      );

      if (resp?.data?.statusAPI !== 'SUCCESS') {
        throw new Error(
          `Get GR491 Family Detail Error - errorMessage: ${resp?.data?.errorMessage}`
        );
      }

      dispatch({
        type: GET_GR491_FAMILY_DETAIL_SUCCESS,
        payload: resp.data.result,
      });
    } catch (err) {
      dispatch({
        type: GET_GR491_FAMILY_DETAIL_FAIL,
        payload: JSON.stringify(err),
      });
    }
  };
};

export const getGR491QuestionDetail = ({
  questionID,
}: {
  questionID: string;
}): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async dispatch => {
    try {
      dispatch({ type: GET_GR491_QUESTION_DETAIL });
      const resp = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT_ROOT}/${webServices.getGR491QuestionDetail}`,
        JSON.stringify({ question_uuid: questionID }),
        generateDefaultApiHeaders()
      );

      if (resp?.data?.statusAPI !== 'SUCCESS') {
        throw new Error(
          `Get GR491 Question Detail Error - errorMessage: ${resp?.data?.errorMessage}`
        );
      }

      dispatch({
        type: GET_GR491_QUESTION_DETAIL_SUCCESS,
        payload: resp.data.result,
      });
    } catch (err) {
      dispatch({
        type: GET_GR491_QUESTION_DETAIL_FAIL,
        payload: JSON.stringify(err),
      });
    }
  };
};

export const getGR491ResultOverview = (): ThunkAction<
  void,
  RootState,
  unknown,
  AnyAction
> => {
  return async dispatch => {
    try {
      dispatch({ type: GET_GR491_RESULT_OVERVIEW });

      const resp = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT_ROOT}/${webServices.getGR491ResultOverview}`,
        JSON.stringify({}),
        generateDefaultApiHeaders()
      );

      if (resp?.data?.statusAPI !== 'SUCCESS') {
        throw new Error(
          `Get GR491 Result Overview Fail - errorMessage: ${resp?.data?.errorMessage}`
        );
      }

      dispatch({
        type: GET_GR491_RESULT_OVERVIEW_SUCCESS,
        payload: resp.data.result,
      });
    } catch (err) {
      dispatch({
        type: GET_GR491_RESULT_OVERVIEW_FAIL,
        payload: JSON.stringify(err),
      });
    }
  };
};

// UPDATE
export const updateGR491QuestionDetails = ({
  questionID,
  answerID,
}: {
  questionID: string;
  answerID: string;
}): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async dispatch => {
    try {
      dispatch({ type: UPDATE_GR491_QUESTION_DETAIL });

      const resp = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT_ROOT}/${webServices.updateGR491QuestionDetail}`,
        JSON.stringify({ question_uuid: questionID, answer_uuid: answerID }),
        generateDefaultApiHeaders()
      );

      if (resp?.data?.statusAPI !== 'SUCCESS') {
        throw new Error(
          `Update GR491 Question Detail Fail - errorMessage: ${resp?.data?.errorMessage}`
        );
      }

      dispatch({
        type: UPDATE_GR491_QUESTION_DETAIL_SUCCESS,
        payload: resp.data.result,
      });
      dispatch(getGR491QuestionDetail({ questionID: questionID }));
    } catch (err) {
      dispatch({
        type: UPDATE_GR491_QUESTION_DETAIL_FAIL,
        payload: JSON.stringify(err),
      });
    }
  };
};

// UPDATE
export const getGR491ResultSankey = (): ThunkAction<
  void,
  RootState,
  unknown,
  AnyAction
> => {
  return async dispatch => {
    try {
      dispatch({ type: GET_GR491_RESULT_SANKEY });

      const resp = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT_ROOT}/${webServices.getResultSankey}`,
        {},
        generateDefaultApiHeaders()
      );

      if (resp?.data?.statusAPI !== 'SUCCESS') {
        throw new Error(
          `Get GR491 Result Sankey Fail - errorMessage: ${resp?.data?.errorMessage}`
        );
      }
      const results = resp.data.result as SanKeyResult[];
      const sankeyParameters = {
        title: 'SDG Contribution Mapping',
        subtext: '',
        legend: ['Question Family', 'SDG'],
        categories: Array.from(
          new Set(results.map(result => camelCaseToCapitalize(result.domains)))
        ),
        sdgGoals: Array.from(new Set(results.map(result => result.sdg))),
        links: results.map(result => ({
          source: camelCaseToCapitalize(result.domains),
          target: result.sdg,
          value: result.score,
        })),
        results,
      };

      dispatch({
        type: GET_GR491_RESULT_SANKEY_SUCCESS,
        payload: sankeyParameters,
      });
    } catch (err) {
      dispatch({
        type: GET_GR491_RESULT_SANKEY_FAIL,
        payload: JSON.stringify(err),
      });
    }
  };
};
