import { useEffect } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import {
  LoadingWheel,
  RadarChartDescription,
  SanKeyChartDescription,
  CustomButton as TaskListButton,
} from '@/components/Atoms';
import { NavBreadcrumbs, RadarChart } from '@/components/Molecules';
import { ChartCard, SanKeyChartContainer } from '@/components/Organisms';
import { TopSDGContainer } from '@/components/Templates';

import {
  getGR491FamilyList,
  getGR491ResultOverview,
  getGR491ResultSankey,
  getGR491TopSDG,
} from '@/redux/reducers/gr491/actions';
import { useAppDispatch, useAppSelector } from '@/redux/stores/hooks';

import {
  ImpactRadarCardContainer,
  ResultDetailsPageContainer,
  ResultDetailsPageImpactChartsContainer,
  ResultDetailsPageImpactSummaryContainer,
  ResultDetailsPageIntroContainer,
  ResultDetailsPageIntroHeader,
  ResultDetailsPageIntroTaskList,
  ResultDetailsPageSummaryRow,
  ResultDetailsPageWrapper,
  StyledTitle,
} from './ResultDetailsPage.styles';

import type { BreadcrumbItem } from '@/components/Molecules/NavBreadcrumbs/NavBreadcrumbs';
import type { EChartsOption } from 'echarts-for-react';

export const ResultDetailsPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { topSDG, sankeyData, resultOverview, isLoading } = useAppSelector(
    store => store.gr491,
  );
  const { t } = useTranslation();
  const sdgAmount = 3;
  const BreadcrumbItemList: BreadcrumbItem[] = [
    {
      key: '1',
      title: t('pages.sdgAssessment'),
      color: 'rgb(106, 113, 111)',
      link: true,
      onClickAction: () => navigate('/sustainable-it-audit'),
    },
    {
      key: '2',
      title: t('pages.sdgAssessmentReport'),
      color: 'rgb(32, 40, 38)',
      link: false,
      onClickAction: () => null,
    },
  ];

  useEffect(() => {
    dispatch(getGR491TopSDG({ limit: sdgAmount }));
    dispatch(getGR491ResultSankey());
    dispatch(getGR491ResultOverview());
    dispatch(getGR491FamilyList());
  }, [dispatch]);

  return (
    <ResultDetailsPageWrapper>
      <ResultDetailsPageContainer>
        <ResultDetailsPageIntroContainer>
          <NavBreadcrumbs breadcrumbItems={BreadcrumbItemList} />
          <Typography variant="h1" style={{ fontWeight: 700 }}>
            {t('pages.sdgAssessmentReport')}
          </Typography>
          <ResultDetailsPageIntroHeader>
            <Typography variant="subtitle1" style={{ fontWeight: 600 }}>
              {t('pages.seeHowToImproveGr491')}
            </Typography>
            <ResultDetailsPageIntroTaskList>
              <Typography variant="subtitle1">
                {t('pages.newTasksHaveBeenAdded')}
              </Typography>
              <TaskListButton
                width={'120px'}
                level={'Primary'}
                iconType={'Right'}
                text={t('pages.taskList')}
                onClickAction={() => navigate('/action-list')}
              />
            </ResultDetailsPageIntroTaskList>
          </ResultDetailsPageIntroHeader>
        </ResultDetailsPageIntroContainer>
        {isLoading ? (
          <LoadingWheel isChart={true} />
        ) : (
          <ResultDetailsPageSummaryRow>
            <ResultDetailsPageImpactSummaryContainer>
              <StyledTitle>
                <Typography variant="h3">{t('pages.sdgImpact')}</Typography>
                <Typography variant="body1">
                  {t('pages.top3SdgYouContributeTo')}
                </Typography>
              </StyledTitle>
              <TopSDGContainer
                amount={sdgAmount}
                topSDGitems={topSDG?.topSdg}
              />
            </ResultDetailsPageImpactSummaryContainer>
            {sankeyData.links.length > 0 && (
              <ResultDetailsPageImpactSummaryContainer>
                <ChartCard
                  title={sankeyData.title}
                  description={<SanKeyChartDescription />}
                >
                  <SanKeyChartContainer
                    parameters={sankeyData}
                    showTitle={false}
                  />
                </ChartCard>
              </ResultDetailsPageImpactSummaryContainer>
            )}
          </ResultDetailsPageSummaryRow>
        )}
        {isLoading ? (
          <LoadingWheel isChart={true} />
        ) : (
          <ResultDetailsPageImpactChartsContainer>
            {resultOverview.map((item: EChartsOption, idx: number) => (
              <ImpactRadarCardContainer key={idx}>
                <ChartCard
                  title={item.title}
                  description={
                    <RadarChartDescription description={item.subtext} />
                  }
                >
                  <RadarChart parameters={item} showTitle={false} />
                </ChartCard>
              </ImpactRadarCardContainer>
            ))}
          </ResultDetailsPageImpactChartsContainer>
        )}
      </ResultDetailsPageContainer>
    </ResultDetailsPageWrapper>
  );
};
