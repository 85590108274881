import { AuthState } from 'SustainablePlatform';

import {
  AUTH_REQUEST,
  AUTH_REQUEST_FAIL,
  AUTH_REQUEST_SUCCESS,
  AuthDispatchTypes,
  GET_USER_AVAILABLE_ORGANIZATION_UUIDS,
  GET_USER_AVAILABLE_ORGANIZATION_UUIDS_FAIL,
  GET_USER_AVAILABLE_ORGANIZATION_UUIDS_SUCCESS,
  GET_USER_INFO,
  GET_USER_INFO_FAIL,
  GET_USER_INFO_SUCCESS,
  UPDATE_USER_ORGANIZATION,
  UPDATE_USER_ORGANIZATION_FAIL,
  UPDATE_USER_ORGANIZATION_SUCCESS,
} from './actionTypes';
import { defaultAuthState } from './state';

const authReducer = (
  state: AuthState = defaultAuthState,
  action: AuthDispatchTypes,
): AuthState => {
  switch (action.type) {
    case AUTH_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case AUTH_REQUEST_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        isLoading: false,
        isError: false,
        errorMsg: '',
      };
    case GET_USER_INFO:
      return {
        ...state,
        isLoading: true,
        isError: false,
        errorMsg: action.payload,
      };
    case GET_USER_INFO_SUCCESS:
      return {
        ...state,
        userInfo: action.payload,
        isLoggedIn: true,
        isLoading: false,
        isError: false,
        errorMsg: '',
      };
    case GET_USER_AVAILABLE_ORGANIZATION_UUIDS:
      return {
        ...state,
        isLoading: true,
        isError: false,
        availableOrganizations: [],
      };
    case GET_USER_AVAILABLE_ORGANIZATION_UUIDS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        availableOrganizations: action.payload,
      };
    case GET_USER_AVAILABLE_ORGANIZATION_UUIDS_FAIL:
      return {
        ...state,
        isLoading: false,
        isError: true,
        errorMsg: action.payload,
      };
    case AUTH_REQUEST_FAIL:
    case GET_USER_INFO_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        isLoading: false,
        isError: true,
        errorMsg: action.payload,
      };
    case UPDATE_USER_ORGANIZATION:
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case UPDATE_USER_ORGANIZATION_SUCCESS:
      return {
        ...state,
        userInfo: {
          ...state.userInfo,
          organizationUuid: action.payload.organizationUuid,
          organization: action.payload.organization,
        },
        isLoading: false,
        isError: false,
      };
    case UPDATE_USER_ORGANIZATION_FAIL:
      return {
        ...state,
        isLoading: false,
        isError: true,
        errorMsg: action.payload,
      };
    default:
      return state;
  }
};

export default authReducer;
