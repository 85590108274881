import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { CircularProgress, IconButton, styled } from '@mui/material';

const StyledPrimaryButton = styled(IconButton)(({ theme }) => ({
  background: theme.palette.ui.primaryGreen.main,
  color: theme.palette.ui.neutral.white,
  borderRadius: '4px',
  ...theme.typography.button,
  '&:disabled': {
    background: theme.palette.ui.neutral.greyLight,
    color: theme.palette.ui.neutral.greyDark,
  },
  '&:hover': {
    background: 'rgb(9, 145, 110)',
  },
}));

const StyledSecondaryButton = styled(IconButton)(({ theme }) => ({
  background: theme.palette.ui.neutral.white,
  color: theme.palette.ui.primaryGreen.main,
  border: '2px solid',
  borderColor: theme.palette.ui.primaryGreen.main,
  borderRadius: '4px',
  ...theme.typography.button,
  '&:disabled': {
    color: theme.palette.ui.neutral.greyDark,
    border: '2px solid',
    borderColor: theme.palette.ui.neutral.greyLight,
  },
  '&:hover': {
    background: theme.palette.ui.primaryGreen.white,
    color: theme.palette.ui.primaryGreen.main,
    border: '2px solid',
    borderColor: theme.palette.ui.primaryGreen.main,
  },
}));

const StyledDashboardButton = styled(IconButton)(({ theme }) => ({
  background: 'none',
  color: theme.palette.ui.primaryGreen.main,
  borderRadius: '4px',
  ...theme.typography.button,
  '&:disabled': {
    color: theme.palette.ui.neutral.greyDark,
  },
  '&:hover': {
    background: theme.palette.ui.primaryGreen.white,
    color: theme.palette.ui.primaryGreen.main,
  },
}));

interface CustomButtonProps {
  width: string;
  level: 'Primary' | 'Secondary' | 'Tertiary';
  iconType?: 'None' | 'Right' | 'Left';
  text?: string;
  disabled?: boolean;
  onClickAction?: any;
  isLoading?: boolean;
}

export const CustomButton = ({
  width,
  level,
  iconType,
  text,
  disabled,
  onClickAction,
  isLoading,
}: CustomButtonProps) => {
  return level === 'Secondary' ? (
    <StyledSecondaryButton
      disabled={disabled}
      sx={{ width: width, padding: '12px' }}
      onClick={onClickAction}
    >
      {iconType === 'Left' ? <ArrowBack fontSize="small" /> : null}
      {text}
      {iconType === 'Right' ? <ArrowForward fontSize="small" /> : null}
    </StyledSecondaryButton>
  ) : level === 'Tertiary' ? (
    <StyledDashboardButton
      disabled={disabled}
      sx={{ width: width, padding: '12px' }}
      onClick={onClickAction}
    >
      {iconType === 'Left' ? <ArrowBack fontSize="small" /> : null}
      {text}
      {iconType === 'Right' ? <ArrowForward fontSize="small" /> : null}
    </StyledDashboardButton>
  ) : (
    <StyledPrimaryButton
      disabled={disabled}
      sx={{ width: width, padding: '12px' }}
      onClick={onClickAction}
    >
      {!isLoading ? (
        <>
          {iconType === 'Left' ? <ArrowBack fontSize="small" /> : null}
          {text}
          {iconType === 'Right' ? <ArrowForward fontSize="small" /> : null}
        </>
      ) : (
        <CircularProgress sx={{ color: 'ui.neutral.white' }} size={20} />
      )}
    </StyledPrimaryButton>
  );
};
