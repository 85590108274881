import { deliveryPlaybookApi } from '@/redux/api';

import type { KPIReport } from 'SustainablePlatform';

export default deliveryPlaybookApi.injectEndpoints({
  endpoints: build => ({
    getKPIReportList: build.query<KPIReport[], void>({
      query: payload => ({
        url: '/dp_get_kpi_report_list',
        data: payload,
      }),
      providesTags: ['KPIReports'],
    }),
    duplicateKPIReport: build.mutation<
      KPIReport,
      {
        report_name: KPIReport['REPORT_NAME'];
        copy_from_report_uuid: KPIReport['DELIVERY_PLAYBOOK_KPI_REPORT_UUID'];
      }
    >({
      query: payload => ({
        url: '/dp_create_kpi_report',
        data: payload,
      }),
      invalidatesTags: ['KPIReports'],
    }),
  }),
});
