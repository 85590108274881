import axios from 'axios';

import { generateDefaultApiHeaders } from '@/utils/api';

import { webServices } from '@/redux/stores/api';

import {
  GET_CHATBOT_RESPONSE,
  GET_CHATBOT_RESPONSE_FAIL,
  GET_CHATBOT_RESPONSE_SUCCESS,
} from './actionTypes';

import type { RootState } from '@/redux/stores/store';
import type { AnyAction, ThunkAction } from '@reduxjs/toolkit';

// GET
export const getChatbotResponse = ({
  query,
}: {
  query: string;
}): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async dispatch => {
    try {
      dispatch({ type: GET_CHATBOT_RESPONSE });

      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT_ROOT}/${webServices.getChatbotResponse}`,
        JSON.stringify({ query }),
        generateDefaultApiHeaders()
      );

      dispatch({
        type: GET_CHATBOT_RESPONSE_SUCCESS,
        payload: response.data.result,
      });
    } catch (err) {
      dispatch({
        type: GET_CHATBOT_RESPONSE_FAIL,
        payload: JSON.stringify(err),
      });
    }
  };
};
