import { NavigateNextOutlined } from '@mui/icons-material';
import { Breadcrumbs, Link, styled, Typography } from '@mui/material';

export interface BreadcrumbItem {
  key: string;
  title: string;
  color: string;
  link: boolean;
  onClickAction: any;
}

export const NavBreadcrumbs = ({
  breadcrumbItems,
}: {
  breadcrumbItems: BreadcrumbItem[];
}) => {
  return (
    <Breadcrumbs
      separator={<NavigateNextOutlined fontSize="small" />}
      aria-label="breadcrumb"
      sx={{
        li: {
          background: 'none',
          margin: 0,
          padding: 0,
        },
        ol: {
          background: 'none',
          margin: 0,
          padding: 0,
          height: 'fit-content',
        },
        margin: 0,
        padding: 0,
      }}
    >
      {breadcrumbItems.map((item: BreadcrumbItem, idx: number) =>
        item.link ? (
          <StyledLink key={idx} onClick={item.onClickAction} color={item.color}>
            {item.title}
          </StyledLink>
        ) : (
          <StyledTypography
            key={idx}
            onClick={item.onClickAction}
            color={item.color}
          >
            {item.title}
          </StyledTypography>
        ),
      )}
    </Breadcrumbs>
  );
};

const StyledLink = styled(Link)`
  font-family: 'Open-Sans-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
  text-decoration: none;
`;

const StyledTypography = styled(Typography)`
  font-family: 'Open-Sans-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
  text-decoration: none;
`;
