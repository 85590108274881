import { Autocomplete, Box, Chip, styled, Typography } from '@mui/material';

export const FilterAutocomplete = styled(Autocomplete)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
  borderRadius: '4px',
  width: '320px',
  backgroundColor: theme.palette.background.default,
  '.MuiAutocomplete-inputRoot': {
    flexWrap: 'nowrap !important',
  },
  '.Mui-focused': {
    flexWrap: 'wrap !important',
    backgroundColor: theme.palette.background.default,
  },
}));

export const FamilyDetailsPageContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  boxSizing: 'border-box',
  padding: '20px',
  gap: '24px',
  width: '100%',
}));

export const FamilyDetailsPageHeaderContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: '16px',
}));

export const FamilyDetailsPageHeaderIntro = styled(Box)(({ theme }) => ({
  background: theme.palette.ui.neutral.white,
  borderRadius: '8px',
}));

export const FamilyDetailsPageHeaderIntroLine = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  padding: '0px 24px',
  height: '92px',
}));

export const FamilyDetailsPageHeaderIntroLineTitle = styled(Typography)(() => ({
  marginRight: '40px',
  fontWeight: '700',
  color: 'ui.neutral.black',
}));

export const FamilyDetailsPageHeaderIntroLineProgressBar = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: '8px',
  width: '100%',
  height: '100%',
}));

export const FamilyDetailsPageHeaderIntroLineProgressBarText = styled(
  Typography,
)(() => ({
  display: 'flex',
  width: '100%',
  fontWeight: '400',
  color: 'ui.neutral.black',
}));

export const FamilyDetailsPageHeaderIntroDesc = styled(Typography)(() => ({
  paddingLeft: '24px',
  paddingBottom: '20px',
}));

export const FamilyDetailsPageFilterContainer = styled(Box)(() => ({
  display: 'flex',
  width: '100%',
  height: '56px',
  gap: '24px',
}));

export const FamilyDetailsPageContentContainer = styled(Box)(() => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
  width: '100%',
  height: '100%',
  overflowY: 'scroll',
}));

export const MyChip = styled(Chip)(({ theme }) => ({
  background: theme.palette.ui.primaryGreen.white,
  width: '100px',
  border: '1px solid',
  borderColor: theme.palette.ui.primaryGreen.main,
  borderRadius: '100px',
  boxSizing: 'border-box',
  color: theme.palette.ui.primaryGreen.dark,
}));
