import { Box, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const SanKeyChartDescription = () => {
  const { t } = useTranslation();
  return (
    <DescriptionContainer>
      <DescriptionHeader>
        <Typography variant="subtitle1">
          {t('atoms.sdgContributionMapping')}
        </Typography>
      </DescriptionHeader>
      <DescriptionContent>
        <Typography variant="body2">
          {t('atoms.sdgContributionDescription1')}
        </Typography>
        <br></br>
        <Typography variant="body2">
          {t('atoms.sdgContributionDescription2')}
        </Typography>
        <br></br>
        <Typography variant="body2">
          {t('atoms.sdgContributionDescription3')}
        </Typography>
      </DescriptionContent>
    </DescriptionContainer>
  );
};

const DescriptionContainer = styled(Box)(({ theme }) => ({
  background: theme.palette.ui.neutral.white,
  maxWidth: '400px',
}));

const DescriptionHeader = styled(Box)(({ theme }) => ({
  padding: '8px 16px',
  borderBottom: `1px solid ${theme.palette.ui.stroke}`,
}));

const DescriptionContent = styled(Box)(() => ({
  padding: '8px 16px',
}));
