import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  QuizQuestionContainer,
  QuizQuestionFeedbackContainer,
} from '@/components/Templates';

import { AnswerOption } from '@/redux/reducers/gr491/state';
import { getLPQuizQuestion } from '@/redux/reducers/quiz/actions';
import { useAppDispatch, useAppSelector } from '@/redux/stores/hooks';

import { Container } from './QuizQuestionPage.styles';

export const QuizQuestionPage = () => {
  type pageParams = {
    quizID: string;
    questionID: string;
  };
  const { quizID, questionID } = useParams<keyof pageParams>() as pageParams;

  const dispatch = useAppDispatch();
  const quizQuestion = useAppSelector(store => store.quiz.quizQuestion);
  const [showFeedback, setShowFeedback] = useState<boolean>(false);
  const [correctAnswer, setCorrectAnswer] = useState<boolean>(false);
  const [feedbackItem, setFeedbackItem] = useState<AnswerOption | null>(null);
  const [isCompleted, setIsCompleted] = useState<boolean>(false);

  useEffect(() => {
    if (quizID && questionID) {
      dispatch(
        getLPQuizQuestion({
          quizID: quizID,
          questionID: questionID,
        }),
      );
    }
  }, [dispatch, quizID, questionID]);

  return (
    <Container>
      <QuizQuestionContainer
        quizID={quizID}
        quizQuestionDetails={quizQuestion}
        setShowFeedback={setShowFeedback}
        setCorrectAnswer={setCorrectAnswer}
        setFeedbackItem={setFeedbackItem}
        isCompleted={isCompleted}
        setIsCompleted={setIsCompleted}
      />
      {showFeedback && (
        <QuizQuestionFeedbackContainer
          correct={correctAnswer}
          feedbackItem={feedbackItem}
          isCompleted={isCompleted}
        />
      )}
    </Container>
  );
};
