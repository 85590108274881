import {
  GENERATE_QUIZ_RECOMMENDATION,
  GENERATE_QUIZ_RECOMMENDATION_FAIL,
  GENERATE_QUIZ_RECOMMENDATION_SUCCESS,
  GET_LP_QUIZ_CATRGORY,
  GET_LP_QUIZ_CATRGORY_FAIL,
  GET_LP_QUIZ_CATRGORY_SUCCESS,
  GET_LP_QUIZ_LIST,
  GET_LP_QUIZ_LIST_FAIL,
  GET_LP_QUIZ_LIST_SUCCESS,
  GET_LP_QUIZ_QUESTION,
  GET_LP_QUIZ_QUESTION_FAIL,
  GET_LP_QUIZ_QUESTION_SUCCESS,
  GET_LP_QUIZ_RESULT_OVERVIEW,
  GET_LP_QUIZ_RESULT_OVERVIEW_FAIL,
  GET_LP_QUIZ_RESULT_OVERVIEW_SUCCESS,
  QuizDispatchTypes,
  RESET_QUIZ,
  UPDATE_LP_QUIZ_QUESTION,
  UPDATE_LP_QUIZ_QUESTION_FAIL,
  UPDATE_LP_QUIZ_QUESTION_SUCCESS,
} from './actionTypes';
import {
  Quiz,
  QuizCategory,
  quizCategoryListInitialState,
  quizListInitialState,
  QuizQuestion,
  quizQuestionInitialState,
  QuizQuestionUpdateStatus,
  quizQuestionUpdateStatusInitialState,
  QuizResult,
  quizResultOverviewInitialState,
  QuizResultTotal,
  quizResultTotalInitialState,
} from './state';

export interface IDefaultQuizState {
  quizList: Quiz[];
  quizListTop5: Quiz[];
  quizQuestion: QuizQuestion;
  quizQuestionUpdateStatus: QuizQuestionUpdateStatus;
  quizResultOverview: QuizResult[];
  quizResultTotal: QuizResultTotal;
  quizCategory: QuizCategory;
  isUpdating: boolean;
  isLoading: boolean;
  isError: boolean;
  errorMsg: string | null;
}

export const defaultQuizState: IDefaultQuizState = {
  quizList: quizListInitialState,
  quizListTop5: quizListInitialState,
  quizQuestion: quizQuestionInitialState,
  quizQuestionUpdateStatus: quizQuestionUpdateStatusInitialState,
  quizResultOverview: quizResultOverviewInitialState,
  quizResultTotal: quizResultTotalInitialState,
  quizCategory: quizCategoryListInitialState,
  isUpdating: false,
  isLoading: false,
  isError: false,
  errorMsg: null,
};

const quizReducer = (
  state: IDefaultQuizState = defaultQuizState,
  action: QuizDispatchTypes,
): IDefaultQuizState => {
  switch (action.type) {
    // GET_LP_QUIZ_LIST
    case GET_LP_QUIZ_LIST:
      return {
        ...state,
        quizList: quizListInitialState,
        isLoading: true,
        isError: false,
        errorMsg: null,
      };
    case GET_LP_QUIZ_LIST_SUCCESS:
      return {
        ...state,
        quizList: action.payload,
        isLoading: false,
        isError: false,
        errorMsg: null,
      };
    case GET_LP_QUIZ_LIST_FAIL:
      return {
        ...state,
        quizList: quizListInitialState,
        isLoading: false,
        isError: true,
        errorMsg: action.payload,
      };
    // GET_LP_QUIZ_QUESTION
    case GET_LP_QUIZ_QUESTION:
      return {
        ...state,
        quizQuestionUpdateStatus: quizQuestionUpdateStatusInitialState,
        quizQuestion: quizQuestionInitialState,
        quizResultOverview: quizResultOverviewInitialState,
        isLoading: true,
        isError: false,
        errorMsg: null,
      };
    case GET_LP_QUIZ_QUESTION_SUCCESS:
      return {
        ...state,
        quizQuestion: action.payload,
        isLoading: false,
        isError: false,
        errorMsg: null,
      };
    case GET_LP_QUIZ_QUESTION_FAIL:
      return {
        ...state,
        quizQuestion: quizQuestionInitialState,
        isLoading: false,
        isError: true,
        errorMsg: action.payload,
      };
    // UPDATE_LP_QUIZ_QUESTION
    case UPDATE_LP_QUIZ_QUESTION:
      return {
        ...state,
        quizQuestionUpdateStatus: quizQuestionUpdateStatusInitialState,
        isUpdating: true,
        isError: false,
        errorMsg: null,
      };
    case UPDATE_LP_QUIZ_QUESTION_SUCCESS:
      return {
        ...state,
        quizQuestionUpdateStatus: action.payload,
        isUpdating: false,
        isError: false,
        errorMsg: null,
      };
    case UPDATE_LP_QUIZ_QUESTION_FAIL:
      return {
        ...state,
        quizQuestionUpdateStatus: quizQuestionUpdateStatusInitialState,
        isUpdating: false,
        isError: true,
        errorMsg: action.payload,
      };
    // GET_LP_QUIZ_RESULT_OVERVIEW
    case GET_LP_QUIZ_RESULT_OVERVIEW:
      return {
        ...state,
        quizResultOverview: quizResultOverviewInitialState,
        isLoading: true,
        isError: false,
        errorMsg: null,
      };
    case GET_LP_QUIZ_RESULT_OVERVIEW_SUCCESS:
      return {
        ...state,
        quizResultOverview: action.payload,
        isLoading: false,
        isError: false,
        errorMsg: null,
      };
    case GET_LP_QUIZ_RESULT_OVERVIEW_FAIL:
      return {
        ...state,
        quizResultOverview: quizResultOverviewInitialState,
        isLoading: false,
        isError: true,
        errorMsg: action.payload,
      };
    // GENERATE_QUIZ_RECOMMENDATION
    case GENERATE_QUIZ_RECOMMENDATION:
      return {
        ...state,
        isLoading: true,
        isError: false,
        errorMsg: null,
      };
    case GENERATE_QUIZ_RECOMMENDATION_SUCCESS:
      return {
        ...state,
        isError: false,
        errorMsg: null,
      };
    case GENERATE_QUIZ_RECOMMENDATION_FAIL:
      return {
        ...state,
        isLoading: false,
        isError: true,
        errorMsg: action.payload,
      };
    // GET_LP_QUIZ_CATRGORY
    case GET_LP_QUIZ_CATRGORY:
      return {
        ...state,
        quizCategory: quizCategoryListInitialState,
        isLoading: true,
        isError: false,
        errorMsg: null,
      };
    case GET_LP_QUIZ_CATRGORY_SUCCESS:
      return {
        ...state,
        quizCategory: action.payload,
        isError: false,
        errorMsg: null,
      };
    case GET_LP_QUIZ_CATRGORY_FAIL:
      return {
        ...state,
        quizCategory: quizCategoryListInitialState,
        isLoading: false,
        isError: true,
        errorMsg: action.payload,
      };
    case RESET_QUIZ:
      return {
        ...defaultQuizState,
      };

    default:
      return state;
  }
};

export default quizReducer;
