import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { LoadingWheel } from '@/components/Atoms';
import { FamilyCard, PageHeader } from '@/components/Molecules';

import { getGR491FamilyList } from '@/redux/reducers/gr491/actions';
import { Family } from '@/redux/reducers/gr491/state';
import { useAppDispatch, useAppSelector } from '@/redux/stores/hooks';

import { Container, Content } from './SustainableITPage.styles';

export const SustainableITPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isLoading, familyList } = useAppSelector(store => store.gr491);
  const [completed, setCompleted] = useState<number>(0);
  const [total, setTotal] = useState<number>(1);
  const [progress, setProgress] = useState<number>(0);

  useEffect(() => {
    dispatch(getGR491FamilyList());
  }, [dispatch]);

  useEffect(() => {
    setCompleted(
      familyList.reduce(
        (initialValue: number, item: Family) =>
          (initialValue += item.questionsCompleted),
        0,
      ),
    );
    setTotal(
      familyList.reduce(
        (initialValue: number, item: Family) =>
          (initialValue += item.questionsTotal),
        0,
      ),
    );
  }, [familyList]);

  useEffect(() => {
    if (total === 0) {
      setProgress(0);
    } else if (completed > total) {
      setProgress(0);
    } else if (total >= completed) {
      setProgress((completed * 100) / total);
    }
  }, [completed, total]);

  return (
    <Container>
      <PageHeader
        completedQuestions={completed}
        totalQuestions={total}
        progressValue={progress}
      />
      {isLoading ? (
        <LoadingWheel />
      ) : (
        <Content>
          {familyList.map((item: Family, idx: number) => (
            <FamilyCard
              key={idx}
              item={item}
              onClickAction={() =>
                item.questionsTotal !== 0 &&
                navigate(`/sustainable-it-audit/${item.uuid}`)
              }
            />
          ))}
        </Content>
      )}
    </Container>
  );
};
