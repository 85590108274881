import { useCallback, useState } from 'react';
import { Add } from '@mui/icons-material';
import {
  Autocomplete,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { KPITableContainer } from '@/components/Templates';

import KPIReportApi from '@/redux/api/deliveryPlaybook/KPIReport';

import { Container } from './KPIUpdatePage.styles';

import type { ChangeEvent, FormEvent, SyntheticEvent } from 'react';
import type { KPIReport } from 'SustainablePlatform';

interface ReportSelectOption {
  id: KPIReport['DELIVERY_PLAYBOOK_KPI_REPORT_UUID'];
  label: KPIReport['REPORT_NAME'];
}

export const KPIUpdatePage = () => {
  const {
    data,
    isLoading: isLoadingReports,
    isFetching: isFetchingReports,
  } = KPIReportApi.useGetKPIReportListQuery();
  const [duplicateKPIReport, { isLoading: isDuplicatingReport }] =
    KPIReportApi.useDuplicateKPIReportMutation();

  const [selectedOption, setSelectedOption] =
    useState<ReportSelectOption | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [reportName, setReportName] = useState('');
  const { t } = useTranslation();

  const handleSelection = useCallback(
    (event: SyntheticEvent, newValue: ReportSelectOption | null) => {
      setSelectedOption(newValue);
    },
    [],
  );

  const handleReportNameChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setReportName(event.target.value);
    },
    [],
  );

  const closeDialog = useCallback(() => {
    setIsDialogOpen(false);
  }, []);

  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (!selectedOption) throw new Error('No report selected!');

      try {
        await duplicateKPIReport({
          report_name: reportName,
          copy_from_report_uuid: selectedOption.id,
        });

        closeDialog();
      } catch (err) {
        console.log('Something went wrong!');
      }
    },
    [reportName],
  );

  const reportSelectOptions = data
    ? data?.map(report => ({
        id: report.DELIVERY_PLAYBOOK_KPI_REPORT_UUID,
        label: report.REPORT_NAME,
      }))
    : [];

  return (
    <Container>
      <Typography variant="h1">
        {t('pages.deliveryPlaybookKpiUpdate')}
      </Typography>
      <Paper
        sx={{
          padding: '16px 24px',
          display: 'flex',
          gap: '16px',
          alignItems: 'center',
        }}
      >
        <Autocomplete
          id="report-name-select"
          disablePortal
          sx={{ minWidth: 200 }}
          loading={isLoadingReports || isFetchingReports}
          onChange={handleSelection}
          options={reportSelectOptions}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          value={selectedOption}
          renderInput={params => (
            <TextField {...params} label={t('pages.selectReport')} />
          )}
        />
        <Typography variant="body1">{t('pages.or')}</Typography>
        <Button
          disabled={!selectedOption || isDuplicatingReport}
          onClick={() => setIsDialogOpen(true)}
          variant="text"
          endIcon={<Add />}
        >
          {t('pages.duplicateReport')}
        </Button>
      </Paper>
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '16px 24px',
        }}
      >
        <KPITableContainer reportUuid={selectedOption?.id} />
      </Paper>
      <Dialog open={isDialogOpen} onClose={closeDialog}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>{t('pages.duplicateReport')}</DialogTitle>
          <DialogContent
            sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}
          >
            <DialogContentText>{t('pages.pleaseSelectName')}</DialogContentText>
            <TextField
              id="reportName"
              autoFocus
              label={t('pages.newName')}
              type="text"
              variant="standard"
              onChange={handleReportNameChange}
            />
          </DialogContent>
          <DialogActions>
            <Button
              color="inherit"
              onClick={closeDialog}
              disabled={isDuplicatingReport}
            >
              {t('pages.cancel')}
            </Button>
            <Button
              type="submit"
              disabled={isDuplicatingReport}
              endIcon={
                isDuplicatingReport ? <CircularProgress size={20} /> : null
              }
            >
              {t('pages.create')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Container>
  );
};
