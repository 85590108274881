export function getIdToken() {
  return sessionStorage.getItem('idToken');
}

export function setIdToken(token: string) {
  sessionStorage.setItem('idToken', token);
}

export function getRefreshToken() {
  return sessionStorage.getItem('refreshToken');
}

export function setRefreshToken(token: string) {
  sessionStorage.setItem('refreshToken', token);
}

export function removeTokens() {
  sessionStorage.removeItem('idToken');
  sessionStorage.removeItem('refreshToken');
  sessionStorage.removeItem('idTokenExpiry');
}

export function getIdTokenExpiry() {
  return sessionStorage.getItem('idTokenExpiry');
}

export function setIdTokenExpiry(expiry: number) {
  sessionStorage.setItem('idTokenExpiry', expiry.toString());
}

export function tokenIsExpired() {
  const expiryString = getIdTokenExpiry() || '0';
  const expiry = +expiryString;
  return new Date().getTime() >= expiry * 1000;
}

export function tokenIsValid() {
  const token = getIdToken();
  const isExpired = tokenIsExpired();
  const isValid = token && !isExpired;
  return isValid;
}
