import { styled } from '@mui/material';

export const Container = styled('div')({
  display: 'flex',
  padding: 24,
  width: '100%',
});

export const Card = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateRows: '[top] 100px [center] auto [bottom]',
  gridTemplateColumns: '[first] 1fr [middle] 1fr [last]',
  width: '100%',
  height: 'fit-content',
  background: theme.palette.common.white,
  paddingBottom: 24,
  border: `1px solid ${theme.palette.ui.stroke}`,
  borderRadius: 6,
}));

export const Header = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gridRow: 'top / center',
  gridColumn: 'first / last',
});

export const ChartContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

export const ScoreCard = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gridRow: 'center / bottom',
  gridColumn: 'middle / last',
});

export const CategoryContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  width: 300,
  paddingTop: 12,
});

export const Tag = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: 100,
  padding: '2px 8px',
  borderRadius: 3,
});
