import { useEffect, useState } from 'react';
import {
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import {
  LoadingWheel,
  CustomButton as NewQuizButton,
} from '@/components/Atoms';
import { MiniQuizCard, QuizGenerationForm } from '@/components/Organisms';

import { theme } from '@/theme/theme';

import {
  generateQuizRecommendation,
  getLPQuizCategoryList,
  getLPQuizList,
} from '@/redux/reducers/quiz/actions';
import {
  Quiz,
  QuizCategoryListItem,
  quizListInitialState,
} from '@/redux/reducers/quiz/state';
import { useAppDispatch, useAppSelector } from '@/redux/stores/hooks';

import {
  LearningPlaygroundContent,
  LearningPlaygroundPageContainer,
  LearningPlaygroundTitle,
  MiniQuizCardGridItem,
  NewQuizButtonWrapper,
  QuizListAllQuizContainer,
  QuizListAllQuizItemsContainer,
  QuizListLoaderWrapper,
  QuizSelectorRow,
  QuizSelectorWrapper,
} from './LearningPlaygroundPage.styles';

export const LearningPlaygroundPage = () => {
  const dispatch = useAppDispatch();
  const { quizList, quizCategory, isLoading } = useAppSelector(
    store => store.quiz,
  );
  const [selectedCategory, setSelectedCategory] =
    useState<string>('All Quizzes');
  const [allQuizList, setAllQuizList] = useState<Quiz[]>(quizListInitialState);
  const [showForm, setShowForm] = useState<boolean>(false);
  const selectQuizCategoryList: QuizCategoryListItem[] = [
    { name: 'All Quizzes' },
    ...quizCategory.categories,
  ];
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getLPQuizList());
    dispatch(getLPQuizCategoryList());
  }, [dispatch]);

  useEffect(() => {
    if (
      quizList !== quizListInitialState &&
      selectedCategory !== 'All Quizzes'
    ) {
      setAllQuizList(
        quizList.filter((item: Quiz) => item.tags.includes(selectedCategory)),
      );
    } else if (
      quizList !== quizListInitialState &&
      selectedCategory === 'All Quizzes'
    ) {
      setAllQuizList(quizList);
    }
  }, [quizList, selectedCategory]);

  const handleSelectCategory = (event: SelectChangeEvent<unknown>) => {
    setSelectedCategory(event.target.value as string);
  };

  const handleShowForm = () => {
    setShowForm(!showForm);
  };

  const handleSubmit = (categories: string[]) => {
    dispatch(generateQuizRecommendation({ categories }));
    handleShowForm();
  };

  return (
    <LearningPlaygroundPageContainer>
      <LearningPlaygroundTitle variant="h1">
        {t('pages.awareness')}
      </LearningPlaygroundTitle>
      <LearningPlaygroundContent>
        <QuizListAllQuizContainer>
          <QuizSelectorRow>
            <QuizSelectorWrapper fullWidth>
              <InputLabel id="quiz-category-label">
                {t('pages.quiz')}
              </InputLabel>
              <Select
                labelId="quiz-category-label"
                label={t('pages.quiz')}
                id="quiz-category"
                value={selectedCategory}
                onChange={handleSelectCategory}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      background: theme.palette.ui.neutral.white,
                    },
                  },
                }}
              >
                {selectQuizCategoryList.map(
                  (categoryItem: QuizCategoryListItem, idx: number) => (
                    <MenuItem key={idx} value={categoryItem.name}>
                      {categoryItem.name}
                    </MenuItem>
                  ),
                )}
              </Select>
            </QuizSelectorWrapper>
            <NewQuizButtonWrapper>
              <QuizGenerationForm
                isOpen={showForm}
                handleClose={handleShowForm}
                onSubmit={handleSubmit}
                quizDomains={quizCategory.domains}
              />
              <NewQuizButton
                level="Primary"
                width="100%"
                text={t('pages.newCustomizedQuiz')}
                iconType="Right"
                onClickAction={handleShowForm}
              />
            </NewQuizButtonWrapper>
          </QuizSelectorRow>
          <QuizListAllQuizItemsContainer>
            {isLoading ? (
              <QuizListLoaderWrapper>
                <LoadingWheel />
              </QuizListLoaderWrapper>
            ) : (
              <Grid container spacing={2}>
                {allQuizList.map((quizItem: Quiz, idx: number) => (
                  <MiniQuizCardGridItem item key={idx}>
                    <MiniQuizCard quizItem={quizItem} />
                  </MiniQuizCardGridItem>
                ))}
              </Grid>
            )}
          </QuizListAllQuizItemsContainer>
        </QuizListAllQuizContainer>
      </LearningPlaygroundContent>
    </LearningPlaygroundPageContainer>
  );
};
