import { Box, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import type { CategoryQuestionDetail } from '@/redux/reducers/gr491/state';

interface QuestionDetailsContainerProps {
  questionItem: CategoryQuestionDetail;
}

export const QuestionDetailsContainer = ({
  questionItem,
}: QuestionDetailsContainerProps) => {
  const { t } = useTranslation();
  const { details } = questionItem;

  return details ? (
    <CardContainer>
      <CardTitle>
        <Typography variant="h3">{t('templates.moreInformation')}</Typography>
      </CardTitle>
      <CardSection>
        <CardSectionTitle>
          <Typography variant="subtitle1">{t('templates.tests')}</Typography>
        </CardSectionTitle>
        <CardSectionContent>
          <Typography variant="body1">{details.tests}</Typography>
        </CardSectionContent>
      </CardSection>
      <CardSection>
        <CardSectionTitle>
          <Typography variant="subtitle1">
            {t('templates.precisions')}
          </Typography>
        </CardSectionTitle>
        <CardSectionContent>
          <Typography variant="body1">
            {details.precisions}
          </Typography>
        </CardSectionContent>
      </CardSection>
      <CardStats>
        <CardStatContainer>
          <Typography variant="body2">{t('templates.difficulty')}</Typography>
          <Typography variant="subtitle2">
            {details.difficulty !== ''
              ? details.difficulty
              : '*'}
          </Typography>
        </CardStatContainer>
        <CardStatContainer>
          <Typography variant="body2">{t('templates.priority')}</Typography>
          <Typography variant="subtitle2">
            {details.priority !== ''
              ? details.priority
              : '*'}
          </Typography>
        </CardStatContainer>
        <CardStatContainer>
          <Typography variant="body2">{t('templates.recurrence')}</Typography>
          <Typography variant="subtitle2">
            {details.recurrence !== ''
              ? details.recurrence
              : '*'}
          </Typography>
        </CardStatContainer>
      </CardStats>
      <CardRatings>
        <CardRatingsContainer>
          <Typography variant="h3">
            {details.peopleRating}
          </Typography>
          <Typography variant="body2">{t('templates.people')}</Typography>
        </CardRatingsContainer>
        <CardRatingsContainer>
          <Typography variant="h3">
            {details.planetRating}
          </Typography>
          <Typography variant="body2">{t('templates.planet')}</Typography>
        </CardRatingsContainer>
        <CardRatingsContainer>
          <Typography variant="h3">
            {details.prosperityRating}
          </Typography>
          <Typography variant="body2">{t('templates.prosperity')}</Typography>
        </CardRatingsContainer>
      </CardRatings>
    </CardContainer>
  ): null;
};

const CardContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateRows: '1fr',
  gridTemplateColumns: '1fr',
  boxSizing: 'border-box',
  padding: '24px',
  gap: '16px',
  width: '100%',
  height: 'fit-content',
  background: theme.palette.ui.neutral.grey,
  borderRadius: '8px',
}));

const CardTitle = styled(Box)(() => ({
  display: 'flex',
  boxSizing: 'border-box',
  width: '100%',
}));

const CardSection = styled(Box)(() => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
  width: '100%',
  boxSizing: 'border-box',
}));

const CardSectionTitle = styled(Box)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

const CardSectionContent = styled(Box)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

const CardStats = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  width: '100%',
  boxSizing: 'border-box',
  color: theme.palette.text.primary,
  height: 'fit-content',
  gap: '16px',
  padding: '16px',
  borderTop: `1px solid ${theme.palette.ui.stroke}`,
  borderBottom: `1px solid ${theme.palette.ui.stroke}`,
}));

const CardStatContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  boxSizing: 'border-box',
}));

const CardRatings = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  boxSizing: 'border-box',
  color: theme.palette.text.primary,
  height: 'fit-content',
  gap: '16px',
}));

const CardRatingsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '8px',
  boxSizing: 'border-box',
  background: theme.palette.ui.neutral.white,
  border: theme.palette.ui.stroke,
  borderRadius: '4px',
}));
