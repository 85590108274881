import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Icon,
  styled,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { SideMenuItemProps } from '@/const';
import { useState } from 'react';

interface SideMenuTabsProps {
  data: SideMenuItemProps[];
}

export const SideMenuTabs = ({ data }: SideMenuTabsProps) => {
  const navigate = useNavigate();
  const path = window.location.pathname.split('/')[1];
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const { t } = useTranslation();

  return (
    <SideMenuTabsContainer
      TabIndicatorProps={{
        style: { display: 'none' },
      }}
      orientation="vertical"
      value={selectedTabIndex}
    >
      {data.map((item: SideMenuItemProps, idx: number) => {
        if (item.url === path && selectedTabIndex !== idx) {
          setSelectedTabIndex(idx);
        }
        if (hasChildren(item)) {
          return (
            <SideMenuAccordion key={idx} disableGutters>
              <SideMenuAccordionSummary
                expandIcon={<ExpandMore style={{ cursor: 'pointer' }} />}
              >
                <AccordionMenuTab
                  key={idx}
                  disableRipple
                  icon={
                    <Icon
                      component={item.icon}
                      sx={{
                        color:
                          item.url === path
                            ? 'ui.primaryGreen.main'
                            : 'ui.neutral.greyLight',
                        paddingRight: '8px',
                      }}
                    />
                  }
                  label={
                    <Typography
                      variant="subtitle1"
                      sx={{
                        textTransform: 'none',
                        fontWeight: 'bold',
                        paddingLeft: '6px',
                      }}
                    >
                      {t(item.translationKey)}
                    </Typography>
                  }
                  sx={{
                    ...(item.url === path && {
                      backgroundColor: 'ui.primaryGreen.white',
                      color: 'ui.primaryGreen.main',
                      borderRadius: '4px',
                    }),
                  }}
                />
              </SideMenuAccordionSummary>
              <SideMenuAccordionDetails>
                {item.children ? (
                  item.children.map(
                    (child: SideMenuItemProps, childIdx: number) => {
                      return (
                        <AccordionMenuTab
                          key={childIdx}
                          label={
                            <Typography
                              variant="subtitle2"
                              sx={{
                                textTransform: 'none',
                                fontWeight: 'bold',
                                paddingLeft: '20px',
                                textAlign: 'left',
                              }}
                            >
                              {t(child.translationKey)}
                            </Typography>
                          }
                          onClick={() => navigate('/' + child.url)}
                          sx={{
                            ...(child.url === path && {
                              backgroundColor: 'ui.primaryGreen.white',
                              color: 'ui.primaryGreen.main',
                              borderRadius: '4px',
                            }),
                          }}
                        />
                      );
                    },
                  )
                ) : (
                  <Typography
                    variant="subtitle2"
                    sx={{
                      textTransform: 'none',
                      fontWeight: 'bold',
                    }}
                  >
                    {t('molecules.noSubMenuItems')}
                  </Typography>
                )}
              </SideMenuAccordionDetails>
            </SideMenuAccordion>
          );
        } else {
          return (
            <SideMenuTab
              key={idx}
              iconPosition="start"
              icon={
                <Icon
                  component={item.icon}
                  sx={{
                    color:
                      item.url === path
                        ? 'ui.primaryGreen.main'
                        : 'ui.neutral.greyLight',
                  }}
                />
              }
              label={
                <Typography
                  variant="subtitle1"
                  sx={{ textTransform: 'none', fontWeight: 'bold' }}
                >
                  {t(item.translationKey)}
                </Typography>
              }
              onClick={() => navigate('/' + item.url)}
              sx={{
                ...(item.url === path && {
                  backgroundColor: 'ui.primaryGreen.white',
                  color: 'ui.primaryGreen.main',
                  borderRadius: '4px',
                }),
              }}
            />
          );
        }
      })}
    </SideMenuTabsContainer>
  );
};

const SideMenuTab = styled(Tab)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  gap: 5,
}));

const SideMenuAccordion = styled(Accordion, {
  shouldForwardProp: (props: string) =>
    props !== 'textColor' &&
    props !== 'selectionFollowsFocus' &&
    props !== 'fullWidth' && props !== 'indicator',
})(() => ({
  display: 'block',
  alignItems: 'center',
  justifyContent: 'flex-start',
  minHeight: 60,
  '&.MuiAccordion-root:before': { display: 'none' },
  '&.MuiPaper-elevation': {
    border: 'none !important',
    boxShadow: 'none !important',
  },
}));

const SideMenuAccordionSummary = styled(AccordionSummary)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  padding: 0,
  paddingRight: 12,
  '& .MuiAccordionSummary-content': {
    margin: '0',
  },
}));

const SideMenuAccordionDetails = styled(AccordionDetails)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
  justifyContent: 'flex-start',
}));

const AccordionMenuTab = styled(Tab)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
}));

const SideMenuTabsContainer = styled(Tabs)(() => ({
  minHeight: 60,
}));

function hasChildren(items: SideMenuItemProps) {
  const { children: children } = items;

  if (children === undefined || children.length === 0) {
    return false;
  }

  return true;
}
