import { useState } from 'react';
import {
  Box,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { CarbonTableRow } from '@/components/Molecules';

import type { CarbonReport } from '@/redux/reducers/carbon/state';
import type { ChangeEvent } from 'react';

interface CarbonTableProps {
  columns: readonly ColumnType[];
  rows: CarbonReport[];
}

export interface ColumnType {
  id: string;
  label: string;
  labelKey: string;
  minWidth?: number;
  align?: 'center' | 'left' | 'right' | 'inherit' | 'justify' | undefined;
}

export const CarbonTable = ({ columns, rows }: CarbonTableProps) => {
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const { t } = useTranslation();

  const handleChangePage = (e: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(Number(event.target.value));
    setPage(0);
  };

  return (
    <StyledPaper>
      <StyledTableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {columns.map((column: ColumnType, idx: number) => (
                <StyledTableCell
                  key={idx}
                  align={column.align}
                  style={{
                    minWidth: column.minWidth,
                  }}
                >
                  <Box className={'subtitle2-desktop'}>
                    {t(column.labelKey)}
                  </Box>
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row: CarbonReport, idxRow: number) => {
                return (
                  <CarbonTableRow
                    key={idxRow}
                    rowData={row}
                    columns={columns}
                    idxRow={idxRow}
                  />
                );
              })}
          </TableBody>
        </Table>
      </StyledTableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </StyledPaper>
  );
};

const StyledTableContainer = styled(TableContainer)`
  min-height: 600px;
  max-height: 666px;
`;
const StyledTableCell = styled(TableCell)`
  color: var(--neutral_black);
  font-weight: 600;
  padding: 16px 24px;
`;
const StyledPaper = styled(Paper)`
  width: 100%;
  border: none;
  box-shadow: none;
`;
