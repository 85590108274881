import { useCallback } from 'react';

import { updateUserOrganization } from '@/redux/reducers/auth/actions';
import { useAppDispatch, useAppSelector } from '@/redux/stores/hooks';
import { RootState } from '@/redux/stores/store';

export const AvailableOrganizationSelectorContainer = () => {
  const dispatch = useAppDispatch();
  const { userInfo: user, availableOrganizations } = useAppSelector(
    (state: RootState) => state.auth,
  );
  const handleChange = useCallback(
    async (e: React.ChangeEvent<HTMLSelectElement>) => {
      dispatch(
        updateUserOrganization({
          organizationName: e.target.getAttribute('data-name') || '',
          organizationUuid: e.target.value,
        }),
      );
      window.location.href = '/';
    },
    [],
  );

  return availableOrganizations ? (
    <select value={user.organizationUuid} onChange={handleChange}>
      {availableOrganizations.map(({ organizationName, organizationUuid }) => (
        <option
          key={organizationUuid}
          value={organizationUuid}
          data-name={organizationName}
        >
          {organizationName}
        </option>
      ))}
    </select>
  ) : null;
};

export default AvailableOrganizationSelectorContainer;
