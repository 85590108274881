import { useEffect, useState } from 'react';
import { Coordinates } from 'Chart';
import { Map, Marker } from 'pigeon-maps';
import { maptiler, osm } from 'pigeon-maps/providers';

import { theme } from '@/theme/theme';

import { getAllCoords, getCenterCoordinates } from '@/utils/chart';

import { CarbonPark } from '@/redux/reducers/carbon/state';

type Props = {
  parkLineList: CarbonPark[];
  onClick: (parkLine: CarbonPark) => void;
};

export default function CarbonParkLineMap({ parkLineList, onClick }: Props) {
  const [centerCoords, setCenterCoords] = useState<Coordinates>([0, 0]);
  const [zoom, setZoom] = useState(11);

  const mapTilerApiKey = process.env.REACT_APP_MAPTILER_API_KEY;
  const mapProvider = mapTilerApiKey
    ? maptiler(mapTilerApiKey, 'streets')
    : osm;

  useEffect(() => {
    const allCoords = getAllCoords(parkLineList);
    const centerCoords = getCenterCoordinates(allCoords);
    setCenterCoords(centerCoords);
    if (allCoords.length > 1) {
      setZoom(4);
    }
  }, [parkLineList]);

  return (
    <>
      <Map
        height={500}
        center={centerCoords}
        zoom={zoom}
        provider={mapProvider}
      >
        {parkLineList.map(parkLine => (
          <Marker
            width={50}
            anchor={[parkLine.latitude, parkLine.longitude]}
            color={theme.palette.ui.primaryGreen.main}
            onClick={() => onClick(parkLine)}
          />
        ))}
      </Map>
    </>
  );
}
