import {
  GET_COMPANY_POWERBI_REPORT,
  GET_COMPANY_POWERBI_REPORT_FAIL,
  GET_COMPANY_POWERBI_REPORT_SUCCESS,
  ProtocolDispatchTypes,
  RESET_COMPANY_POWERBI_REPORT,
} from './actionTypes';
import { defaultProtocolState, IDefaultProtocolState } from './state';

const protocolReducer = (
  state: IDefaultProtocolState = defaultProtocolState,
  action: ProtocolDispatchTypes,
): IDefaultProtocolState => {
  switch (action.type) {
    case GET_COMPANY_POWERBI_REPORT:
      return {
        ...state,
        powerBIReport: action.payload,
        isLoading: true,
        isError: false,
        errorMsg: null,
      };
    case GET_COMPANY_POWERBI_REPORT_SUCCESS:
      return {
        ...state,
        powerBIReport: action.payload,
        isLoading: false,
        isError: false,
        errorMsg: null,
      };
    case GET_COMPANY_POWERBI_REPORT_FAIL:
      return {
        ...state,
        powerBIReport: action.payload,
        isLoading: false,
        isError: true,
        errorMsg: action.payload,
      };
    case RESET_COMPANY_POWERBI_REPORT:
      return {
        ...defaultProtocolState,
      };
    default:
      return state;
  }
};

export default protocolReducer;
