import { useEffect, useState } from 'react';
import { TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { LoadingWheel, ProgressBar } from '@/components/Atoms';
import { NavBreadcrumbs } from '@/components/Molecules';
import { FamilyDetailsContainer } from '@/components/Templates';

import { convertUppercase } from '@/utils/general';

import { getGR491FamilyDetail } from '@/redux/reducers/gr491/actions';
import { Category } from '@/redux/reducers/gr491/state';
import { useAppDispatch, useAppSelector } from '@/redux/stores/hooks';

import {
  FamilyDetailsPageContainer,
  FamilyDetailsPageContentContainer,
  FamilyDetailsPageFilterContainer,
  FamilyDetailsPageHeaderContainer,
  FamilyDetailsPageHeaderIntro,
  FamilyDetailsPageHeaderIntroDesc,
  FamilyDetailsPageHeaderIntroLine,
  FamilyDetailsPageHeaderIntroLineProgressBar,
  FamilyDetailsPageHeaderIntroLineProgressBarText,
  FamilyDetailsPageHeaderIntroLineTitle,
  FilterAutocomplete,
  MyChip,
} from './FamilyDetailsPage.styles';

import type { BreadcrumbItem } from '@/components/Molecules/NavBreadcrumbs/NavBreadcrumbs';

// TODO fetch the tags from the backend
const tags: string[] = [
  'Renewable Energy',
  'Sustainable Agriculture',
  'Circular Economy',
  'Green Transportation',
  'Waste Reduction',
  'Water Conservation',
  'Eco-Friendly Products',
  'Climate Action',
  'Biodiversity Preservation',
  'Social Responsibility',
];

const priorities: string[] = ['high', 'medium', 'low'];

export const FamilyDetailsPage = () => {
  const familyID = useParams()?.familyID;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isLoading, familyDetail } = useAppSelector(store => store.gr491);
  const [questionProgress, setQuestionProgress] = useState<number>(0);
  const [prioritiesFilter, setPrioritiesFilter] = useState<string[]>([]);
  const BreadcrumbItemList: BreadcrumbItem[] = [
    {
      key: '1',
      title: t('pages.sdgAssessment'),
      color: 'rgb(106, 113, 111)',
      link: true,
      onClickAction: () => navigate('/sustainable-it-audit'),
    },
    {
      key: '2',
      title: familyDetail.title
        ? convertUppercase(familyDetail.title)
        : t('pages.questionList'),
      color: 'rgb(32, 40, 38)',
      link: false,
      onClickAction: () => null,
    },
  ];

  useEffect(() => {
    if (familyID) {
      dispatch(getGR491FamilyDetail({ familyID }));
    }
  }, [dispatch, familyID]);

  useEffect(() => {
    if (familyDetail.questionsCompleted && familyDetail.questionsTotal) {
      setQuestionProgress(
        (familyDetail.questionsCompleted * 100) / familyDetail.questionsTotal,
      );
    } else setQuestionProgress(0);
  }, [familyDetail]);

  return (
    <FamilyDetailsPageContainer>
      <FamilyDetailsPageHeaderContainer>
        <NavBreadcrumbs breadcrumbItems={BreadcrumbItemList} />
        {isLoading ? (
          <LoadingWheel isChart={true} />
        ) : (
          <FamilyDetailsPageHeaderIntro>
            <FamilyDetailsPageHeaderIntroLine>
              <FamilyDetailsPageHeaderIntroLineTitle variant="h1">
                {convertUppercase(familyDetail.title)}
              </FamilyDetailsPageHeaderIntroLineTitle>
              <FamilyDetailsPageHeaderIntroLineProgressBar>
                <FamilyDetailsPageHeaderIntroLineProgressBarText variant="body1">
                  {`${familyDetail.questionsCompleted} / ${
                    familyDetail.questionsTotal
                  } ${t('pages.totalAnswered')}`}
                </FamilyDetailsPageHeaderIntroLineProgressBarText>
                <ProgressBar width="100%" value={questionProgress} />
              </FamilyDetailsPageHeaderIntroLineProgressBar>
            </FamilyDetailsPageHeaderIntroLine>
            <FamilyDetailsPageHeaderIntroDesc variant="subtitle1">
              {familyDetail.desc}
            </FamilyDetailsPageHeaderIntroDesc>
          </FamilyDetailsPageHeaderIntro>
        )}
      </FamilyDetailsPageHeaderContainer>
      <FamilyDetailsPageFilterContainer>
        <FilterAutocomplete
          limitTags={2}
          multiple
          options={priorities.map(item => t(`pages.${item}`))}
          disableCloseOnSelect={false}
          onChange={(event, value) => {
            setPrioritiesFilter(value as string[]);
          }}
          renderTags={(value, getTagProps) => {
            return value.map((option, index) => (
              <MyChip
                {...getTagProps({ index })}
                label={
                  <Typography variant="body2">
                    {t(`pages.${option}`)}
                  </Typography>
                }
              />
            ));
          }}
          renderInput={params => (
            <TextField
              {...params}
              variant="outlined"
              label={t('pages.priority')}
              placeholder={t('pages.selectPriorities')}
            />
          )}
        />
        {/* TODO tags are not translated yet */}
        <FilterAutocomplete
          sx={{
            backgroundColor: 'ui.neutral.white',
          }}
          limitTags={2}
          multiple
          options={tags}
          disableCloseOnSelect
          renderTags={(value, getTagProps) => {
            return value.map((option, index) => (
              <MyChip
                {...getTagProps({ index })}
                label={
                  <Typography variant="body2">{option as string}</Typography>
                }
              />
            ));
          }}
          renderInput={params => (
            <TextField
              {...params}
              variant="outlined"
              label={t('pages.tag')}
              placeholder={t('pages.selectTags')}
            />
          )}
        />
      </FamilyDetailsPageFilterContainer>
      <FamilyDetailsPageContentContainer>
        {familyDetail.categoryList.map((item: Category, idx: number) => (
          <FamilyDetailsContainer
            key={idx}
            priorities={prioritiesFilter}
            familyID={familyID ? familyID : 'no-family'}
            familyItem={item}
          />
        ))}
      </FamilyDetailsPageContentContainer>
    </FamilyDetailsPageContainer>
  );
};
