import { useEffect, useState } from 'react';
import { Box, styled } from '@mui/material';
import ReactEcharts from 'echarts-for-react';

import type { BarType } from '@/redux/reducers/carbon/state';
import type { EChartsOption } from 'echarts-for-react';

interface HorizontalBarChartProps {
  xAxisLabel: string[];
  chartData: BarType[];
}

export const HorizontalBarChart = ({
  xAxisLabel,
  chartData,
}: HorizontalBarChartProps) => {
  const [arrangedData, setArrangedData] = useState<BarType[]>();

  useEffect(() => {
    const data: BarType[] = chartData.sort((a, b) => {
      return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
    });
    setArrangedData(data);
  }, [chartData]);

  const option: EChartsOption = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    legend: {},
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'value',
    },
    yAxis: {
      type: 'category',
      data: xAxisLabel,
      color: ['black'],
    },
    series: arrangedData,
  };

  return (
    <BarChartWrapper>
      <ReactEcharts
        option={option}
        lazyUpdate={true}
        style={{ width: '100%', height: '100%' }}
      />
    </BarChartWrapper>
  );
};

const BarChartWrapper = styled(Box)`
  display: flex;
  box-sizing: border-box;
  width: 100vw;
  max-width: 1000px;
  height: 100%;
  min-height: 500px;
`;
