import { Box, Container, Dialog, Grid, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { CustomButton as LogOutButton } from '@/components/Atoms';
import { Timer } from '@/components/Organisms';

import { getIdTokenExpiry } from '@/utils/auth';

import { useAppSelector } from '@/redux/stores/hooks';

interface UserMenuContainerProps {
  open: boolean;
  onClickAction: any;
  handleLogout: () => void;
}

export const UserMenuContainer = ({
  open,
  onClickAction,
  handleLogout,
}: UserMenuContainerProps) => {
  const user = useAppSelector(store => store.auth.userInfo);
  const expiryString = getIdTokenExpiry() || '0';
  const expiry = +expiryString;
  const currentTime = Date.now();
  const timeDiff = expiry - currentTime;
  const differenceDate = new Date(timeDiff);
  const { t } = useTranslation();
  return (
    <Dialog open={open} onClick={onClickAction}>
      <UserMenuCard>
        <Grid container spacing={1} rowSpacing={5}>
          <Grid item xs={4}>
            <Item> {t('templates.user')}: </Item>
          </Grid>
          <Grid item xs={8}>
            <Item>{user.login}</Item>
          </Grid>
          <Grid item xs={4}>
            <Item> {t('templates.username')}: </Item>
          </Grid>
          <Grid item xs={8}>
            <Item>{user.email}</Item>
          </Grid>
          <Grid item xs={4}>
            <Item> {t('templates.timeUntilLogOut')}: </Item>
          </Grid>
          <Grid item xs={8}>
            <Timer
              initialMinute={differenceDate.getUTCMinutes()}
              initialSeconds={differenceDate.getUTCSeconds()}
            />
          </Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={8}>
            <LogOutButton
              text={t('templates.signOut')}
              level={'Secondary'}
              width={'100%'}
              onClickAction={handleLogout}
            />
          </Grid>
        </Grid>
      </UserMenuCard>
    </Dialog>
  );
};

const UserMenuCard = styled(Container)(({ theme }) => ({
  padding: '24px',
  width: '500px',
  height: '700px',

  background: theme.palette.ui.neutral.white,
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 1)',
  borderRadius: '8px',
}));

const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body1,
}));
