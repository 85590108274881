import { CircularProgress, Container, styled, Typography } from '@mui/material';

export const PageContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: 24,
  gap: 24,
  width: '100%',
});

export const ActionListStatusRow = styled(Typography)`
  &.MuiTypography-root {
    display: block;
    font-size: 1.5em;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 20px;
    font-weight: bold;
    display: flex;
    flex-direction: row;
  }
  &:after {
    content: '';
    flex: 1;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    margin: auto;
  }
`;

export const LoaderWrapper = styled(Container)`
  &.MuiContainer-root {
    background: rgba(0, 0, 0, 0.12);
    padding: 0;
    align-items: center;
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100000;
    max-width: 100%;
  }
`;

export const CustomLoader = styled(CircularProgress)`
  &.MuiCircularProgress-root {
    position: relative;
    left: calc(50% - 50px);
    top: 50%;
    color: var(--primary_green);
  }
`;
