import { RadioButtonChecked, RadioButtonUnchecked } from '@mui/icons-material';
import { IconButton, styled } from '@mui/material';

const StyledMultipleChoiceButton = styled(IconButton)`
  &.MuiIconButton-root {
    justify-content: flex-start;
    padding: 0px;
    font-weight: 600;
    color: var(--text_primary);
    box-sizing: border-box;
    background: var(--neutral_white);
    border: 1px solid var(--stroke);
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
  }
  &.Mui-disabled {
    background: var(--neutral_grey_light);
    color: var(--neutral_grey_dark);
  }
  &:focus,
  &:hover {
    color: var(--primary_green);
    box-sizing: border-box;
    background: var(--primary_green_white);
    border: 1px solid var(--primary_green);

    div {
      color: var(--text_primary);
    }
  }
`;

interface MultipleChoiceButtonProps {
  text: string;
  focused: boolean;
  onClickAction: any;
  disabled?: boolean;
}

export const MultipleChoiceButton = ({
  text,
  focused,
  onClickAction,
  disabled,
}: MultipleChoiceButtonProps) => {
  return (
    <StyledMultipleChoiceButton disabled={disabled} onClick={onClickAction}>
      {focused ? (
        <RadioButtonChecked fontSize="small" sx={{ padding: '18px' }} />
      ) : (
        <RadioButtonUnchecked fontSize="small" sx={{ padding: '18px' }} />
      )}
      <div className="subtitle1-desktop">{text}</div>
    </StyledMultipleChoiceButton>
  );
};
