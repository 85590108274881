import type { GridColDef } from '@mui/x-data-grid';

// pass translation key instead of header name
export const KPITableColumns: GridColDef[] = [
  {
    field: 'KPI_CATEGORY',
    headerName: 'constants.kpiCategory',
    type: 'string',
    flex: 1,
  },
  {
    field: 'KPI_NAME',
    headerName: 'constants.kpiName',
    type: 'string',
    flex: 1,
  },
  {
    field: 'KPI_DESCRIPTION',
    headerName: 'constants.kpiDescription',
    type: 'string',
    flex: 1,
    renderCell: params => params.value || 'N/A',
  },
  {
    field: 'KPI_SCORE',
    headerName: 'constants.kpiScore',
    type: 'number',
    editable: true,
    flex: 1,
  },
  {
    field: 'KPI_MAX_SCORE',
    headerName: 'constants.kpiMaxScore',
    type: 'number',
    editable: true,
    flex: 1,
  },
  {
    field: 'KPI_NORMALIZED',
    headerName: 'constants.kpiNormalizedScore',
    type: 'number',
    editable: false,
    flex: 1,
  },
  {
    field: 'KPI_COMMENT',
    headerName: 'constants.kpiComment',
    type: 'string',
    editable: true,
    flex: 1,
    renderCell: params => params.value || 'N/A',
  },
];
