import { renderToString } from 'react-dom/server';
import { useTranslation } from 'react-i18next';

import { removeHashtag, removeSDGGoal } from '@/utils/general';

import type { SanKeyResult } from '@/models/chart';
import type { FC, PropsWithChildren } from 'react';

const LineBreak: FC = () => (
  <hr style={{ backgroundColor: 'var(--stroke)', width: '100%' }} />
);

const TooltipWrapper: FC<PropsWithChildren> = ({ children }) => (
  <div>{children}</div>
);

const HeaderWrapper: FC<PropsWithChildren> = ({ children }) => (
  <div className="subtitle1-desktop" style={{ padding: '8px 12px' }}>
    {children}
  </div>
);

const ContentWrapper: FC<PropsWithChildren> = ({ children }) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'stretch',
      padding: '8px 16px',
    }}
  >
    {children}
  </div>
);

// NOTE: Mui and styled components cannot work on Apache Echarts Tooltip
export const TreeMapChartToolTip = ({
  data,
}: {
  data: { name: string; value: number };
}) => {
  const returnComponent = (
    <div>
      <div>{data.name}</div>
      <div>{data.value}</div>
    </div>
  );

  return renderToString(returnComponent);
};

export const SankeyChartDomainToolTip = ({ data }: any) => {
  const { t } = useTranslation();
  const returnComponent = (
    <TooltipWrapper>
      <HeaderWrapper>{data.name}</HeaderWrapper>
      <LineBreak />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'stretch',
          width: '280px',
          padding: '16px',
        }}
      >
        <div className="body2-desktop">{t('atoms.answeredQuestions')}</div>
        <div className="subtitle2-desktop">
          {data.questionsCompleted}/{data.questionsTotal}
        </div>
      </div>
      <LineBreak />
      <ContentWrapper>
        <div className="body2-desktop">{t('atoms.totalScore')}</div>
        <div className="subtitle2-desktop">{data.totalScore.toFixed(2)}</div>
      </ContentWrapper>
      {data.sdgContributions.map((sdgContribution: SanKeyResult) => (
        <ContentWrapper>
          <div className="body2-desktop">
            {t('atoms.contributedTo')} {removeHashtag(sdgContribution.sdg)}
          </div>
          <div className="subtitle2-desktop">
            {Math.round((sdgContribution.score / data.totalScore) * 100)}%
          </div>
        </ContentWrapper>
      ))}
    </TooltipWrapper>
  );

  return renderToString(returnComponent);
};

export const SankeyChartSdgToolTip = ({ data }: any) => {
  const { t } = useTranslation();
  const returnComponent = (
    <TooltipWrapper>
      <div style={{ padding: '16px' }}>
        <div className="subtitle1-desktop">{removeHashtag(data.name)}</div>
        <div className="subtitle1-desktop" style={{ fontWeight: 400 }}>
          {removeSDGGoal(data.title)}
        </div>
      </div>
      <LineBreak />
      <ContentWrapper>
        <div className="body2-desktop">{t('atoms.resultScore')}</div>
        <div className="subtitle2-desktop">{Number(data.score.toFixed(2))}</div>
      </ContentWrapper>
      {data.domainContributions.map((domainContribution: SanKeyResult) => (
        <ContentWrapper>
          <div className="body2-desktop">
            {t('atoms.contributedBy')}{' '}
            {removeHashtag(domainContribution.domains)}
          </div>
          <div className="subtitle2-desktop">
            {Math.round((domainContribution.score / data.totalScore) * 100)}%
          </div>
        </ContentWrapper>
      ))}
    </TooltipWrapper>
  );

  return renderToString(returnComponent);
};

export const RadarChartToolTip = ({ data }: any) => {
  const returnComponent = (
    <TooltipWrapper>
      <HeaderWrapper>{data.name}</HeaderWrapper>
      <LineBreak />
      {data.scores.map((score: any) => (
        <ContentWrapper key={`${score.name}-${score.score}`}>
          <div className="body2-desktop">{score.name}</div>
          <div className="body2-desktop" style={{ fontWeight: 600 }}>
            {Number(score.score.toFixed(2))}
          </div>
        </ContentWrapper>
      ))}
    </TooltipWrapper>
  );

  return renderToString(returnComponent);
};
