import { Box, ButtonBase, ListItem, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { HeaderButton } from '@/components/Atoms';
import { AvailableOrganizationSelectorContainer } from '@/components/Templates/AvailableOrganizationSelectorContainer/AvailableOrganizationSelectorContainer';
import { LanguageSelectorContainer } from '@/components/Templates/LanguageSelectorContainer/LanguageSelectorContainer';

import { Logo as HeaderLogo } from '@/assets/images';

interface HeaderProps {
  onClickUsersAction: any;
}

export const Header = ({ onClickUsersAction }: HeaderProps) => {
  const navigate = useNavigate();

  return (
    <HeaderWrapper>
      <HeaderContainer>
        <HeaderLogoContainer onClick={() => navigate('/')}>
          <img
            src={HeaderLogo}
            alt="header-logo"
            style={{
              height: '100%',
            }}
          />
        </HeaderLogoContainer>
        <HeaderLanguageContainer>
          <LanguageSelectorContainer />
          <AvailableOrganizationSelectorContainer />
        </HeaderLanguageContainer>
        <HeaderButton type="User" onClickAction={onClickUsersAction} />
      </HeaderContainer>
    </HeaderWrapper>
  );
};

const HeaderLanguageContainer = styled(ListItem)(() => ({
  display: 'flex',
  justifySelf: 'flex-start',
  justifyContent: 'right',
  height: '100%',
  padding: '5px 0 0',
  borderRadius: '50%',
}));

const HeaderLogoContainer = styled(ButtonBase)(() => ({
  display: 'flex',
  justifySelf: 'flex-start',
  justifyContent: 'left',
  height: '100%',
  padding: '2px 30px 0',
}));

const HeaderWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  position: 'fixed',
  justifyContent: 'center',
  height: 56,
  top: 0,
  left: 0,
  right: 0,
  background: theme.palette.ui.neutral.white,
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.12)',
  zIndex: 1000,
}));

const HeaderContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
}));
