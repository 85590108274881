import { CloseOutlined, InfoOutlined } from '@mui/icons-material';
import { Box, Dialog, IconButton, styled, Typography } from '@mui/material';

import { theme } from '@/theme/theme';

export const FullScreenChartDialog = ({
  children,
  title,
  isOpen,
  handleOnSeeDetail,
  handleClose,
}: {
  children: any;
  title: string;
  isOpen: boolean;
  handleOnSeeDetail: any;
  handleClose: any;
}) => {
  return (
    <Dialog open={isOpen} fullScreen>
      <FullScreenChartContainer>
        <FullScreenChartHeaderContainer>
          <Typography variant="h3">{title}</Typography>
          <IconsContainer>
            <ChartIconButton onClick={handleOnSeeDetail}>
              <InfoOutlined
                style={{ color: theme.palette.ui.primaryGreen.main }}
              />
            </ChartIconButton>
            <ChartIconButton onClick={handleClose}>
              <CloseOutlined
                style={{ color: theme.palette.ui.primaryGreen.main }}
              />
            </ChartIconButton>
          </IconsContainer>
        </FullScreenChartHeaderContainer>
        <FullScreenChartContentContainer>
          {children}
        </FullScreenChartContentContainer>
      </FullScreenChartContainer>
    </Dialog>
  );
};

const FullScreenChartContainer = styled(Box)(({ theme }) => ({
  background: theme.palette.ui.neutral.white,
  height: '100%',
}));

const FullScreenChartHeaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: '16px 24px',
  borderBottom: `1px solid ${theme.palette.ui.stroke}`,
}));

const FullScreenChartContentContainer = styled(Box)(() => ({
  height: '100%',
  padding: '24px',
}));

const IconsContainer = styled(Box)(() => ({
  position: 'relative',
  top: '-4px',
}));

const ChartIconButton = styled(IconButton)(() => ({
  padding: '6px',
}));
