import { Box, List, ListItem, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { displaySDGTitlesOnly } from '@/utils/general';

import type { SDGItem } from '@/redux/reducers/gr491/state';

export const TopSDGContainer = ({
  amount,
  topSDGitems,
}: {
  amount: number;
  topSDGitems: SDGItem[];
}) => {
  const { t } = useTranslation();
  return (
    <TopSDGItemsContainer>
      {topSDGitems?.map((sdg: SDGItem, idx: number) => {
        return (
          idx < amount && (
            <TopSDGItemRow key={idx}>
              <TopSDGItemId>
                <Typography variant="subtitle2">{idx + 1}</Typography>
              </TopSDGItemId>
              <TopSDGItemImageWrapper>
                <img
                  src={
                    sdg.imageUrl !== '' || null || undefined ? sdg.imageUrl : ''
                  }
                  alt={`to-sdg-item-${idx}`}
                  style={{
                    maxWidth: '100%',
                    maxHeight: '100%',
                  }}
                />
              </TopSDGItemImageWrapper>
              <TopSDGItemName>
                <Typography variant="body1">
                  {displaySDGTitlesOnly(sdg.sdg)}
                </Typography>
              </TopSDGItemName>
              <TopSDGItemScore>
                <TopSDGItemTitle>
                  <Typography variant="body2">
                    {t('templates.result')}
                  </Typography>
                </TopSDGItemTitle>
                <TopSDGItemValue>
                  <Typography variant="subtitle1">
                    {sdg?.avgScore?.toFixed(0)}
                  </Typography>
                </TopSDGItemValue>
              </TopSDGItemScore>
            </TopSDGItemRow>
          )
        );
      })}
    </TopSDGItemsContainer>
  );
};

const TopSDGItemsContainer = styled(List)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  height: '100%',
  maxHeight: 250,
  maxWidth: '100%',
}));

const TopSDGItemRow = styled(ListItem)(() => ({
  display: 'flex',
  boxSizing: 'border-box',
  alignItems: 'center',
  gap: 16,
  maxHeight: 50,
  padding: 0,
}));

const TopSDGItemId = styled(Box)(() => ({
  width: 7,
}));

const TopSDGItemImageWrapper = styled(Box)(() => ({
  maxWidth: 50,
  maxHeight: 50,
}));

const TopSDGItemName = styled(Box)(({ theme }) => ({
  color: theme.palette.ui.neutral.black,
  paddingLeft: 10,
  display: 'flex',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
}));

const TopSDGItemScore = styled(Box)(() => ({
  marginLeft: 'auto',
}));

const TopSDGItemTitle = styled(Box)(({ theme }) => ({
  fontWeight: 400,
  textAlign: 'end',
  color: theme.palette.ui.neutral.greyDark,
}));

const TopSDGItemValue = styled(Box)(() => ({
  fontWeight: 600,
  textAlign: 'end',
}));
