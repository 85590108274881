import { Close, TextSnippetOutlined } from '@mui/icons-material';
import { Box, Button, IconButton, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

import type { ChangeEvent } from 'react';

const StyledFileBox = styled(Box)`
  display: grid;
  grid-template-columns: 14fr 1fr;
  align-items: center;

  width: 100%;
  padding: 4px;

  font-family: 'Open-Sans-Regular';
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;

  color: var(--neutral_grey_dark);
  background: var(--neutral_grey);
  border: 1px solid var(--neutral_grey_light);
  border-radius: 6px;
`;

interface UploadButtonProps {
  label: string;
  acceptedTypes: string;
  file: File | null;
  handleFileChange: any;
  handleReset: any;
}

export const ReportUploadButton = ({
  label,
  acceptedTypes,
  file,
  handleFileChange,
  handleReset,
}: UploadButtonProps) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
      }}
    >
      <Button
        variant="contained"
        component="label"
        sx={{
          width: '200px',
          background: 'var(--neutral_white)',
          boxShadow: 'none',
          color: 'var(--primary_green)',
          border: '2px solid var(--primary_green)',
          borderRadius: '4px',
          fontFamily: 'Montserrat-Medium',
          fontSize: '14px',
          fontWeight: '600',
          lineHeight: '20px',
          letterSpacing: '0.15px',
          '&:hover': {
            background: 'var(--neutral_white)',
            boxShadow: 'none',
          },
        }}
      >
        {label}
        <input
          type="file"
          hidden
          accept={acceptedTypes}
          onChange={(e: ChangeEvent<HTMLInputElement>) => handleFileChange(e)}
        />
      </Button>
      <StyledFileBox>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '4px',
            padding: '8px',
          }}
        >
          <TextSnippetOutlined fontSize="small" />
          {file ? file.name : t('molecules.fileNameWillDisplay')}
        </Box>
        {file && (
          <IconButton
            size="small"
            sx={{ padding: 0, borderRadius: 0, margin: 0 }}
            onClick={handleReset}
          >
            <Close />
          </IconButton>
        )}
      </StyledFileBox>
    </Box>
  );
};
