import { useState } from 'react';
import { Box, Fab, styled } from '@mui/material';

import { Chatbox } from '@/components/Organisms';

import { ChatbotIcon } from '@/assets/images';

import { getChatbotResponse } from '@/redux/reducers/chatbot/actions';
import { useAppDispatch, useAppSelector } from '@/redux/stores/hooks';

export const ChatbotContainer = () => {
  const dispatch = useAppDispatch();
  const [isActive, setIsActive] = useState<boolean>(false);
  const { response, isLoading } = useAppSelector(store => store.chatbot);

  const handleFabOnClick = () => {
    setIsActive(true);
  };

  const handleChatboxOnClose = () => {
    setIsActive(false);
  };

  const handleChatboxOnSubmit = async (query: string) => {
    await dispatch(getChatbotResponse({ query }));
  };

  return (
    <ChatbotContentContainer>
      {isActive && (
        <Chatbox
          response={response}
          isLoading={isLoading}
          onClose={handleChatboxOnClose}
          onSubmit={handleChatboxOnSubmit}
        />
      )}
      <FabButton onClick={handleFabOnClick}>
        <img src={ChatbotIcon} alt="ChatbotIcon" />
      </FabButton>
    </ChatbotContentContainer>
  );
};

const ChatbotContentContainer = styled(Box)(() => ({
  position: 'fixed',
  bottom: '30px',
  right: '30px',
  zIndex: '100',
}));

const FabButton = styled(Fab)(
  ({ theme }) => `
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 60px;
  height: 60px;
  z-index: 110;
  background: ${theme.palette.ui.primaryGreen.main};
  &:hover {
    background: ${theme.palette.ui.primaryGreen.main};
  }
`,
);
