import { useEffect, useState } from 'react';
import { Box, styled, Typography } from '@mui/material';
import ReactEcharts, { EChartsOption } from 'echarts-for-react';
import { useTranslation } from 'react-i18next';

import { theme } from '@/theme/theme';

import { quizResultOverviewInitialState } from '@/redux/reducers/quiz/state';

import type { QuizResult } from '@/redux/reducers/quiz/state';

export const QuizResultPieChart = ({ scores }: { scores: QuizResult[] }) => {
  const [correctAnswers, setCorrectAnswers] = useState<number>(0);
  const [incorrectAnswers, setIncorrectAnswers] = useState<number>(0);
  const [totalPercentage, setTotalPercentage] = useState<number>(0);

  useEffect(() => {
    if (scores !== quizResultOverviewInitialState) {
      let newCorrectAnswers = 0;
      let newIncorrectAnswers = 0;
      scores.forEach(item => {
        newCorrectAnswers = newCorrectAnswers + item.answersCorrect;
        newIncorrectAnswers =
          newIncorrectAnswers + (item.questionsTotal - item.answersCorrect);
      });
      setCorrectAnswers(newCorrectAnswers);
      setIncorrectAnswers(newIncorrectAnswers);
    }
  }, [scores]);

  useEffect(() => {
    setTotalPercentage(
      Math.round((correctAnswers * 100) / (correctAnswers + incorrectAnswers)),
    );
  }, [correctAnswers, incorrectAnswers]);

  const { t } = useTranslation();
  const pieParameters: EChartsOption = {
    tooltip: {
      trigger: 'item',
    },
    series: [
      {
        name: '',
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10,
          borderColor: '#fff',
          borderWidth: 2,
        },
        label: {
          show: false,
          position: 'center',
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 20,
            fontWeight: 'bold',
          },
        },
        labelLine: {
          show: false,
        },
        color: [theme.palette.ui.palo.green, theme.palette.error.main],
        data: [
          { value: correctAnswers, name: t('organisms.correct') },
          { value: incorrectAnswers, name: t('organisms.incorrect') },
        ],
      },
    ],
  };

  return (
    <QuizResultPieChartContainer>
      <Typography variant="subtitle1">{t('organisms.overall')}</Typography>
      <ReactEcharts
        option={pieParameters}
        lazyUpdate={true}
        style={{
          width: '100%',
          height: '100%',
          padding: 0,
          margin: 0,
        }}
      />
      <Typography variant="body2">{`${totalPercentage}% ${t(
        'organisms.numberOfCorrectAnswers',
      )}`}</Typography>
    </QuizResultPieChartContainer>
  );
};

const QuizResultPieChartContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  boxSizing: 'border-box',
  width: '100%',
  height: '100%',
  textAlign: 'center',
}));
