export interface IDefaultChatbotState {
  response: string;
  isLoading: boolean;
  isError: boolean;
  errorMsg: any | null;
}

export const defaultChatbotState: IDefaultChatbotState = {
  response: '',
  isLoading: false,
  isError: false,
  errorMsg: null,
};
