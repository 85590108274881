import {
  GET_GR491_FAMILY_DETAIL,
  GET_GR491_FAMILY_DETAIL_FAIL,
  GET_GR491_FAMILY_DETAIL_SUCCESS,
  GET_GR491_FAMILY_LIST,
  GET_GR491_FAMILY_LIST_FAIL,
  GET_GR491_FAMILY_LIST_SUCCESS,
  GET_GR491_QUESTION_DETAIL,
  GET_GR491_QUESTION_DETAIL_FAIL,
  GET_GR491_QUESTION_DETAIL_SUCCESS,
  GET_GR491_RESULT_OVERVIEW,
  GET_GR491_RESULT_OVERVIEW_FAIL,
  GET_GR491_RESULT_OVERVIEW_SUCCESS,
  GET_GR491_RESULT_SANKEY,
  GET_GR491_RESULT_SANKEY_FAIL,
  GET_GR491_RESULT_SANKEY_SUCCESS,
  GET_GR491_TOP_SDG,
  GET_GR491_TOP_SDG_FAIL,
  GET_GR491_TOP_SDG_SUCCESS,
  GR491DispatchTypes,
  RESET_GR491,
  UPDATE_GR491_QUESTION_DETAIL,
  UPDATE_GR491_QUESTION_DETAIL_FAIL,
  UPDATE_GR491_QUESTION_DETAIL_SUCCESS,
} from './actionTypes';
import {
  CategoryQuestionDetail,
  categoryQuestionDetailInitialState,
  Family,
  FamilyDetail,
  familyDetailInitialState,
  familyListInitialState,
  questionUpdateInitialState,
  QuestionUpdateType,
  sankeyDataInitialState,
  TopSDG,
  topSDGInitialState,
} from './state';

import type { RadarParameter, SanKeyParameter } from '@/models/chart';

export interface IDefaultGR491State {
  sankeyData: SanKeyParameter;
  topSDG: TopSDG;
  familyList: Family[];
  familyDetail: FamilyDetail;
  questionDetail: CategoryQuestionDetail;
  questionUpdateStatus: QuestionUpdateType;
  resultOverview: RadarParameter[];
  isLoading: boolean;
  isError: boolean;
  errorMsg: any | null;
}

export const defaultGR491State: IDefaultGR491State = {
  sankeyData: sankeyDataInitialState,
  topSDG: topSDGInitialState,
  familyList: familyListInitialState,
  familyDetail: familyDetailInitialState,
  questionDetail: categoryQuestionDetailInitialState,
  questionUpdateStatus: questionUpdateInitialState,
  resultOverview: [],
  isLoading: false,
  isError: false,
  errorMsg: null,
};

const gr491Reducer = (
  state: IDefaultGR491State = defaultGR491State,
  action: GR491DispatchTypes,
): IDefaultGR491State => {
  switch (action.type) {
    case GET_GR491_TOP_SDG:
      return {
        ...state,
        topSDG: topSDGInitialState,
        isLoading: true,
        isError: false,
        errorMsg: null,
      };
    case GET_GR491_TOP_SDG_SUCCESS:
      return {
        ...state,
        topSDG: action.payload,
        isLoading: false,
        isError: false,
        errorMsg: null,
      };
    case GET_GR491_TOP_SDG_FAIL:
      return {
        ...state,
        topSDG: topSDGInitialState,
        isLoading: false,
        isError: true,
        errorMsg: action.payload,
      };
    case GET_GR491_FAMILY_LIST:
      return {
        ...state,
        topSDG: topSDGInitialState,
        familyList: familyListInitialState,
        familyDetail: familyDetailInitialState,
        questionDetail: categoryQuestionDetailInitialState,
        questionUpdateStatus: questionUpdateInitialState,
        isLoading: true,
        isError: false,
        errorMsg: null,
      };
    case GET_GR491_FAMILY_LIST_SUCCESS:
      return {
        ...state,
        familyList: action.payload,
        isLoading: false,
        isError: false,
        errorMsg: null,
      };
    case GET_GR491_FAMILY_LIST_FAIL:
      return {
        ...state,
        familyList: familyListInitialState,
        isLoading: false,
        isError: true,
        errorMsg: action.payload,
      };
    case GET_GR491_FAMILY_DETAIL:
      return {
        ...state,
        questionUpdateStatus: questionUpdateInitialState,
        familyDetail: familyDetailInitialState,
        isLoading: true,
        isError: false,
        errorMsg: null,
      };
    case GET_GR491_FAMILY_DETAIL_SUCCESS:
      return {
        ...state,
        familyDetail: action.payload,
        isLoading: false,
        isError: false,
        errorMsg: null,
      };
    case GET_GR491_FAMILY_DETAIL_FAIL:
      return {
        ...state,
        familyDetail: familyDetailInitialState,
        isLoading: false,
        isError: true,
        errorMsg: action.payload,
      };
    case GET_GR491_QUESTION_DETAIL:
      return {
        ...state,
        questionDetail: categoryQuestionDetailInitialState,
        isLoading: true,
        isError: false,
        errorMsg: null,
      };
    case GET_GR491_QUESTION_DETAIL_SUCCESS:
      return {
        ...state,
        questionDetail: action.payload,
        isLoading: false,
        isError: false,
        errorMsg: null,
      };
    case GET_GR491_QUESTION_DETAIL_FAIL:
      return {
        ...state,
        questionDetail: categoryQuestionDetailInitialState,
        isLoading: false,
        isError: true,
        errorMsg: action.payload,
      };
    case GET_GR491_RESULT_OVERVIEW:
      return {
        ...state,
        isLoading: true,
        isError: false,
        errorMsg: null,
      };
    case GET_GR491_RESULT_OVERVIEW_SUCCESS:
      return {
        ...state,
        resultOverview: action.payload,
        isLoading: false,
        isError: false,
        errorMsg: null,
      };
    case GET_GR491_RESULT_OVERVIEW_FAIL:
      return {
        ...state,
        resultOverview: [],
        isLoading: false,
        isError: true,
        errorMsg: action.payload,
      };
    case UPDATE_GR491_QUESTION_DETAIL:
      return {
        ...state,
        questionUpdateStatus: questionUpdateInitialState,
        isLoading: true,
        isError: false,
        errorMsg: null,
      };
    case UPDATE_GR491_QUESTION_DETAIL_SUCCESS:
      return {
        ...state,
        questionUpdateStatus: action.payload,
        isLoading: false,
        isError: false,
        errorMsg: null,
      };
    case UPDATE_GR491_QUESTION_DETAIL_FAIL:
      return {
        ...state,
        questionUpdateStatus: questionUpdateInitialState,
        isLoading: false,
        isError: true,
        errorMsg: action.payload,
      };
    case GET_GR491_RESULT_SANKEY:
      return {
        ...state,
        isLoading: true,
        isError: false,
        errorMsg: null,
      };
    case GET_GR491_RESULT_SANKEY_SUCCESS:
      return {
        ...state,
        sankeyData: action.payload,
        isLoading: false,
        isError: false,
        errorMsg: null,
      };
    case GET_GR491_RESULT_SANKEY_FAIL:
      return {
        ...state,
        sankeyData: sankeyDataInitialState,
        isLoading: false,
        isError: true,
        errorMsg: action.payload,
      };
    case RESET_GR491:
      return {
        ...defaultGR491State,
      };
    default:
      return state;
  }
};

export default gr491Reducer;
