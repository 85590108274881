import { staticPagesMap } from '@/const/IframeRouteMap';

import { IFrame } from './IFramePage.styles';

export const IFramePage = () => {
  const path = staticPagesMap[window.location.pathname.split('/')[1]];

  return (
    <IFrame
      src={`${process.env.REACT_APP_EXTERNAL_WEBSITE_ENDPOINT_ROOT}/${path}`}
    />
  );
};
