import { Box, styled, Typography } from '@mui/material';

export const RadarChartDescription = ({
  description,
}: {
  description: string;
}) => {
  return (
    <DescriptionContainer>
      <DescriptionContent>
        <Typography variant="body2">{description}</Typography>
      </DescriptionContent>
    </DescriptionContainer>
  );
};

const DescriptionContainer = styled(Box)(({ theme }) => ({
  background: theme.palette.ui.neutral.white,
  maxWidth: '400px',
}));

const DescriptionContent = styled(Box)(() => ({
  padding: '8px 16px',
}));
