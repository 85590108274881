import { fetchUserInfo } from '@/services/auth';
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserSession,
} from 'amazon-cognito-identity-js';
import axios from 'axios';

import { UserPool } from '@/const';

import { generateDefaultApiHeaders } from '@/utils/api';
import { setIdToken, setIdTokenExpiry, setRefreshToken } from '@/utils/auth';

import { webServices } from '@/redux/stores/api';

import { RESET_ACTIONS } from '../action/actionTypes';
import { RESET_CARBON } from '../carbon/actionTypes';
import { RESET_GR491 } from '../gr491/actionTypes';
import { RESET_COMPANY_POWERBI_REPORT } from '../protocol/actionTypes';
import { RESET_QUIZ } from '../quiz/actionTypes';
import {
  GET_USER_AVAILABLE_ORGANIZATION_UUIDS,
  GET_USER_AVAILABLE_ORGANIZATION_UUIDS_FAIL,
  GET_USER_AVAILABLE_ORGANIZATION_UUIDS_SUCCESS,
  GET_USER_INFO,
  GET_USER_INFO_FAIL,
  GET_USER_INFO_SUCCESS,
  UPDATE_USER_ORGANIZATION,
  UPDATE_USER_ORGANIZATION_FAIL,
  UPDATE_USER_ORGANIZATION_SUCCESS,
} from './actionTypes';

import type { RootState } from '@/redux/stores/store';
import type { AnyAction, ThunkAction } from '@reduxjs/toolkit';

function asyncAuthenticateUser(
  cognitoUser: CognitoUser,
  cognitoAuthenticationDetails: AuthenticationDetails,
) {
  return new Promise(function (resolve, reject) {
    cognitoUser.authenticateUser(cognitoAuthenticationDetails, {
      onSuccess: (data: CognitoUserSession) => {
        const idToken = data.getIdToken();
        const refreshToken = data.getRefreshToken().getToken();
        setIdToken(idToken.getJwtToken());
        setRefreshToken(refreshToken);
        setIdTokenExpiry(idToken.payload['exp'] ?? 0);
        resolve(data);
      },
      onFailure: (err: any) => {
        reject(err);
      },
      newPasswordRequired: (data: any) => {
        console.warn('newPasswordRequired: ', data);
        resolve(data);
      },
    });
  });
}

export const authenticate = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => {
  const currentUser = new CognitoUser({
    Username: email,
    Pool: UserPool,
  });

  const authDetails = new AuthenticationDetails({
    Username: email,
    Password: password,
  });
  await asyncAuthenticateUser(currentUser, authDetails);
};

export const getUserInfo = (): ThunkAction<
  void,
  RootState,
  unknown,
  AnyAction
> => {
  return async dispatch => {
    dispatch({ type: GET_USER_INFO });

    await fetchUserInfo()
      .then(response => {
        dispatch({
          type: GET_USER_INFO_SUCCESS,
          payload: response.data.result[0],
        });
      })
      .catch(err => {
        dispatch({
          type: GET_USER_INFO_FAIL,
          payload: JSON.stringify(err),
        });
      });
    return;
  };
};

export const getUserAvailableOrganizationUuids = (): ThunkAction<
  void,
  RootState,
  unknown,
  AnyAction
> => {
  return async dispatch => {
    try {
      dispatch({ type: GET_USER_AVAILABLE_ORGANIZATION_UUIDS });
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT_ROOT}/${webServices.getUserOrganizationUuids}`,
        JSON.stringify({}),
        generateDefaultApiHeaders(),
      );
      dispatch({
        type: GET_USER_AVAILABLE_ORGANIZATION_UUIDS_SUCCESS,
        payload: response.data.result,
      });
    } catch (err) {
      dispatch({
        type: GET_USER_AVAILABLE_ORGANIZATION_UUIDS_FAIL,
        payload: JSON.stringify(err),
      });
    }
  };
};

export const updateUserOrganization = ({
  organizationUuid,
  organizationName,
}: {
  organizationUuid: string;
  organizationName: string;
}): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async dispatch => {
    try {
      dispatch({ type: UPDATE_USER_ORGANIZATION });
      await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT_ROOT}/${webServices.updateUserOrganization}`,
        JSON.stringify({
          organization_uuid: organizationUuid,
        }),
        generateDefaultApiHeaders(),
      );
      dispatch({
        type: UPDATE_USER_ORGANIZATION_SUCCESS,
        payload: {
          organizationUuid,
          organization: organizationName,
        },
      });
      dispatch({ type: RESET_GR491, payload: {} });
      dispatch({ type: RESET_ACTIONS, payload: {} });
      dispatch({ type: RESET_CARBON, payload: {} });
      dispatch({ type: RESET_QUIZ, payload: {} });
      dispatch({ type: RESET_COMPANY_POWERBI_REPORT, payload: {} });
    } catch (err) {
      dispatch({
        type: UPDATE_USER_ORGANIZATION_FAIL,
        payload: JSON.stringify(err),
      });
    }
  };
};
