import axios, { AxiosResponse } from 'axios';
import { FetchUserInfoResponse } from 'SustainablePlatform';

import { generateDefaultApiHeaders } from '@/utils/api';

import { webServices } from '@/redux/stores/api';

export async function fetchUserInfo(): Promise<
  AxiosResponse<FetchUserInfoResponse>
> {
  return await axios.post(
    `${process.env.REACT_APP_API_ENDPOINT_ROOT}/${webServices.getUserInfo}`,
    JSON.stringify({}),
    generateDefaultApiHeaders(),
  );
}
