import { IconButton, styled } from '@mui/material';

import { renderHeaderIcons } from '@/utils/general';

type HeaderButtonType = 'Settings' | 'Notifications' | 'User';

interface HeaderButtonProps {
  type: HeaderButtonType;
  onClickAction?: any;
}

export const HeaderButton = ({ type, onClickAction }: HeaderButtonProps) => {
  return (
    <StyledHeaderButton onClick={onClickAction}>
      {renderHeaderIcons(type)}
    </StyledHeaderButton>
  );
};

const StyledHeaderButton = styled(IconButton)`
  &.MuiIconButton-root {
    border-radius: 2px;
    display: flex;
    justify-self: flex-end;
    margin: auto 30px;
  }
`;
