import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import {
  QuestionContainer,
  QuestionDetailsContainer,
} from '@/components/Templates';

import { getGR491QuestionDetail } from '@/redux/reducers/gr491/actions';
import { useAppDispatch, useAppSelector } from '@/redux/stores/hooks';

import { Container } from './QuestionDetailsPage.styles';

interface QuestionDetailsParams {
  familyID: string;
  questionID: string;
}

export const QuestionDetailsPage = () => {
  const dispatch = useAppDispatch();
  const { familyID, questionID } = useParams<
    keyof QuestionDetailsParams
  >() as QuestionDetailsParams;
  const { questionDetail } = useAppSelector(store => store.gr491);

  useEffect(() => {
    if (questionID !== '') {
      dispatch(getGR491QuestionDetail({ questionID: questionID }));
    }
  }, [dispatch, questionID]);

  return (
    <Container>
      <QuestionContainer familyID={familyID} />
      <QuestionDetailsContainer questionItem={questionDetail} />
    </Container>
  );
};
