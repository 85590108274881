import type { AnswerOption } from '@/redux/reducers/gr491/state';

export interface Quiz {
  uuid: string;
  imageUrl: string;
  name: string;
  questionsTotal: number;
  tags: string[];
  firstQuestionUuid: string;
}

export const quizListInitialState: Quiz[] = [];

export interface QuizQuestion {
  quizUuid: string;
  questionUuid: string;
  nextQuestionUuid: string;
  questionOrder: number;
  question: string;
  hint: string | null;
  tags: string[];
  options: AnswerOption[];
}

export const quizQuestionInitialState: QuizQuestion = {
  quizUuid: '',
  questionUuid: '',
  nextQuestionUuid: '',
  questionOrder: 0,
  question: '',
  hint: null,
  tags: [],
  options: [],
};

export interface QuizQuestionUpdateStatus {
  questionUuid: string;
  completed: boolean;
}

export const quizQuestionUpdateStatusInitialState: QuizQuestionUpdateStatus = {
  questionUuid: '',
  completed: false,
};

export interface QuizResult {
  category: string;
  answersCorrect: number;
  questionsTotal: number;
}

export interface QuizResultTotal {
  updatedDate: string;
  personalScores: QuizResult[];
  projectScores: QuizResult[];
}

export const quizResultOverviewInitialState: QuizResult[] = [
  {
    category: '',
    answersCorrect: 0,
    questionsTotal: 0,
  },
];

export const quizResultTotalInitialState: QuizResultTotal = {
  updatedDate: '',
  personalScores: [],
  projectScores: [],
};

export interface QuizCategoryListItem {
  name: string;
}

export type QuizDomain = QuizCategoryListItem;

export interface QuizCategory {
  categories: QuizCategoryListItem[];
  domains: QuizDomain[];
}

export const quizCategoryListInitialState: QuizCategory = {
  categories: [],
  domains: [],
};
