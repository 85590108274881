import { AddOutlined, RefreshOutlined } from '@mui/icons-material';
import { IconButton, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

const StyledCarbonTableButtons = styled(IconButton)`
  &.MuiIconButton-root {
    flex-direction: column;
    border-radius: 2px;
  }
`;

type CarbonTableButtonsType = 'Refresh' | 'New Report';

interface CarbonTableButtonsProps {
  type: CarbonTableButtonsType;
  onClickAction?: any;
}

export const CarbonTableButtons = ({
  type,
  onClickAction,
}: CarbonTableButtonsProps) => {
  const { t } = useTranslation();
  return (
    <StyledCarbonTableButtons onClick={onClickAction}>
      {type === 'Refresh' ? <RefreshOutlined /> : <AddOutlined />}
      <div className="caption-desktop">
        {type === 'Refresh'
          ? t('atoms.refreshButton')
          : t('atoms.newReportButton')}
      </div>
    </StyledCarbonTableButtons>
  );
};
