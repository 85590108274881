import axios from 'axios';

import { generateDefaultApiHeaders } from '@/utils/api';

import { webServices } from '@/redux/stores/api';

import {
  GENERATE_QUIZ_RECOMMENDATION,
  GENERATE_QUIZ_RECOMMENDATION_FAIL,
  GENERATE_QUIZ_RECOMMENDATION_SUCCESS,
  GET_LP_QUIZ_CATRGORY,
  GET_LP_QUIZ_CATRGORY_FAIL,
  GET_LP_QUIZ_CATRGORY_SUCCESS,
  GET_LP_QUIZ_LIST,
  GET_LP_QUIZ_LIST_FAIL,
  GET_LP_QUIZ_LIST_SUCCESS,
  GET_LP_QUIZ_QUESTION,
  GET_LP_QUIZ_QUESTION_FAIL,
  GET_LP_QUIZ_QUESTION_SUCCESS,
  GET_LP_QUIZ_RESULT_OVERVIEW,
  GET_LP_QUIZ_RESULT_OVERVIEW_FAIL,
  GET_LP_QUIZ_RESULT_OVERVIEW_SUCCESS,
  UPDATE_LP_QUIZ_QUESTION,
  UPDATE_LP_QUIZ_QUESTION_FAIL,
  UPDATE_LP_QUIZ_QUESTION_SUCCESS,
} from './actionTypes';

import type { RootState } from '@/redux/stores/store';
import type { AnyAction, ThunkAction } from '@reduxjs/toolkit';

// GET
export const getLPQuizList = (): ThunkAction<
  void,
  RootState,
  unknown,
  AnyAction
> => {
  return async dispatch => {
    dispatch({ type: GET_LP_QUIZ_LIST });
    axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT_ROOT}/${webServices.getLPQuizList}`,
        JSON.stringify(''),
        generateDefaultApiHeaders(),
      )
      .then(res => {
        dispatch({
          type: GET_LP_QUIZ_LIST_SUCCESS,
          payload: res.data.result,
        });
      })
      .catch(err => {
        dispatch({
          type: GET_LP_QUIZ_LIST_FAIL,
          payload: JSON.stringify(err),
        });
      });
  };
};

export const getLPQuizQuestion = ({
  quizID,
  questionID,
}: {
  quizID: string;
  questionID: string;
}): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async dispatch => {
    dispatch({ type: GET_LP_QUIZ_QUESTION });
    axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT_ROOT}/${webServices.getLPQuizQuestion}`,
        JSON.stringify({ quiz_uuid: quizID, question_uuid: questionID }),
        generateDefaultApiHeaders(),
      )
      .then(res => {
        dispatch({
          type: GET_LP_QUIZ_QUESTION_SUCCESS,
          payload: res.data.result,
        });
      })
      .catch(err => {
        dispatch({
          type: GET_LP_QUIZ_QUESTION_FAIL,
          payload: JSON.stringify(err),
        });
      });
  };
};

export const getLPQuizCategoryList = (): ThunkAction<
  void,
  RootState,
  unknown,
  AnyAction
> => {
  return async dispatch => {
    dispatch({ type: GET_LP_QUIZ_CATRGORY });
    axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT_ROOT}/${webServices.getLPQuizCategoryList}`,
        JSON.stringify({
          // TEMP: temporarily set to english until we have a language handler
          language: 'English',
        }),
        generateDefaultApiHeaders(),
      )
      .then(res => {
        dispatch({
          type: GET_LP_QUIZ_CATRGORY_SUCCESS,
          payload: res.data.result,
        });
      })
      .catch(err => {
        dispatch({
          type: GET_LP_QUIZ_CATRGORY_FAIL,
          payload: JSON.stringify(err),
        });
      });
  };
};

// UPDATE
export const updateLPQuizQuestion = ({
  quizID,
  questionID,
  answerID,
}: {
  quizID: string;
  questionID: string;
  answerID: string;
}): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async dispatch => {
    dispatch({ type: UPDATE_LP_QUIZ_QUESTION });
    axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT_ROOT}/${webServices.updateLPQuizQuestion}`,
        JSON.stringify({
          quiz_uuid: quizID,
          question_uuid: questionID,
          answer_uuid: answerID,
        }),
        generateDefaultApiHeaders(),
      )
      .then(res => {
        dispatch({
          type: UPDATE_LP_QUIZ_QUESTION_SUCCESS,
          payload: res.data.result,
        });
      })
      .catch(err => {
        dispatch({
          type: UPDATE_LP_QUIZ_QUESTION_FAIL,
          payload: JSON.stringify(err),
        });
      });
  };
};

export const getLPQuizResultOverview = ({
  quizID,
}: {
  quizID: string;
}): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async dispatch => {
    dispatch({ type: GET_LP_QUIZ_RESULT_OVERVIEW });
    axios
      .post(
        `${process.env.REACT_APP_API_ENDPOINT_ROOT}/${webServices.getLPQuizResultOverview}`,
        JSON.stringify({ quiz_uuid: quizID }),
        generateDefaultApiHeaders(),
      )
      .then(res => {
        dispatch({
          type: GET_LP_QUIZ_RESULT_OVERVIEW_SUCCESS,
          payload: res.data.result,
        });
      })
      .catch(err => {
        dispatch({
          type: GET_LP_QUIZ_RESULT_OVERVIEW_FAIL,
          payload: JSON.stringify(err),
        });
      });
  };
};

export const generateQuizRecommendation = ({
  categories,
}: {
  categories: string[];
}): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async dispatch => {
    try {
      dispatch({ type: GENERATE_QUIZ_RECOMMENDATION });
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT_ROOT}/${webServices.generateQuizRecommendation}`,
        JSON.stringify({ categories }),
        generateDefaultApiHeaders(),
      );

      if (response?.data?.statusAPI !== 'SUCCESS') {
        throw new Error(
          `Generate Quiz Recommendation Error  ${response?.data?.errorMessage}`,
        );
      }

      dispatch({ type: GENERATE_QUIZ_RECOMMENDATION_SUCCESS });
      dispatch(getLPQuizList());
    } catch (err) {
      dispatch({
        type: GENERATE_QUIZ_RECOMMENDATION_FAIL,
        payload: JSON.stringify(err),
      });
    }
  };
};
