import { Box, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { CustomButton, ProgressBar } from '@/components/Atoms';

export const PageHeader = ({
  completedQuestions,
  totalQuestions,
  progressValue,
}: {
  completedQuestions: number;
  totalQuestions: number;
  progressValue: number;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <PageHeaderWrapper>
      <PageHeaderTitle variant="h1">
        {t('molecules.sdgAssessment')}
      </PageHeaderTitle>
      <PageHeaderProgressBar>
        <PageHeaderProgressBarText>
          <Typography variant="body1">
            {`${completedQuestions}/${totalQuestions} ${t(
              'molecules.totalAnswered',
            )}`}
          </Typography>
        </PageHeaderProgressBarText>
        <ProgressBar width="100%" value={progressValue} />
      </PageHeaderProgressBar>
      <CustomButton
        width={'120px'}
        level={'Primary'}
        iconType={'Right'}
        text={t('molecules.report')}
        onClickAction={() => navigate('/sustainable-it-audit/results')}
      />
    </PageHeaderWrapper>
  );
};

const PageHeaderWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '24px',
  gap: '24px',
  height: 'fit-content',
  background: theme.palette.ui.neutral.white,
  borderRadius: '8px',
}));

const PageHeaderTitle = styled(Typography)(({ theme }) => ({
  display: 'flex',
  fontWeight: 700,
  color: theme.palette.text.primary,
  whiteSpace: 'nowrap',
}));

const PageHeaderProgressBar = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
  width: 100%;
  height: 100%;
`;

const PageHeaderProgressBarText = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  fontWeight: '400',
  color: theme.palette.ui.neutral.black,
}));
