import { MenuItem, styled, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

import type { CarbonPark } from '@/redux/reducers/carbon/state';

const StyledDropdown = styled(TextField)`
  & .MuiSelect-select {
    font-family: 'Open-Sans-Regular';
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1px;
    text-transform: none;
  }

  & label.Mui-focused {
    color: var(--text_primary);
    font-weight: 800;
  }
  & .MuiOutlinedInput-root {
    font-family: 'Open-Sans-Regular';
    &.Mui-focused fieldset {
      color: var(--text_primary);
      border-color: var(--text_primary);
    }
  }
`;

interface ParkListDropDownProps {
  title?: string;
  placeholder?: string;
  value: string | number;
  onChangeAction?: any;
  showError?: boolean;
  disabled?: boolean;
  dropdownItems?: any;
}

export const ParkListDropDown = ({
  title,
  disabled,
  placeholder,
  value,
  onChangeAction,
  showError,
  dropdownItems,
}: ParkListDropDownProps) => {
  const { t } = useTranslation();
  return (
    <StyledDropdown
      label={disabled ? title + ` - ${t('molecules.cannotBeEdited')}` : title}
      variant="outlined"
      select
      disabled={disabled}
      placeholder={
        placeholder ? placeholder : `${t('molecules.pleaseEnterThe')} ` + title
      }
      value={value}
      onChange={onChangeAction}
      error={showError ? (value === '' ? true : false) : false}
    >
      {dropdownItems?.map((item: CarbonPark, idx: number) => (
        <MenuItem key={String(idx)} value={item.parkUuid}>
          {item.parkName}
        </MenuItem>
      ))}
    </StyledDropdown>
  );
};
