import { useState } from 'react';
import { ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Divider,
  styled,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

import { NonValidateLogo, ValidateLogo } from '@/assets/images';

export const ActionItemCard = ({
  actionId,
  isCompleted,
  description,
  comment,
  handleUpdateAction,
  expanded = false,
}: {
  actionId: string;
  isCompleted: boolean;
  description: string;
  comment: string;
  handleUpdateAction: (actionId: string, isComplete: boolean) => void;
  expanded: boolean;
}) => {
  const [accordionOpen, setAccordionOpen] = useState(expanded);
  const { t } = useTranslation();
  return (
    <ActionAccordion expanded={accordionOpen}>
      <ActionAccordionSummary
        expandIcon={
          <ExpandMore
            style={{ cursor: 'pointer' }}
            onClick={() => setAccordionOpen(!accordionOpen)}
          />
        }
        sx={{ cursor: 'unset !important' }}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <ActionItemImage
          onClick={() => handleUpdateAction(actionId, !isCompleted)}
        >
          <img
            src={isCompleted ? ValidateLogo : NonValidateLogo}
            alt="ValidateLogo"
          />
        </ActionItemImage>
        <ActionItemDescription className={isCompleted ? 'completed' : ''}>
          {description}
        </ActionItemDescription>
      </ActionAccordionSummary>
      <DividerContainer>
        <Divider />
      </DividerContainer>
      <AccordionDetails>
        <ActionItemRow>
          <ActionItemHintTitle>
            {t('molecules.whatYouCanDo')}
          </ActionItemHintTitle>
          <ActionItemHintDescription>{comment}</ActionItemHintDescription>
        </ActionItemRow>
      </AccordionDetails>
    </ActionAccordion>
  );
};

const ActionAccordion = styled(Accordion)(({ theme }) => ({
  margin: '1px 0',
  background: theme.palette.ui.neutral.white,
  boxShadow: 'none',
}));

const ActionAccordionSummary = styled(AccordionSummary)(() => ({
  '&.MuiAccordionSummary-content.Mui-expanded': {
    margin: '12px 0',
  },
}));

const ActionItemImage = styled(Box)(() => ({
  padding: '15px',
  cursor: 'pointer',
}));

const ActionItemDescription = styled(Typography)(({ theme }) => ({
  lineHeight: 3.5,
  fontSize: '1.2rem',
  '&.completed': {
    color: theme.palette.text.secondary,
  },
}));

const ActionItemRow = styled(Container)(() => ({
  display: 'flex',
  fleDdirection: 'row',
  paddingLeft: '70px',
  paddingRight: '15px',
  paddingTop: '15px',
  paddingBottom: '15px',
}));

const ActionItemHintTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  color: theme.palette.ui.primaryGreen.main,
  flex: 1,
}));

const ActionItemHintDescription = styled(Typography)(() => ({
  flex: 5,
}));

const DividerContainer = styled(Container)(() => ({
  paddingLeft: '80px',
  paddingRight: 0,
}));
