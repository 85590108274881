export const GET_CHATBOT_RESPONSE = 'GET_CHATBOT_RESPONSE';
export const GET_CHATBOT_RESPONSE_SUCCESS = 'GET_CHATBOT_RESPONSE_SUCCESS';
export const GET_CHATBOT_RESPONSE_FAIL = 'GET_CHATBOT_RESPONSE_FAIL';

export interface getChatbotResponse {
  type: typeof GET_CHATBOT_RESPONSE;
  payload: any;
}

export interface getChatbotResponseSuccess {
  type: typeof GET_CHATBOT_RESPONSE_SUCCESS;
  payload: string;
}

export interface getChatbotResponseFail {
  type: typeof GET_CHATBOT_RESPONSE_FAIL;
  payload: any;
}

export type ChatbotDispatchTypes =
  | getChatbotResponse
  | getChatbotResponseSuccess
  | getChatbotResponseFail;
