import { Action, ActionOverview } from './state';

export const GET_ALL_ACTIONS = 'GET_ALL_ACTIONS';
export const GET_ALL_ACTIONS_SUCCESS = 'GET_ALL_ACTIONS_SUCCESS';
export const GET_ALL_ACTIONS_FAIL = 'GET_ALL_ACTIONS_FAIL';
export const SELECT_CATEGORY = 'SELECT_CATEGORY';
export const UPDATE_ACTION_TOTALS = 'UPDATE_ACTION_TOTALS';
export const UPDATE_ACTION_STATUS = 'UPDATE_ACTION_STATUS';
export const UPDATE_ACTION_STATUS_SUCCESS = 'UPDATE_ACTION_STATUS_SUCCESS';
export const UPDATE_ACTION_STATUS_FAIL = 'UPDATE_ACTION_STATUS_FAIL';
export const RESET_ACTIONS = 'RESET_ACTIONS';

export interface GetAllActions {
  type: typeof GET_ALL_ACTIONS;
}

export interface GetAllActionsSuccess {
  type: typeof GET_ALL_ACTIONS_SUCCESS;
  payload: Action[];
}

export interface GetAllActionsFail {
  type: typeof GET_ALL_ACTIONS_FAIL;
  payload: any;
}

export interface SelectCategory {
  type: typeof SELECT_CATEGORY;
}

export interface UpdateActionTotals {
  type: typeof UPDATE_ACTION_TOTALS;
  payload: ActionOverview;
}

export interface UpdateActionStatus {
  type: typeof UPDATE_ACTION_STATUS;
}

export interface UpdateActionStatusSuccess {
  type: typeof UPDATE_ACTION_STATUS_SUCCESS;
}

export interface UpdateActionStatusFail {
  type: typeof UPDATE_ACTION_STATUS_FAIL;
  payload: any;
}

export interface ResetActions {
  type: typeof RESET_ACTIONS;
  payload: any;
}

export type ActionDispatchTypes =
  | GetAllActions
  | GetAllActionsSuccess
  | GetAllActionsFail
  | SelectCategory
  | UpdateActionTotals
  | UpdateActionStatus
  | UpdateActionStatusSuccess
  | UpdateActionStatusFail
  | ResetActions;
