import { Box, styled } from '@mui/material';

export const LoginPageWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100vw',
  height: '100vh',
  background: '#f0f9f7',
}));

export const LoginPageContainer = styled(Box)(() => ({
  display: 'flex',
  height: 96,
  margin: '44px auto',
}));
