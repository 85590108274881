import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as uuid from 'uuid';

import { CustomButton } from '@/components/Atoms';
import { CarbonUploadContainer } from '@/components/Templates';

import {
  clearCarbonParkDetail,
  getCarbonParkDetail,
  getCarbonParkList,
  uploadFile,
} from '@/redux/reducers/carbon/actions';
import { useAppDispatch, useAppSelector } from '@/redux/stores/hooks';

import {
  Container,
  NavButtonsContainer,
  Title,
} from './CarbonUploadPage.styles';

import type { ChangeEvent } from 'react';

export const CarbonUploadPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { carbonParkList } = useAppSelector(store => store.carbon);
  const { t } = useTranslation();

  const [reportName, setReportName] = useState<string>('');
  const [filename, setFilename] = useState<string>('');
  const [uploading, setUploading] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);
  const [activePark, setActivePark] = useState<string>('');

  useEffect(() => {
    dispatch(clearCarbonParkDetail());
    dispatch(getCarbonParkList());
  }, [dispatch]);

  const handleOnParkChange = (parkUuid: string) => {
    setActivePark(parkUuid);
    dispatch(getCarbonParkDetail({ parkUuid }));
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0].name !== file?.name) {
      const newFile = e.target.files[0];
      setFile(newFile);
      setFilename(newFile.name);
    }
  };

  const handleFileReset = () => {
    setFile(null);
    setFilename('');
  };

  const handleConfirmUpload = async () => {
    setUploading(true);
    dispatch(
      uploadFile({
        reportName: reportName,
        fileName: filename,
        reportUuid: uuid.v4(),
        parkUuid: activePark,
        file: file,
      }),
    );
    navigate('/carbon-coach');
  };

  return (
    <Container>
      <Title className="headline1-desktop">
        {t('pages.newDigitalSolutionCarbon')}
      </Title>
      <CarbonUploadContainer
        reportName={reportName}
        setReportName={setReportName}
        activePark={activePark}
        setPark={handleOnParkChange}
        file={file}
        handleFileChange={handleFileChange}
        handleFileReset={handleFileReset}
        allParkList={carbonParkList}
      />
      <NavButtonsContainer>
        <CustomButton
          width={'200px'}
          level={'Secondary'}
          iconType={'None'}
          text={t('pages.cancel')}
          disabled={false}
          onClickAction={() => navigate('/carbon-coach')}
          isLoading={false}
        />
        <CustomButton
          width={'200px'}
          level={'Primary'}
          iconType={'None'}
          text={t('pages.confirm')}
          disabled={reportName === '' || activePark === ''}
          onClickAction={() => handleConfirmUpload()}
          isLoading={uploading}
        />
      </NavButtonsContainer>
    </Container>
  );
};
