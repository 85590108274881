import { Box, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { QuestionCard } from '@/components/Organisms';

import type { Category, CategoryQuestion } from '@/redux/reducers/gr491/state';

interface FamilyDetailsContainerProps {
  priorities: string[];
  familyID: string;
  familyItem: Category;
  onClickAction?: any;
}

export const FamilyDetailsContainer = ({
  priorities,
  familyID,
  familyItem,
}: FamilyDetailsContainerProps) => {
  const { t } = useTranslation();
  return (
    <FamilyContainer>
      <FamilyTitle>
        <Typography variant="h3">{familyItem.category}</Typography>
        <FamilyLine />
      </FamilyTitle>
      <FamilyQuestionsContainer>
        {familyItem.categoryQuestionList.filter(
          (questionItem: CategoryQuestion) =>
            priorities.length !== 0
              ? priorities.includes(questionItem.priority.toLowerCase())
              : true,
        ).length == 0 ? (
          <Typography variant="body2">{t('templates.noQuestions')}</Typography>
        ) : (
          familyItem.categoryQuestionList
            .filter((questionItem: CategoryQuestion) =>
              priorities.length !== 0
                ? priorities.includes(questionItem.priority.toLowerCase())
                : true,
            )
            .map((questionItem: CategoryQuestion) => (
              <QuestionCard
                key={questionItem.uuid}
                familyID={familyID}
                item={questionItem}
              />
            ))
        )}
      </FamilyQuestionsContainer>
    </FamilyContainer>
  );
};

const FamilyContainer = styled(Box)(() => ({
  display: 'grid',
  gridTemplateRows: '[top] 76px [center] auto [bottom]',
  width: '100%',
  height: '100%',
}));

const FamilyTitle = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gridRow: 'top / center',
  width: '100%',
  gap: '16px',
}));

const FamilyLine = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignSelf: 'flex-start',
  width: '100%',
  height: '50%',
  borderBottom: `1px solid ${theme.palette.ui.stroke}`,
}));

const FamilyQuestionsContainer = styled(Box)(() => ({
  display: 'grid',
  gridTemplateColumns: '[first] 1fr [second] 1fr [last-second] 1fr [last]',
  gap: '24px',
}));
