import { Box, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { CarbonTableButtons } from '@/components/Atoms';
import { CarbonTable } from '@/components/Organisms';

import { CarbonColumnData } from '@/const/CarbonTableColumnItems';

import type { CarbonReport } from '@/redux/reducers/carbon/state';

export const CarbonTableContainer = ({
  reports,
  handleRefresh,
}: {
  reports: CarbonReport[];
  handleRefresh: () => void;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <TableContainer>
      <TableContainerTitle>
        <TableContainerBox>
          <Typography variant="h3">
            {t('templates.digitalSolutionCarbon')}
          </Typography>
        </TableContainerBox>
        <Box>
          <CarbonTableButtons type={'Refresh'} onClickAction={handleRefresh} />
        </Box>
        <Box>
          <CarbonTableButtons
            type={'New Report'}
            onClickAction={() => navigate('/carbon-coach/new-report')}
          />
        </Box>
      </TableContainerTitle>
      <CarbonTable columns={CarbonColumnData} rows={reports} />
    </TableContainer>
  );
};

const TableContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  boxSizing: 'border-box',
  width: '100%',
  height: 'fit-content',
  borderRadius: '8px',
  background: theme.palette.background.default,
}));

const TableContainerTitle = styled(Box)(() => ({
  display: 'grid',
  gridTemplateColumns: 'auto 65px 85px',
  boxSizing: 'border-box',
  alignItems: 'center',
}));

const TableContainerBox = styled(Box)(() => ({
  boxSizing: 'border-box',
  padding: '24px',
}));
