import type { ColumnType } from '@/components/Organisms/CarbonTable/CarbonTable';

export const CarbonColumnData: readonly ColumnType[] = [
  {
    id: 'reportName',
    label: 'Report Name',
    labelKey: 'constants.reportName',
    minWidth: 170,
    align: 'left',
  },
  {
    id: 'parkName',
    label: 'Park Name',
    labelKey: 'constants.parkName',
    minWidth: 170,
    align: 'right',
  },
  {
    id: 'creationDate',
    label: 'Created Date',
    labelKey: 'constants.createdDate',
    minWidth: 170,
    align: 'right',
  },
  {
    id: 'reportUuid',
    label: 'Report UUID',
    labelKey: 'constants.reportUuid',
    minWidth: 170,
    align: 'right',
  },
  {
    id: 'reportStatus',
    label: 'Status',
    labelKey: 'constants.status',
    minWidth: 170,
    align: 'right',
  },
];
