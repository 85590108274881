import { styled } from '@mui/material';

export const Container = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: 24,
  padding: 24,
  width: '100%',
  height: '100%',
  background: theme.palette.common.white,
}));
