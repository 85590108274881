import { Box, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import {
  CustomButton as LoginButton,
  CustomButton as SignupButton,
} from '@/components/Atoms';
import { PasswordTextField, UsernameTextField } from '@/components/Molecules';
import { LanguageSelectorContainer } from '@/components/Templates';

export const LoginContainer = ({
  email,
  password,
  handleEmailChange,
  handlePasswordChange,
  onSubmit,
}: {
  email: string;
  password: string;
  handleEmailChange: (email: string) => void;
  handlePasswordChange: (password: string) => void;
  onSubmit: (event: any) => void;
}) => {
  const { t } = useTranslation();
  return (
    <LogContainer>
      <LoginIntro>
        <Typography variant="h2">{t('templates.getStarted')}</Typography>
        <Typography
          variant="body1"
          sx={{ color: theme => theme.palette.text.secondary }}
        >
          {t('templates.welcome')}
        </Typography>
      </LoginIntro>
      <LoginTextFields>
        <UsernameTextField
          email={email}
          onChangeAction={(event: any) => handleEmailChange(event.target.value)}
          onKeyDownAction={(event: any) => {
            if (event.key === 'Enter') {
              onSubmit(event);
            }
          }}
        />
        <PasswordTextField
          password={password}
          onChangeAction={(event: any) =>
            handlePasswordChange(event.target.value)
          }
          onKeyDownAction={(event: any) => {
            if (event.key === 'Enter') {
              onSubmit(event);
            }
          }}
        />
      </LoginTextFields>
      <LoginSettings>
        <LoginSettingsRememberMe>{/*Remember Me*/}</LoginSettingsRememberMe>
        <LoginSettingsForgotPassword>
          <Typography variant="body2">
            {t('templates.forgotPassword')}
          </Typography>
        </LoginSettingsForgotPassword>
      </LoginSettings>
      <LoginButtonWrapper>
        <LoginButton
          text={t('templates.login')}
          level={'Primary'}
          width={'200px'}
          disabled={email === '' || password === '' ? true : false}
          onClickAction={(event: any) => onSubmit(event)}
        />
      </LoginButtonWrapper>
      <LoginContentDivider />
      <LoginSignup>
        <Typography variant="body1">
          {t('templates.dontHaveAccount')}
        </Typography>
        <SignupButton
          text={t('templates.signUp')}
          level={'Secondary'}
          width={'200px'}
          onClickAction={() => null}
        />
        <LanguageSelectorContainer />
      </LoginSignup>
    </LogContainer>
  );
};

const LogContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '32px 24px',
  gap: '24px',
  width: '100%',
  height: '100%',
  maxWidth: '418px',
  maxHeight: '630px',
  background: theme.palette.background.default,
  borderRadius: '10px',
}));

const LoginIntro = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  width: '100%',
}));

const LoginTextFields = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: '100%',
  height: '100%',
  maxHeight: '136px',
}));

const LoginSettings = styled(Box)(() => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  width: '100%',
}));

const LoginSettingsRememberMe = styled(Box)(() => ({
  display: 'flex',
  justifySelf: 'flex-start',
}));

const LoginSettingsForgotPassword = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifySelf: 'flex-end',
  color: theme.palette.ui.primaryGreen.main,
}));

const LoginButtonWrapper = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
}));

const LoginContentDivider = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignSelf: 'center',
  width: '100%',
  margin: '16px',
  border: `1px solid ${theme.palette.ui.stroke}`,
}));

const LoginSignup = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '24px',
  width: '100%',
}));
