import { Check, Close } from '@mui/icons-material';
import { Box, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import type { AnswerOption } from '@/redux/reducers/gr491/state';

interface QuizQuestionFeedbackContainerProps {
  correct: boolean;
  feedbackItem: AnswerOption | null;
  isCompleted: boolean;
}

export const QuizQuestionFeedbackContainer = ({
  correct,
  feedbackItem,
  isCompleted,
}: QuizQuestionFeedbackContainerProps) => {
  const percentage: number = Math.floor(Math.random() * 100);
  const { t } = useTranslation();
  return (
    <FeedbackWrapper>
      {isCompleted && (
        <FeedbackContainer
          sx={{
            background: correct
              ? theme => theme.palette.ui.primaryGreen.white
              : '#ffbaba	',
          }}
        >
          {correct ? (
            <FeedbackHeader
              sx={{ color: theme => theme.palette.ui.primaryGreen.main }}
            >
              <Check />
              <Typography variant="subtitle1">
                {`${t('templates.correctYouDidBetter')} ${percentage}% ${t(
                  'templates.ofPlayers',
                )}`}
              </Typography>
            </FeedbackHeader>
          ) : (
            <FeedbackHeader sx={{ color: theme => theme.palette.error.main }}>
              <Close />
              <Typography variant="subtitle1">
                {t('templates.wrongTryAgain')}
              </Typography>
            </FeedbackHeader>
          )}
          {feedbackItem?.answer && (
            <FeedbackContent>
              <Typography variant="body1">
                {`${t(
                  'templates.theCorrectAnswer',
                )} "${feedbackItem?.answer}"!`}
              </Typography>
              <Typography variant="body2">{feedbackItem?.message}</Typography>
            </FeedbackContent>
          )}
        </FeedbackContainer>
      )}
    </FeedbackWrapper>
  );
};

const FeedbackWrapper = styled(Box)(() => ({
  display: 'flex',
  boxSizing: 'border-box',
  width: '100%',
  height: '100%',
}));

const FeedbackContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  boxSizing: 'border-box',
  width: '100%',
  height: 'fit-content',
  gap: '16px',
  padding: '24px',
  borderRadius: '8px',
}));

const FeedbackHeader = styled(Box)(() => ({
  display: 'flex',
  boxSizing: 'border-box',
  gap: '8px',
  height: '30px',
  width: '100%',
}));

const FeedbackContent = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  boxSizing: 'border-box',
  textAlign: 'justify',
  gap: '8px',
  padding: '0px 4px',
  height: '100%',
  width: '100%',
}));
