import { Box, styled } from '@mui/material';
import ReactEcharts from 'echarts-for-react';

import { TreeMapChartToolTip } from '@/components/Atoms';

import type { EChartsOption } from 'echarts-for-react';

interface TreeMapProps {
  chartData: { value: number; name: string }[];
}

export const TreeMap = ({ chartData }: TreeMapProps) => {
  const optionTreemap: EChartsOption = {
    tooltip: {
      formatter: (info: any) => {
        const tooltip = TreeMapChartToolTip({ data: info.data });
        return tooltip;
      },
    },
    series: [
      {
        type: 'treemap',
        zoom: false,
        roam: false,
        label: {
          show: true,
          formatter: '{b}',
          color: 'black',
        },
        data: chartData,
      },
    ],
  };
  return (
    <TreeMapWrapper>
      <ReactEcharts
        option={optionTreemap}
        lazyUpdate={true}
        style={{ width: '100%', height: '100%' }}
      />
    </TreeMapWrapper>
  );
};

const TreeMapWrapper = styled(Box)`
  display: flex;
  box-sizing: border-box;
  width: 100vw;
  max-width: 1000px;
  height: 100%;
  min-height: 500px;
`;
