import { Box, styled, Typography } from '@mui/material';

import { SanKeyChart } from '@/components/Molecules';

import { SanKeyParameter } from '@/models/chart';

export const SanKeyChartContainer = ({
  parameters,
  showTitle = false,
}: {
  parameters: SanKeyParameter;
  showTitle?: boolean;
}) => {
  return (
    <ChartContainer>
      <StyledLabelLeft>{parameters.legend[0] || ''}</StyledLabelLeft>
      <SanKeyChart parameters={parameters} showTitle={showTitle} />
      <StyledLabelRight>{parameters.legend[1] || ''}</StyledLabelRight>
    </ChartContainer>
  );
};

const ChartContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  padding: 0,
  position: 'relative',
  height: '100%',
}));

const StyledLabel = styled(Typography)(() => ({
  position: 'absolute',
  transform: 'rotate(-90deg)',
  top: '45%',
}));

const StyledLabelLeft = styled(StyledLabel)(() => ({
  left: '-50px',
}));

const StyledLabelRight = styled(StyledLabel)(() => ({
  right: '-4px',
}));
