import { useEffect, useState } from 'react';
import {
  Avatar,
  ChatContainer,
  ConversationHeader,
  Message,
  MessageInput,
  MessageList,
  TypingIndicator,
} from '@chatscope/chat-ui-kit-react';
import { KeyboardArrowDown } from '@mui/icons-material';
import { Box, IconButton, styled, Typography } from '@mui/material';

import { ChatbotAvatar, ChatbotIcon } from '@/assets/images';

import './Chatbox.scss';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';

import { useTranslation } from 'react-i18next';

interface ChatboxProps {
  response: string;
  isLoading: boolean;
  onClose: () => void;
  onSubmit: (query: string) => void;
}

interface Message {
  text: string;
  direction: 'incoming' | 'outgoing';
}

export const Chatbox: React.FC<ChatboxProps> = ({
  response,
  isLoading,
  onClose,
  onSubmit,
}) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (response && !isLoading) {
      setMessages([
        ...messages,
        {
          text: response,
          direction: 'incoming',
        },
      ]);
    }
  }, [response]);

  const handleInputOnSubmit = (userInput: string) => {
    setMessages([
      ...messages,
      {
        text: userInput,
        direction: 'outgoing',
      },
    ]);
    onSubmit(userInput);
  };

  const renderMessages = () => {
    if (messages.length > 0) {
      return messages.map(({ text, direction }) => (
        <Message
          className="message"
          model={{
            message: text,
            direction,
            position: 'single',
          }}
        >
          {direction === 'incoming' && (
            <Avatar
              src={ChatbotAvatar}
              name="ImpactBot"
              size="sm"
              className="chatbot-avatar"
            />
          )}
        </Message>
      ));
    }
    return (
      <MessageList.Content className="empty-message">
        <EmptyMessageAvatarContainer>
          <Avatar src={ChatbotIcon} className="avatar" />
        </EmptyMessageAvatarContainer>
        <Typography variant="subtitle2">
          {t('organisms.welcomeToChatbot')}
          <br />
          {t('organisms.typeYourQuestion')}
        </Typography>
      </MessageList.Content>
    );
  };

  return (
    <ChatBoxContainer>
      <ChatContainer>
        <ConversationHeader className="conversation-header">
          <Avatar
            src={ChatbotIcon}
            name="ImpactBot"
            size="sm"
            className="icon"
          />
          <ConversationHeader.Content
            userName="ImpactBot"
            className="content"
          />
          <ConversationHeader.Actions>
            <IconButton
              style={{ color: 'var(--neutral_white)' }}
              onClick={onClose}
            >
              <KeyboardArrowDown fontSize="inherit" />
            </IconButton>
          </ConversationHeader.Actions>
        </ConversationHeader>
        <MessageList
          typingIndicator={
            isLoading && <TypingIndicator className="typing-indicator" />
          }
        >
          {renderMessages()}
        </MessageList>
        <MessageInput
          autoFocus
          attachButton={false}
          placeholder={t('organisms.askSomething')}
          onSend={handleInputOnSubmit}
          className="message-input"
        />
      </ChatContainer>
    </ChatBoxContainer>
  );
};

const ChatBoxContainer = styled(Box)(({ theme }) => ({
  background: theme.palette.ui.neutral.white,
  position: 'relative',
  height: '516px',
  width: '376px',
  filter: 'drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.25))',
  zIndex: '120',
}));

const EmptyMessageAvatarContainer = styled(Box)(({ theme }) => ({
  background: theme.palette.ui.primaryGreen.main,
  height: '100px',
  width: '100px',
  borderRadius: '100px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));
