import { Box, styled } from '@mui//material';

import ImpactTrackerLoaderAnimation from '@/assets/images/ImpactTrackerLoaderAnimation.gif';

export const LoaderAnimation = () => {
  return (
    <LoaderAnimationContainer>
      <StyledImage src={ImpactTrackerLoaderAnimation} alt="logo" />
    </LoaderAnimationContainer>
  );
};

const LoaderAnimationContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'fixed',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  zIndex: 10000000000,
  background: theme.palette.ui.neutral.white,
}));

const StyledImage = styled('img')({
  width: '100%',
});
