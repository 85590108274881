import { Box, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { CopyButton } from '@/components/Atoms';

import { formatDate } from '@/utils/general';

import type { CarbonReportDetail } from '@/redux/reducers/carbon/state';

interface CarbonReportInfoContainerProps {
  reportDetail: CarbonReportDetail;
}

export const CarbonReportInfoContainer = ({
  reportDetail,
}: CarbonReportInfoContainerProps) => {
  const { t } = useTranslation();
  return (
    <ReportInfoContainer>
      <ReportInfoTitle>
        <Typography variant="h3">{t('templates.reportInfo')}</Typography>
      </ReportInfoTitle>
      <ReportInfoItemTitle sx={{ gridColumn: 'first / second' }}>
        <Typography variant="body2">{t('templates.reportName')}</Typography>
      </ReportInfoItemTitle>
      <ReportInfoItemValueCopy sx={{ gridColumn: 'first / second' }}>
        <Typography variant="body1">
          {reportDetail?.report?.reportName
            ? reportDetail?.report?.reportName
            : t('templates.loading')}
        </Typography>
        <CopyButton value={reportDetail?.report?.reportName} />
      </ReportInfoItemValueCopy>
      <ReportInfoItemTitle sx={{ gridColumn: 'second / middle' }}>
        <Typography variant="body2">{t('templates.createdDate')}</Typography>
      </ReportInfoItemTitle>
      <ReportInfoItemValue sx={{ gridColumn: 'second / middle' }}>
        <Typography variant="body1">
          {reportDetail?.report?.creationDate
            ? formatDate(reportDetail?.report?.creationDate)
            : t('templates.loading')}
        </Typography>
      </ReportInfoItemValue>
      <ReportInfoItemTitle sx={{ gridColumn: 'middle / second-last' }}>
        <Typography variant="body2">{t('templates.parkName')}</Typography>
      </ReportInfoItemTitle>
      <ReportInfoItemValue sx={{ gridColumn: 'middle / second-last' }}>
        <Typography variant="body1">
          {reportDetail?.report?.parkName
            ? reportDetail?.report?.parkName
            : t('templates.loading')}
        </Typography>
      </ReportInfoItemValue>
      <ReportInfoItemTitle sx={{ gridColumn: 'second-last / last' }}>
        <Typography variant="body2">{t('templates.reportUuid')}</Typography>
      </ReportInfoItemTitle>
      <ReportInfoItemValueCopy sx={{ gridColumn: 'second-last / last' }}>
        <Typography variant="body1">
          {reportDetail?.report?.reportUuid
            ? reportDetail?.report?.reportUuid
            : t('templates.loading')}
        </Typography>
        <CopyButton value={reportDetail?.report?.reportUuid} />
      </ReportInfoItemValueCopy>
    </ReportInfoContainer>
  );
};

const ReportInfoContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns:
    '[first] 1fr [second] 1fr [middle] 1fr [second-last] 1fr [last]',
  gridTemplateRows: '[top] 50px [center] 30px [divider] 1fr [bottom]',
  width: '100%',
  padding: '24px',
  background: theme.palette.ui.neutral.white,
  boxSizing: 'border-box',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.12)',
  borderRadius: '8px',
}));

const ReportInfoTitle = styled(Box)(() => ({
  marginBottom: '24px',
  gridColumn: 'first / last',
  gridRow: 'top / center',
}));

const ReportInfoItemTitle = styled(Box)(({ theme }) => ({
  color: theme.palette.ui.neutral.greyDark,
  gridRow: 'center / divider',
}));

const ReportInfoItemValueCopy = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '5fr 1fr',
  gridRow: 'divider / bottom',
  width: '90%',
  color: theme.palette.ui.neutral.black,
  justifyContent: 'center',
}));

const ReportInfoItemValue = styled(Box)(({ theme }) => ({
  gridRow: 'divider / bottom',
  width: '90%',
  color: theme.palette.ui.neutral.black,
}));
