import { useEffect, useState } from 'react';
import ReactEcharts from 'echarts-for-react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { theme } from '@/theme/theme';

import { renderTagColor } from '@/utils/general';

import { getLPQuizResultOverview } from '@/redux/reducers/quiz/actions';
import { quizResultOverviewInitialState } from '@/redux/reducers/quiz/state';
import { useAppDispatch, useAppSelector } from '@/redux/stores/hooks';

import {
  Card,
  CategoryContainer,
  ChartContainer,
  Container,
  Header,
  ScoreCard,
  Tag,
} from './QuizResultPage.styles';

import type { QuizResult } from '@/redux/reducers/quiz/state';
import type { EChartsOption } from 'echarts-for-react';

export const QuizResultPage = () => {
  const dispatch = useAppDispatch();
  const { quizID } = useParams();
  const [correctAnswers, setCorrectAnswers] = useState<number>(0);
  const [incorrectAnswers, setIncorrectAnswers] = useState<number>(1);
  const [totalPercentage, setTotalPercentage] = useState<number>(0);
  const quizResultOverview = useAppSelector(
    store => store.quiz.quizResultOverview,
  );
  const { t } = useTranslation();

  useEffect(() => {
    if (quizID) {
      dispatch(getLPQuizResultOverview({ quizID: quizID }));
    }
  }, [dispatch, quizID]);

  useEffect(() => {
    if (quizResultOverview !== quizResultOverviewInitialState) {
      setCorrectAnswers(
        quizResultOverview.reduce(
          (
            initialValue: number,
            item: {
              category: string;
              answersCorrect: number;
              questionsTotal: number;
            },
          ) => (initialValue += item.answersCorrect),
          0,
        ),
      );
      setIncorrectAnswers(
        quizResultOverview.reduce(
          (
            initialValue: number,
            item: {
              category: string;
              answersCorrect: number;
              questionsTotal: number;
            },
          ) => (initialValue += item.questionsTotal - item.answersCorrect),
          0,
        ),
      );
    }
  }, [quizResultOverview]);

  useEffect(() => {
    setTotalPercentage(
      Math.round((correctAnswers * 100) / (correctAnswers + incorrectAnswers)),
    );
  }, [correctAnswers, incorrectAnswers]);

  const pieParameters: EChartsOption = {
    tooltip: {
      trigger: 'item',
    },
    series: [
      {
        name: '',
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10,
          borderColor: '#fff',
          borderWidth: 2,
        },
        label: {
          show: false,
          position: 'center',
        },
        emphasis: {
          label: {
            show: true,
            fontSize: 20,
            fontWeight: 'bold',
          },
        },
        labelLine: {
          show: false,
        },
        color: [theme.palette.ui.palo.green, theme.palette.error.main],
        data: [
          { value: correctAnswers, name: 'Correct' },
          { value: incorrectAnswers, name: 'Incorrect' },
        ],
      },
    ],
  };

  return (
    <Container>
      <Card>
        <Header>
          <div className="headline2-desktop">{t('pages.yourScore')}</div>
          <div className="body1-desktop">{t('pages.youAreQuiteFamiliar')}</div>
        </Header>
        <ChartContainer>
          <ReactEcharts
            option={pieParameters}
            lazyUpdate={true}
            style={{ width: '100%' }}
          />
          <div className="body2-desktop">{`${totalPercentage}% ${t(
            'pages.ofQuestionCorrectly',
          )}`}</div>
        </ChartContainer>
        <ScoreCard>
          <div className="headline3-desktop">
            {t('pages.answeredCorrectlyByCategory')}
          </div>
          {quizResultOverview.map((categoryItem: QuizResult, idx: number) => (
            <CategoryContainer key={idx}>
              <Tag
                className="body2-desktop"
                style={{
                  background: renderTagColor(categoryItem.category),
                }}
              >
                {categoryItem.category}
              </Tag>
              <div className="body2-desktop">{`${
                categoryItem.answersCorrect
              } ${t('pages.outOf')} ${categoryItem.questionsTotal}`}</div>
            </CategoryContainer>
          ))}
        </ScoreCard>
      </Card>
    </Container>
  );
};
