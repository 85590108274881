import type {
  Quiz,
  QuizCategory,
  QuizQuestion,
  QuizQuestionUpdateStatus,
  QuizResult,
} from './state';

export const GET_LP_QUIZ_LIST = 'GET_LP_QUIZ_LIST';
export const GET_LP_QUIZ_LIST_SUCCESS = 'GET_LP_QUIZ_LIST_SUCCESS';
export const GET_LP_QUIZ_LIST_FAIL = 'GET_LP_QUIZ_LIST_FAIL';
export const GET_LP_QUIZ_QUESTION = 'GET_LP_QUIZ_QUESTION';
export const GET_LP_QUIZ_QUESTION_SUCCESS = 'GET_LP_QUIZ_QUESTION_SUCCESS';
export const GET_LP_QUIZ_QUESTION_FAIL = 'GET_LP_QUIZ_QUESTION_FAIL';
export const UPDATE_LP_QUIZ_QUESTION = 'UPDATE_LP_QUIZ_QUESTION';
export const UPDATE_LP_QUIZ_QUESTION_SUCCESS =
  'UPDATE_LP_QUIZ_QUESTION_SUCCESS';
export const UPDATE_LP_QUIZ_QUESTION_FAIL = 'UPDATE_LP_QUIZ_QUESTION_FAIL';
export const GET_LP_QUIZ_RESULT_OVERVIEW = 'GET_LP_QUIZ_RESULT_OVERVIEW';
export const GET_LP_QUIZ_RESULT_OVERVIEW_SUCCESS =
  'GET_LP_QUIZ_RESULT_OVERVIEW_SUCCESS';
export const GET_LP_QUIZ_RESULT_OVERVIEW_FAIL =
  'GET_LP_QUIZ_RESULT_OVERVIEW_FAIL';
export const GENERATE_QUIZ_RECOMMENDATION = 'GENERATE_QUIZ_RECOMMENDATION';
export const GENERATE_QUIZ_RECOMMENDATION_SUCCESS =
  'GENERATE_QUIZ_RECOMMENDATION_SUCCESS';
export const GENERATE_QUIZ_RECOMMENDATION_FAIL =
  'GENERATE_QUIZ_RECOMMENDATION_FAIL';
export const GET_LP_QUIZ_CATRGORY = 'GET_LP_QUIZ_CATRGORY';
export const GET_LP_QUIZ_CATRGORY_SUCCESS = 'GET_LP_QUIZ_CATRGORY_SUCCESS';
export const GET_LP_QUIZ_CATRGORY_FAIL = 'GET_LP_QUIZ_CATRGORY_FAIL';
export const RESET_QUIZ = 'RESET_QUIZ';

export interface GetLPQuizList {
  type: typeof GET_LP_QUIZ_LIST;
  payload: any;
}

export interface GetLPQuizListSuccess {
  type: typeof GET_LP_QUIZ_LIST_SUCCESS;
  payload: Quiz[];
}

export interface GetLPQuizListFail {
  type: typeof GET_LP_QUIZ_LIST_FAIL;
  payload: any;
}

export interface GetLPQuizQuestion {
  type: typeof GET_LP_QUIZ_QUESTION;
  payload: any;
}

export interface GetLPQuizQuestionSuccess {
  type: typeof GET_LP_QUIZ_QUESTION_SUCCESS;
  payload: QuizQuestion;
}

export interface GetLPQuizQuestionFail {
  type: typeof GET_LP_QUIZ_QUESTION_FAIL;
  payload: any;
}

export interface UpdateLPQuizQuestion {
  type: typeof UPDATE_LP_QUIZ_QUESTION;
  payload: any;
}

export interface UpdateLPQuizQuestionSuccess {
  type: typeof UPDATE_LP_QUIZ_QUESTION_SUCCESS;
  payload: QuizQuestionUpdateStatus;
}

export interface UpdateLPQuizQuestionFail {
  type: typeof UPDATE_LP_QUIZ_QUESTION_FAIL;
  payload: any;
}

export interface GetLPQuizResultOverview {
  type: typeof GET_LP_QUIZ_RESULT_OVERVIEW;
  payload: any;
}

export interface GetLPQuizResultOverviewSuccess {
  type: typeof GET_LP_QUIZ_RESULT_OVERVIEW_SUCCESS;
  payload: QuizResult[];
}

export interface GetLPQuizResultOverviewFail {
  type: typeof GET_LP_QUIZ_RESULT_OVERVIEW_FAIL;
  payload: any;
}

export interface GenerateQuizRecommendation {
  type: typeof GENERATE_QUIZ_RECOMMENDATION;
  payload: any;
}

export interface GenerateQuizRecommendationSuccess {
  type: typeof GENERATE_QUIZ_RECOMMENDATION_SUCCESS;
  payload: QuizResult[];
}

export interface GenerateQuizRecommendationFail {
  type: typeof GENERATE_QUIZ_RECOMMENDATION_FAIL;
  payload: any;
}

export interface GetLPQuizCategory {
  type: typeof GET_LP_QUIZ_CATRGORY;
  payload: any;
}

export interface GetLPQuizCategorySuccess {
  type: typeof GET_LP_QUIZ_CATRGORY_SUCCESS;
  payload: QuizCategory;
}

export interface GetLPQuizCategoryFail {
  type: typeof GET_LP_QUIZ_CATRGORY_FAIL;
  payload: any;
}

export interface ResetQuiz {
  type: typeof RESET_QUIZ;
  payload: any;
}

export type QuizDispatchTypes =
  | GetLPQuizList
  | GetLPQuizListSuccess
  | GetLPQuizListFail
  | GetLPQuizQuestion
  | GetLPQuizQuestionSuccess
  | GetLPQuizQuestionFail
  | UpdateLPQuizQuestion
  | UpdateLPQuizQuestionSuccess
  | UpdateLPQuizQuestionFail
  | GetLPQuizResultOverview
  | GetLPQuizResultOverviewSuccess
  | GetLPQuizResultOverviewFail
  | GenerateQuizRecommendation
  | GenerateQuizRecommendationSuccess
  | GenerateQuizRecommendationFail
  | GetLPQuizCategory
  | GetLPQuizCategorySuccess
  | GetLPQuizCategoryFail
  | ResetQuiz;
