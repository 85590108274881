import { Box, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { SideMenuTabs } from '@/components/Molecules';

import { SideMenuItems } from '@/const';

import { useAppSelector } from '@/redux/stores/hooks';

export const SideMenuContainer = () => {
  const userInfo = useAppSelector(store => store.auth.userInfo);
  const { t } = useTranslation();
  return (
    <MenuWrapper>
      <MenuContainer>
        <MenuIntro>
          <Typography variant="caption">{t('templates.company')}</Typography>
          <Typography variant="subtitle2">{userInfo.organization}</Typography>
        </MenuIntro>
        <SideMenuTabs data={SideMenuItems} />
      </MenuContainer>
    </MenuWrapper>
  );
};

const MenuWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  position: 'fixed',
  alignItems: 'flex-start',
  boxSizing: 'border-box',
  width: '245px',
  height: 'calc(100% - 56px)',
  left: '0px',
  top: '56px',
  overflowY: 'auto',
  background: theme.palette.ui.neutral.white,
  borderRight: `1px solid ${theme.palette.ui.stroke}`,
}));

const MenuContainer = styled(Box)(() => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
  margin: '0px auto',
}));

const MenuIntro = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxSizing: 'border-box',
  margin: '12px 8px 16px',
  gap: '8px',
  width: '208px',
  height: '76px',
  background: theme.palette.ui.neutral.white,
  borderBottom: `1px solid ${theme.palette.ui.stroke}`,
}));
