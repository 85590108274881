import { ContentCopyOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import copy from 'copy-to-clipboard';

export const CopyButton = ({ value }: { value: string }) => {
  return (
    <StyledIconButton
      onClick={() => {
        copy(value);
      }}
    >
      <ContentCopyOutlined fontSize="small" />
    </StyledIconButton>
  );
};

const StyledIconButton = styled(IconButton)`
  display: flex;
  justify-self: flex-start;
  align-self: flex-start;
  border-radius: 10px;
  padding: 15px;
  margin: -15px;
`;
