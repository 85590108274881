import { Box, styled } from '@mui/material';

export const StyledTitle = styled(Box)`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  gap: 4px;

  div:nth-of-type(1) {
    color: var(--neutral_black);
  }
  div:nth-of-type(2) {
    color: var(--neutral_grey_dark);
  }
`;

export const ResultDetailsPageWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  boxSizing: 'border-box',
  width: '100%',
  height: '100%',
  right: '0',
  bottom: '0',
  padding: '24px',
  background: theme.palette.ui.neutral.grey,
}));

export const ResultDetailsPageContainer = styled(Box)(() => ({
  display: 'grid',
  gridTemplateRows: '196px 360px auto',
  width: '100%',
  height: '100%',
  gap: '24px',
  boxSizing: 'border-box',
  overflowY: 'scroll',
}));

export const ResultDetailsPageIntroContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
}));

export const ResultDetailsPageIntroHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  boxSizing: 'border-box',
  padding: '16px',
  width: '100%',
  height: '100%',
  background: theme.palette.ui.neutral.white,
  borderRadius: '8px',
  gap: '16px',
}));

export const ResultDetailsPageIntroTaskList = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  height: 'fit-content',
  boxSizing: 'border-box',
  padding: '8px 16px',
  background: theme.palette.ui.primaryGreen.white,
  borderRadius: '8px',
}));

export const ResultDetailsPageSummaryRow = styled(Box)(() => ({
  display: 'flex',
  paddingTop: '24px',
  gap: '24px',
}));

export const ResultDetailsPageImpactChartsContainer = styled(Box)(() => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: '24px',
  width: '100%',
  height: '100%',
  boxSizing: 'border-box',
}));

export const ResultDetailsPageImpactSummaryContainer = styled(Box)(
  ({ theme }) => ({
    flex: 1,
    background: theme.palette.ui.neutral.white,
    borderRadius: '8px',
    boxSizing: 'border-box',
    padding: '24px',
    height: '100%',
  }),
);

export const ImpactRadarCardContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  boxSizing: 'border-box',
  height: '400px',
  padding: '24px',
  background: theme.palette.ui.neutral.white,
  borderRadius: '8px',
}));
