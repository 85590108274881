import { useEffect, useState } from 'react';
import { Done, PriorityHighOutlined } from '@mui/icons-material';
import {
  Box,
  ButtonBase,
  Divider,
  styled,
  Tooltip,
  tooltipClasses,
  Typography,
} from '@mui/material';

import { convertUppercase } from '@/utils/general';

import type { Family } from '@/redux/reducers/gr491/state';
import type { TooltipProps } from '@mui/material/Tooltip';

interface FamilyCardProps {
  item: Family;
  onClickAction?: () => void;
}

export const FamilyCard = ({ item, onClickAction }: FamilyCardProps) => {
  const [empty, setEmpty] = useState<boolean>(false);
  const [completed, setCompleted] = useState<boolean>(false);

  useEffect(() => {
    if (item.questionsCompleted === item.questionsTotal) {
      setEmpty(false);
      setCompleted(true);
    } else if (item.questionsCompleted === 0) {
      setEmpty(true);
      setCompleted(false);
    } else {
      setEmpty(false);
      setCompleted(false);
    }
  }, [item]);

  return (
    <FamilyCardContainer onClick={onClickAction}>
      <FamilyCardTitle>{convertUppercase(item.title)}</FamilyCardTitle>
      <FamilyCardStatus
        sx={{
          color: completed ? 'ui.primaryGreen.main' : 'ui.neutral.greyDark',
        }}
      >
        <>
          {completed ?? <Done fontSize="small" />}
          {empty ?? <PriorityHighOutlined fontSize="small" />}
          {item.questionsCompleted + ' / ' + item.questionsTotal}
        </>
      </FamilyCardStatus>
      <TooltipDesc
        title={
          <>
            <Typography variant="h3" sx={{ paddingBottom: '5px' }}>
              {' '}
              {convertUppercase(item.title)}{' '}
            </Typography>
            <Divider />
            <Box sx={{ paddingTop: '5px' }}> {item.desc} </Box>
          </>
        }
      >
        <FamilyCardDesc> {item.desc} </FamilyCardDesc>
      </TooltipDesc>
    </FamilyCardContainer>
  );
};

const FamilyCardContainer = styled(ButtonBase)(({ theme }) => ({
  display: 'grid',
  gridTemplateRows: '[top] 33% [center] 67% [bottom]',
  gridTemplateColumns: '[first] 70% [center] 30% [last]',
  boxSizing: 'border-box',
  height: '144px',
  width: '100%',
  padding: '24px',
  borderRadius: '8px',
  background: theme.palette.ui.neutral.white,
  boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.16)',
}));

const FamilyCardTitle = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  gridRow: 'top / center',
  gridColumn: 'first / center',
  ...theme.typography.h3,
}));

const FamilyCardStatus = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  width: '100%',
  gridRow: 'top / center',
  gridColumn: 'center/ last',
  ...theme.typography.subtitle2,
}));

const FamilyCardDesc = styled(Box)(({ theme }) => ({
  marginTop: '4px',
  width: '100%',
  gridRow: 'center / bottom',
  gridColumn: 'first / last',

  color: theme.palette.ui.neutral.black,
  ...theme.typography.body2,
  textAlign: 'left',

  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: '3',
  WebkitBoxOrient: 'vertical',
}));

const TooltipDesc = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.ui.neutral.white,
    color: theme.palette.ui.neutral.black,
    ...theme.typography.body2,
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.16)',
    borderRadius: '8px',
    padding: '15px',
  },
}));
