import { createApi } from '@reduxjs/toolkit/query/react';
import axios from 'axios';

import { generateDefaultApiHeaders } from '@/utils/api';

import type { BaseQueryFn } from '@reduxjs/toolkit/query/react';
import type { AxiosError, AxiosRequestConfig } from 'axios';

const axiosBaseQuery: BaseQueryFn = async ({
  url,
  data,
  params,
}: {
  url: string;
  data?: AxiosRequestConfig['data'];
  params?: AxiosRequestConfig['params'];
}) => {
  const baseUrl = process.env.REACT_APP_API_ENDPOINT_ROOT;
  const config = generateDefaultApiHeaders();

  try {
    const response = await axios({
      url: baseUrl + url,
      method: config.method,
      headers: config.headers,
      data,
      params,
    });
    return {
      data: response.data?.result ? response.data?.result : response.data,
    };
  } catch (axiosError) {
    const err = axiosError as AxiosError;
    return {
      error: {
        status: err.response?.status,
        data: err.response?.data || err.message,
      },
    };
  }
};

export const deliveryPlaybookApi = createApi({
  reducerPath: 'deliveryPlaybookApi',
  baseQuery: axiosBaseQuery,
  endpoints: () => ({}),
  tagTypes: ['KPIRecords', 'KPIReports'],
});
