import { useCallback, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import { Close, Edit, Save } from '@mui/icons-material';
import { Alert, AlertProps, Snackbar } from '@mui/material';
import {
  DataGrid,
  enUS,
  esES,
  frFR,
  GridActionsCellItem,
  GridColDef,
  GridEventListener,
  GridLocaleText,
  GridRowEditStopReasons,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
} from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';

import { KPITableColumns } from '@/const/KPITableColumns';

import kpiRecordApi from '@/redux/api/deliveryPlaybook/KPIRecord';

import type { KPIRecord, KPIReport } from 'SustainablePlatform';

export interface KPITableContainerProps {
  reportUuid?: KPIReport['DELIVERY_PLAYBOOK_KPI_REPORT_UUID'];
}

export const KPITableContainer = ({ reportUuid }: KPITableContainerProps) => {
  const { t, i18n } = useTranslation();
  const languageMap: Record<string, Partial<GridLocaleText>> = {
    es: esES.components.MuiDataGrid.defaultProps.localeText,
    fr: frFR.components.MuiDataGrid.defaultProps.localeText,
    en: enUS.components.MuiDataGrid.defaultProps.localeText,
  };
  const [
    getKpiRecords,
    { data, isLoading: isLoadingRecords, isFetching: isFetchingRecords, error },
  ] = kpiRecordApi.useLazyGetKPIRecordListQuery();
  const [updateKpiRecord, { isLoading: isUpdatingRecord }] =
    kpiRecordApi.useUpdateKPIRecordMutation();

  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});
  const [snackbar, setSnackbar] = useState<Pick<
    AlertProps,
    'children' | 'severity'
  > | null>(null);

  useEffect(() => {
    const fetchKpiRecords = async () => {
      if (!reportUuid) return;

      await getKpiRecords({
        report_uuid: reportUuid,
      });
    };

    fetchKpiRecords();
  }, [reportUuid]);

  useEffect(() => {
    if (error) {
      setSnackbar({
        children: t('templates.somethingWentWrong'),
        severity: 'error',
      });
    }
  }, [error]);

  const handleRowEditStop: GridEventListener<'rowEditStop'> = useCallback(
    (params, event) => {
      if (params.reason === GridRowEditStopReasons.rowFocusOut) {
        event.defaultMuiPrevented = true;
      }
    },
    [],
  );

  const handleEditClick = useCallback(
    (id: GridRowId) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    },
    [],
  );

  const handleSaveClick = useCallback(
    (id: GridRowId) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    },
    [],
  );

  const handleCancelClick = useCallback(
    (id: GridRowId) => () => {
      setRowModesModel({
        ...rowModesModel,
        [id]: { mode: GridRowModes.View, ignoreModifications: true },
      });
    },
    [],
  );

  const processRowUpdate = useCallback(async (newRow: KPIRecord) => {
    const updatedRow = { ...newRow };

    await updateKpiRecord({
      record_uuid: newRow.DELIVERY_PLAYBOOK_KPI_RECORD_UUID,
      score: newRow.KPI_SCORE,
      comment: newRow.COMMENTS,
    });

    setSnackbar({
      children: t('templates.updatedSuccessfully'),
      severity: 'success',
    });
    return updatedRow;
  }, []);

  const handleProcessRowUpdateError = useCallback((error: Error) => {
    setSnackbar({ children: error.message, severity: 'error' });
  }, []);

  const handleCloseSnackbar = useCallback(() => setSnackbar(null), []);

  const actionColumn = useMemo(() => {
    return {
      field: 'actions',
      type: 'actions',
      renderHeader: params =>
        (params.colDef.headerName = t('templates.actions')),
      flex: 1,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<Save />}
              label={t('templates.save')}
              sx={{
                color: 'ui.primaryGreen.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<Close />}
              label={t('templates.cancel')}
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<Edit />}
            label={t('templates.edit')}
            onClick={handleEditClick(id)}
            color="inherit"
          />,
        ];
      },
    } as GridColDef;
  }, [rowModesModel]);

  const columns: GridColDef[] = [
    ...KPITableColumns.map(column => {
      column.renderHeader = params =>
        (params.colDef.headerName = t(column.headerName || ''));
      return column;
    }),
    actionColumn,
  ];

  return (
    <Container>
      <DataGrid
        rows={data || []}
        columns={columns}
        getRowId={row => row.DELIVERY_PLAYBOOK_KPI_RECORD_UUID}
        editMode="row"
        hideFooterPagination
        rowModesModel={rowModesModel}
        onRowModesModelChange={setRowModesModel}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={handleProcessRowUpdateError}
        loading={isLoadingRecords || isFetchingRecords || isUpdatingRecord}
        localeText={languageMap[i18n.language]}
      />
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
    </Container>
  );
};

const Container = styled('div')(() => ({
  minHeight: 400,
}));
