export type TopSDG = {
  updatedDate: string;
  topSdg: SDGItem[];
};

export type SDGItem = {
  uuid: string;
  sdg: string;
  avgScore: number;
  imageUrl: string;
};

export type Family = {
  uuid: string; // unique id of family
  title: string; // title or name of family
  desc: string; // description of family
  questionsTotal: number; // total number of questions
  questionsCompleted: number; // number of completed questions
};

export interface FamilyDetail extends Family {
  categoryList: Category[];
}

export interface Category {
  category: string;
  categoryQuestionList: CategoryQuestion[];
}

export type CategoryQuestion = {
  uuid: string; // unique id of question
  id: number; // nth order of question
  question: string; // question
  tag: string;
  completed: boolean; // if correct, true; else, false
  priority: string;
};

export interface CategoryQuestionDetail extends CategoryQuestion {
  options: UserAnswer[]; // multiple choice
  userAnswer: UserAnswer; // the answer given by user
  answer: string; // correct answer
  details: {
    tests: string;
    precisions: string;
    difficulty: string;
    priority: string;
    recurrence: string;
    peopleRating: 'A' | 'B' | 'C' | 'D' | 'E' | 'F' | null;
    planetRating: 'A' | 'B' | 'C' | 'D' | 'E' | 'F' | null;
    prosperityRating: 'A' | 'B' | 'C' | 'D' | 'E' | 'F' | null;
  };
}

export type AnswerOption = {
  answer: string;
  answerUuid: string;
  isCorrect: boolean;
  message: string;
};

export type UserAnswer = {
  uuid: string; // uuid of question choice
  answer: string;
  score: number;
};

export const topSDGInitialState: TopSDG = {
  updatedDate: '',
  topSdg: [],
};

export const familyListInitialState: Family[] = [
  {
    uuid: '',
    title: '',
    desc: '',
    questionsTotal: 0,
    questionsCompleted: 0,
  },
];

export const familyDetailInitialState: FamilyDetail = {
  uuid: '', // unique id of family
  title: '', // title or name of family
  desc: '', // description of family
  questionsTotal: 0, // total number of questions
  questionsCompleted: 0, // number of completed questions
  categoryList: [],
};

export const categoryQuestionDetailInitialState: CategoryQuestionDetail = {
  uuid: '',
  id: 0,
  question: '',
  options: [],
  userAnswer: { uuid: '', answer: '', score: 0 },
  answer: '',
  completed: true,
  priority: '',
  tag: '',
  details: {
    tests: '',
    precisions: '',
    difficulty: '',
    priority: '',
    recurrence: '',
    peopleRating: null,
    planetRating: null,
    prosperityRating: null,
  },
};

export type QuestionUpdateType = {
  question_uuid: string;
  completed: boolean | null;
};

export const questionUpdateInitialState = {
  question_uuid: '',
  completed: null,
};

export const sankeyDataInitialState = {
  title: '',
  subtext: '',
  updatedDate: '',
  legend: [],
  categories: [],
  sdgGoals: [],
  links: [],
  results: [],
};
