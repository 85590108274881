import axios from 'axios';

import { generateDefaultApiHeaders } from '@/utils/api';

import { webServices } from '@/redux/stores/api';
import { RootState } from '@/redux/stores/store';

import {
  GET_ALL_ACTIONS,
  GET_ALL_ACTIONS_FAIL,
  GET_ALL_ACTIONS_SUCCESS,
  UPDATE_ACTION_STATUS,
  UPDATE_ACTION_STATUS_FAIL,
  UPDATE_ACTION_STATUS_SUCCESS,
  UPDATE_ACTION_TOTALS,
} from './actionTypes';

import type { AnyAction, ThunkAction } from '@reduxjs/toolkit';

export const getAllActions = (): ThunkAction<
  void,
  RootState,
  unknown,
  AnyAction
> => {
  return async dispatch => {
    try {
      dispatch({ type: GET_ALL_ACTIONS });
      const response = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT_ROOT}/${webServices.getAllActions}`,
        {},
        generateDefaultApiHeaders()
      );

      if (response?.data?.statusAPI !== 'SUCCESS') {
        throw new Error('Get All Actions Error');
      }

      dispatch({
        type: GET_ALL_ACTIONS_SUCCESS,
        payload: response.data.result.actionList,
      });
      dispatch({
        type: UPDATE_ACTION_TOTALS,
        payload: {
          totalActions: response.data.result.actionsTotal,
          totalCompletedActions: response.data.result.actionsCompleted,
          lastUpdatedDate: response.data.result.updatedDate,
        },
      });
    } catch (err) {
      dispatch({
        type: GET_ALL_ACTIONS_FAIL,
        payload: JSON.stringify(err),
      });
    }
  };
};

export const updateActionStatus = ({
  actionId,
  isComplete,
}: {
  actionId: string;
  isComplete: boolean;
}): ThunkAction<void, RootState, unknown, AnyAction> => {
  return async dispatch => {
    try {
      dispatch({ type: UPDATE_ACTION_STATUS });

      const updateResponse = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT_ROOT}/${webServices.updateActionStatus}`,
        {
          action_uuid: actionId,
          status: isComplete,
        },
        generateDefaultApiHeaders()
      );

      if (updateResponse?.data?.statusAPI !== 'SUCCESS') {
        throw new Error('Update Action Fail');
      }

      dispatch({ type: UPDATE_ACTION_STATUS_SUCCESS });
      dispatch(getAllActions());
    } catch (err) {
      dispatch({
        type: UPDATE_ACTION_STATUS_FAIL,
        payload: JSON.stringify(err),
      });
    }
  };
};
