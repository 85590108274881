import { useTranslation } from 'react-i18next';

import { LANGUAGES } from '@/const/LanguagesMenuItems';

export const LanguageSelectorContainer = () => {
  const { i18n } = useTranslation();

  const setCookie = (name: string, value: string) => {
    document.cookie = `${name}=${value};`;
  };

  const onChangeLang = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const lang_code = e.target.value;
    i18n.changeLanguage(lang_code);
    setCookie('lang', lang_code);
  };

  return (
    <select defaultValue={i18n.language} onChange={onChangeLang}>
      {LANGUAGES.map(({ code, label }) => (
        <option key={code} value={code}>
          {label}
        </option>
      ))}
    </select>
  );
};

export default LanguageSelectorContainer;
