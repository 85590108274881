import { useEffect, useState } from 'react';
import { Box } from '@mui/material';

interface TimerProps {
  initialMinute: number;
  initialSeconds: number;
}

export const Timer = ({ initialMinute, initialSeconds }: TimerProps) => {
  const [minutes, setMinutes] = useState<number>(initialMinute);
  const [seconds, setSeconds] = useState<number>(initialSeconds);

  useEffect(() => {
    const myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  }, [minutes, seconds]);

  return (
    <Box>
      {minutes === 0 && seconds === 0 ? null : (
        <>
          {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
        </>
      )}
    </Box>
  );
};
