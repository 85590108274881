import {
  NotificationsNone,
  PersonOutline,
  SettingsOutlined,
} from '@mui/icons-material';
import { format } from 'date-fns';

export const camelCaseToCapitalize = (camelCase: string) =>
  camelCase.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
    return str.toUpperCase();
  });

export const renderTagColor = (type: string) => {
  switch (type) {
    case 'Climate Fresk':
      return 'rgba(255, 233, 200, 1)';
    case 'Sustainable IT':
      return 'rgba(224, 243, 239, 1)';
    case 'Carbon Coach':
      return 'rgba(224, 243, 200, 1)';
    case 'B-Corp Impact':
      return 'rgba(224, 243, 239, 1)';
    case 'Energy':
      return 'rgba(66, 106, 234, 0.5)';
    case 'Climate':
      return 'rgba(224, 243, 239, 1)';
    case 'Biodiversity':
      return 'rgba(255, 233, 200, 1)';
    default:
      return 'rgba(224, 243, 239, 1)';
  }
};

export const renderHeaderIcons = (type: string) => {
  switch (type) {
    case 'Settings':
      return <SettingsOutlined />;
    case 'Notifications':
      return <NotificationsNone />;
    case 'User':
      return <PersonOutline />;
    default:
      return null;
  }
};

export const displaySDGTitlesOnly = (fullSDGTitle: string) => {
  return fullSDGTitle.replace(/.+?(:)/g, '');
};

export const formatDate = (dateString: string) => {
  const date = format(new Date(dateString), 'dd MMM yyyy');
  return date;
};

export const convertUppercase = (text: string) => {
  const convertedString = text.replace(/(^\w|\s\w)/g, m => m.toUpperCase());
  return convertedString;
};

export const renderSDGNumbersOnly = (text: string) => {
  const SDGNumber = text.replace(/[a-zA-Z#;]/g, '');
  return `SDG: ${SDGNumber}`;
};

export const isSDGvalue = (text: string) => {
  return /sdg/i.test(text);
};

export const removeHashtag = (text: string) => {
  return text.replace('#', ' ');
};

export const removeSDGGoal = (text: string) => {
  return text.replace(/.*:/g, '');
};
