import { useEffect } from 'react';
import { embedDashboard } from '@superset-ui/embedded-sdk';

import { useAppSelector } from '@/redux/stores/hooks';
import { RootState } from '@/redux/stores/store';

import { SupersetDisplay } from './SupersetContainer.style';

interface SupersetContainerProps {
  dashboardId: string;
}

export const SupersetContainer = ({ dashboardId }: SupersetContainerProps) => {
  const { supersetGuestToken, supersetDomain } = useAppSelector(
    (state: RootState) => state.auth.userInfo,
  );

  useEffect(() => {
    const targetElement = document.getElementById('my-superset-container');
    if (targetElement) {
      embedDashboard({
        id: dashboardId,
        supersetDomain: supersetDomain,
        mountPoint: targetElement,
        fetchGuestToken: () =>
          new Promise<string>(resolve => {
            resolve(supersetGuestToken);
          }),
      });
    }
  }, [supersetGuestToken, dashboardId]);

  return <SupersetDisplay id="my-superset-container" />;
};
