import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { SupersetContainer } from '@/components/Templates';

import { SupersetDashboardMap } from '@/const/SupersetDashboardMap';

import { useAppSelector } from '@/redux/stores/hooks';
import { RootState } from '@/redux/stores/store';

import { Container } from './SupersetPage.styles';

export const SupersetPage = () => {
  const { supersetEmbeddedDashboards } = useAppSelector(
    (state: RootState) => state.auth.userInfo,
  );
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [dashboardId, setDashboardId] = useState('');
  const pathName = useLocation().pathname;

  useEffect(() => {
    const path = pathName.split('/')[1];
    const dashboard = SupersetDashboardMap[path];

    if (!(dashboard in supersetEmbeddedDashboards)) {
      navigate('/');
    } else {
      const dashboardId = supersetEmbeddedDashboards[dashboard];
      setDashboardId(dashboardId);
    }
  }, [pathName]);

  if (dashboardId == '') {
    return (
      <>
        <p>{t('pages.loading')}</p>
      </>
    );
  }

  return (
    <Container>
      <SupersetContainer dashboardId={dashboardId} />
    </Container>
  );
};
