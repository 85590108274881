import { useState } from 'react';
import { Box, Container, styled, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { CustomTextField, LoadingWheel } from '@/components/Atoms';
import {
  CarbonMapDialog,
  ParkListDropDown,
  ReportUploadButton,
} from '@/components/Molecules';
import CarbonParkLineMap from '@/components/Organisms/CarbonParkLineMap/CarbonParkLineMap';

import { ListOption } from '@/models/helper';

import { makeParkDetailContent } from '@/utils/carbon';

import { useAppSelector } from '@/redux/stores/hooks';

import type {
  CarbonPark,
  CarbonParkListing,
} from '@/redux/reducers/carbon/state';

interface CarbonUploadContainerProps {
  reportName: string;
  setReportName: any;
  activePark: string;
  setPark: any;
  file: File | null;
  handleFileChange: any;
  handleFileReset: any;
  allParkList: CarbonParkListing[];
}

export const CarbonUploadContainer = ({
  reportName,
  setReportName,
  activePark,
  setPark,
  file,
  handleFileChange,
  handleFileReset,
  allParkList,
}: CarbonUploadContainerProps) => {
  const { isLoadingMap, carbonParkDetail } = useAppSelector(
    store => store.carbon,
  );

  const [parkDetailContent, setParkDetailContent] = useState<ListOption[]>([]);
  const [open, setOpen] = useState(false);

  const handleClickOpen = (carbonPark: CarbonPark) => {
    setParkDetailContent(makeParkDetailContent(carbonPark));
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { t } = useTranslation();

  const mapDisplay = (
    <MapContainer>
      {isLoadingMap && (
        <ChartLoadingWrapper>
          <LoadingWheel isChart={true} />
        </ChartLoadingWrapper>
      )}
      {!isLoadingMap && allParkList && (
        <CarbonParkLineMap
          parkLineList={carbonParkDetail}
          onClick={handleClickOpen}
        />
      )}
    </MapContainer>
  );

  return (
    <UploadContainer>
      <CarbonMapDialog
        isOpen={open}
        handleClose={handleClose}
        parkDetails={parkDetailContent}
      />
      <UploadInput
        style={{
          paddingBottom: '24px',
        }}
      >
        <Typography variant="body1">{t('templates.reportName')}</Typography>
        <CustomTextField
          value={reportName}
          label={t('templates.untitledReport')}
          onChangeAction={(event: any) => setReportName(event.target.value)}
        />
      </UploadInput>
      <UploadInput
        sx={theme => ({
          paddingTop: '24px',
          paddingBottom: '24px',
          borderTop: `1px solid ${theme.palette.ui.stroke}`,
        })}
      >
        <Typography variant="body1">{t('templates.harFile')}</Typography>
        <ReportUploadButton
          label={t('templates.attachHarFile')}
          acceptedTypes={'.har'}
          file={file}
          handleFileChange={handleFileChange}
          handleReset={handleFileReset}
        />
      </UploadInput>
      <UploadInput
        sx={theme => ({
          paddingTop: '24px',
          borderTop: `1px solid ${theme.palette.ui.stroke}`,
        })}
      >
        <Typography variant="body1">{t('templates.park')}</Typography>
        <ParkListDropDown
          title={t('templates.selectPark')}
          disabled={false}
          value={activePark}
          dropdownItems={allParkList}
          onChangeAction={(event: any) => setPark(event.target.value)}
          showError={false}
        />
      </UploadInput>
      {carbonParkDetail.length > 0 && mapDisplay}
    </UploadContainer>
  );
};

const ChartLoadingWrapper = styled(Container)(({ theme }) => ({
  height: '360px',
  padding: '0px',
  background: theme.palette.ui.neutral.white,
}));

const UploadContainer = styled(Box)(({ theme }) => ({
  display: 'block',
  flexDirection: 'column',
  boxSizing: 'border-box',
  padding: '24px',
  width: '100%',
  height: 'fit-content',
  borderRadius: '8px',
  background: theme.palette.background.default,
}));

const UploadInput = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '200px auto',
  boxSizing: 'border-box',
  width: '100%',
  height: 'fit-content',
  borderRadius: '8px',
  background: theme.palette.background.default,
  color: theme.palette.text.secondary,
}));

const MapContainer = styled(Box)(() => ({
  display: 'flex',
  boxSizing: 'border-box',
  width: '100%',
  minHeight: '360px',
  paddingTop: '20px',
}));
