import { useTranslation } from 'react-i18next';

import { Wrapper } from './WIPPage.styles';

export const WIPPage = () => {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <h2>{t('pages.workInProgress')}</h2>
    </Wrapper>
  );
};
