import { useState } from 'react';
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import { IconButton, InputAdornment, styled, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';

const LoginTextfield = styled(TextField)`
  padding-bottom: 12px;
  & .MuiFormControl-root {
    width: 100%;
  }
  & .MuiFormLabel-root {
    font: 'body1-desktop';
    color: rgba(34, 34, 34, 0.4);
  }
  & .MuiInputLabel-shrink.Mui-focused {
    font-weight: 800;
    color: rgba(34, 34, 34, 0.7);
  }
  & label.mui-focused: {
    color: rgba(0, 0, 0, 0.12);
  }
  & .MuiInput-underline:after {
    border-bottom-color: rgba(0, 0, 0, 0.12);
  }
  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: rgba(0, 0, 0, 0.12);
    }
    &:hover fieldset {
      border-color: rgba(0, 0, 0, 0.12);
    }
    &.Mui-focused fieldset {
      border-color: rgba(0, 0, 0, 0.12);
    }
  }
`;

interface UsernameTextFieldProps {
  email?: string;
  onChangeAction?: any;
  onKeyDownAction?: any;
}

interface PasswordTextFieldProps {
  password?: string;
  onChangeAction?: any;
  onKeyDownAction?: any;
}

export const UsernameTextField = ({
  email,
  onChangeAction,
  onKeyDownAction,
}: UsernameTextFieldProps) => {
  const { t } = useTranslation();
  return (
    <LoginTextfield
      label={t('molecules.userName')}
      value={email}
      onChange={onChangeAction}
      onKeyDown={onKeyDownAction}
    />
  );
};

export const PasswordTextField = ({
  password,
  onChangeAction,
  onKeyDownAction,
}: PasswordTextFieldProps) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const { t } = useTranslation();
  return (
    <LoginTextfield
      label={t('molecules.password')}
      type={showPassword ? 'text' : 'password'}
      value={password}
      onChange={onChangeAction}
      onKeyDown={onKeyDownAction}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? (
                <VisibilityOffOutlined />
              ) : (
                <VisibilityOutlined />
              )}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};
