import { Search } from '@mui/icons-material';
import { InputAdornment, styled, TextField } from '@mui/material';

const StyledTextField = styled(TextField)(({ theme }) => ({
  '& label.Mui-focused': {
    color: theme.palette.text.primary,
    fontWeight: '800',
  },
  '& .MuiOutlinedInput-root': {
    fontFamily: 'Open-Sans-Regular',
    '&.Mui-focused fieldset': {
      color: theme.palette.text.primary,
      borderColor: theme.palette.text.primary,
    },
  },
}));

interface CustomTextFieldProps {
  value?: string;
  label?: string;
  showAdornment?: boolean;
  onChangeAction?: any;
  onKeyDownAction?: any;
}

export const CustomTextField = ({
  value,
  label,
  showAdornment,
  onChangeAction,
  onKeyDownAction,
}: CustomTextFieldProps) => {
  return (
    <StyledTextField
      label={label}
      value={value}
      onChange={onChangeAction}
      onKeyDown={onKeyDownAction}
      InputProps={
        value
          ? {
              startAdornment: (
                <InputAdornment position="start">
                  {showAdornment && <Search fontSize="small" />}
                </InputAdornment>
              ),
            }
          : undefined
      }
    />
  );
};
